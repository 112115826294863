import React, { useState } from 'react';
import { Box, Button, Container, Typography, TextField } from '@mui/material';
import api from '../api/api';  // Import your api module

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');  // Add companyName state

  const handleSubscribe = async () => {
    try {
      // Send a request to your back-end to check if the email or company exists
      const response = await api.post('/check-email/', { email, companyName });
      const { emailExists, companyExists } = response.data;  // Adjust according to the backend response structure

      if (emailExists) {
        // Redirect to login page or show a message
        alert('An account with this email already exists. Please log in to manage your subscription.');
        window.location.href = '/login/';
      } else if (companyExists) {
        // Show a message if the company name is already in use
        alert('A company with this name already exists. Please log in or contact support.');
      } else {
        // Redirect to Stripe Payment Link with prefilled email
        const stripePaymentLink = `https://buy.stripe.com/test_00g14d22XanzcaQ3cj?prefilled_email=${encodeURIComponent(email)}`;
        window.location.href = stripePaymentLink;
      }
    } catch (error) {
      console.error('Error checking email or company:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f9f9f9',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Subscribe to Our Service
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Enter your email and company name, then click subscribe.
        </Typography>

        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Company Name"
          variant="outlined"
          fullWidth
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}  // Update state for companyName
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubscribe}
          sx={{ width: '100%', mt: 2 }}
        >
          Subscribe Now
        </Button>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Already have an account? <a href="/login/">Log in here</a>.
        </Typography>
      </Box>
    </Container>
  );
};

export default LandingPage;
