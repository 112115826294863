import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Switch, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateTemplateVisibility, updateVersionVisibility } from '../../../apislice'; // API calls for updating visibility

const FormTemplateSettingsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  
  // Get the formTemplates from Redux state
  const formTemplates = useSelector(state => state.api.formTemplates.data);

  // Handle form-level visibility toggle (this affects the whole form and its versions)
  const handleToggleFormVisibility = async (templateId, isVisible) => {
    try {

      await dispatch(updateTemplateVisibility({ templateId, isVisible })).unwrap(); // Ensures successful API update

    } catch (error) {
      console.error("Error updating form visibility:", error);
    }
  };

  // Handle version-level visibility toggle (this affects only the specific version)
  const handleToggleVersionVisibility = async (templateId, versionId, isVisible) => {
    try {
        console.log("templateId", templateId);
        console.log("versionId", versionId);
        console.log("isVisible", isVisible);
      await dispatch(updateVersionVisibility({ templateId, versionId, isVisible })).unwrap(); // Ensures successful API update
    } catch (error) {
      console.error("Error updating version visibility:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Manage Form and Template Visibility</DialogTitle>
      <DialogContent>
        <List>
          {formTemplates.map((template) => (
            <Box key={template.id} mb={2}>
              {/* Form-level control */}
              <ListItem>
                <ListItemText primary={template.name} />
                <Switch
                  checked={template.visible}  // Ensure this reflects the active status
                  onChange={(e) => handleToggleFormVisibility(template.id, e.target.checked)} // Dispatch API call to change visibility and active status
                />
              </ListItem>
              {/* Version-level control */}
              {template.versions.map((version) => (
                <ListItem key={version.id} sx={{ pl: 4 }}>
                  <ListItemText primary={`Version ${version.version_number}`} />
                  <Switch
                    checked={version.visible}
                    onChange={(e) => handleToggleVersionVisibility(template.id, version.id, e.target.checked)} // Dispatch API call to change visibility
                    disabled={!template.visible} // Disable if form is hidden
                  />
                </ListItem>
              ))}
            </Box>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormTemplateSettingsModal;
