import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import api, { setAuthToken } from './api/api';
// import { dispatch } from 'react';


// define an inital state for the API data
const initialState = {
    projects: {
        data: [],
        status: 'idle',
    },
    clients: [],
    siteDiary: {
        data: [],
        status: 'idle',
    },
    permitToDig: {
        data: [],
        status: 'idle',
    },
    manholeInspection: {
        data: [],
        status: 'idle',
    },
    materialRequisition: {
        data: [],
        status: 'idle',
    },
    serviceStrike: {
        data: [],
        status: 'idle',
    },
    nearMiss: {
        data: [],
        status: 'idle',
    },
    technicalQueries: {
        data: [],
        status: 'idle',
    },
    companies: {
        data: [],
        status: 'idle',
    },
    approvals: {
        data: [],
        status: 'idle',
    },
    subcontractOrders: {
        data: [],
        status: 'idle',
    },
    trades: {
        data: [],
        status: 'idle',
    },
    users: {
        data: [],
        status: 'idle',
    },
    groups: { 
        data: [],
        status: 'idle',
    },
    stock_items: {
        data: [],
        status: 'idle',
    },
    stock_orders: {
        data: [],
        status: 'idle',
    },
    formTemplates: {
        data: [],
        status: 'idle',
    },
    forms: {
        data: [],
        status: 'idle',
    },
    LatestSiteDiaryEntries: [],
    formElements: [],
    selectedElement: null,
    pdfFile: null,

    showDetails: false,
    selectedProject: null,
    status: 'idle',
    error: null,
    storeUloadedFiles: [],

};



//Fetch Project Information with API
export const fetchProjectsxxxx = createAsyncThunk('projects/fetchProjects', async (arg, { getState }) => {
    // Get the token from your state or however you have stored it
    const token = getState().auth.email.access; 
    setAuthToken(token);

    const response = await api.get('projects/');
    return response.data;
});

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (arg, { getState }) => {
    try{
            // Get the token from your state or however you have stored it
            console.log('fetching projects');
        const accessToken = getState().auth.email.access; 
        setAuthToken(accessToken);

        const response = await api.get('projects/');

        if (response.status !== 200) {
            throw new Error('Failed to fetch projects');
        }
        console.log(response.data);


        return response.data;
    } catch (error) {
        throw error;
    }
});




export const deleteProject = createAsyncThunk(
    'api/deleteProject',
    async (projectId, { dispatch }) => {
        try {
            await axios.delete(`http://localhost:8000/api/projects/${projectId}/`);
            //dispatch(fetchProjects());
            return projectId;
        } catch (error) {
            throw (error);
        }
    }
);

export const addProjectx = createAsyncThunk(
    'api/addProject',
    async (project, { dispatch }) => {
        try {
            const response = await axios.post('http://localhost:8000/api/projects/', project);
            //dispatch(fetchProjects());
            return response.data;
        } catch (error) {
            throw (error);
        }
    }
);

export const addProject = createAsyncThunk(
    'api/addProject',
    async (addProject, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('projects/', addProject);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateProject = createAsyncThunk( 
    'api/updateProject',
    async ({projectId, project}, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);
            
            const response = await api.put(`projects/${projectId}/`, project);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




export const addProjectxxx = createAsyncThunk('projects/addProject', async (project, { getState }) => {
    // Get the token from your state or however you have stored it
    const token = getState().auth.token; 
    setAuthToken(token);

    const response = await api.post('projects/', project);
    return response.data;
});




export const fetchClients = createAsyncThunk('clients/fetchProjects', async (arg, { getState }) => {
    // Get the token from your state or however you have stored it
    const token = getState().auth.email.access; 
    setAuthToken(token);

    const response = await api.get('clients/');
    return response.data;
});






//Material Requisitions
export const fetchMaterialRequisition = createAsyncThunk(
    'api/fetchMaterialRequisition',
    async (arg, { getState }) => {
        try {
            console.log('fetching Material Requisition');
            const token = getState().auth.email.access; 
            setAuthToken(token);
    
            const response = await api.get('forms/materialrequisition/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch material requisition');
            }
            console.log(response.data);
            return response.data;

        } catch (error) {
            throw error;
        }
    }
);

export const addMaterialRequsition = createAsyncThunk(
    'api/addMaterialRequsition',
    async (materialRequisition, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('forms/materialrequisition/', materialRequisition);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateMaterialRequsition = createAsyncThunk(
    'api/updateMaterialRequsition',
    async (materialRequisition, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            console.log(materialRequisition);

            const response = await api.put(`forms/materialrequisition/${materialRequisition.id}/`, materialRequisition);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


//Near Miss
export const fetchNearMiss = createAsyncThunk(
    'api/fetchNearMiss',
    async (arg, { getState }) => {
        try {
            console.log('fetching Near Miss');
            const token = getState().auth.email.access; 
            setAuthToken(token);
    
            const response = await api.get('forms/nearmiss/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch service strike');
            }
            console.log(response.data);
            return response.data;

        } catch (error) {
            throw error;
        }
    }
);

export const addNearMiss = createAsyncThunk(
    'api/addNearMiss',
    async (NearMiss, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('forms/nearmiss/', NearMiss);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateNearMiss = createAsyncThunk(
    'api/updateNearMiss',
    async ({nearMissId, nearMiss}, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`forms/nearmiss/${nearMissId}/`, nearMiss);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteNearMiss = createAsyncThunk(
    'api/deleteNearMiss',
    async (nearMissId, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            console.log("Near Miss Id", nearMissId);

            const response = await api.delete(`forms/nearmiss/${nearMissId}/`);
            console.log(response.data);

            return nearMissId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLatestNearMiss = createAsyncThunk(
    'api/fetchLatestNearMiss',
    async (arg, { getState }) => {
        try {
            console.log('fetching Near Miss');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('forms/nearmiss/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch service strike');
            }
            console.log(response.data);
            return response.data;

        } catch (error) {
            throw error;
        }
    }
);




export const archiveDocument = createAsyncThunk(
    'api/archiveDocument',
    async ({ path, documentId, nearMissId }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.delete(`${path}/`);
            console.log(response.data);
            
            return { path, documentId, nearMissId };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeDocument = (documentId, nearMissId, dataType) => ({
    type: 'REMOVE_DOCUMENT',
    payload: { nearMissId, documentId, dataType },
});



//Technical Queries
export const fetchTechnicalQueries = createAsyncThunk(
    'api/fetchTechnicalQueries',
    async (arg, { getState }) => {
        try {
            console.log('fetching Technical Queries');
            const token = getState().auth.email.access; 
            setAuthToken(token);
    
            const response = await api.get('technicalqueries/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch service strike');
            }
            console.log(response.data);
            return response.data;

        } catch (error) {
            throw error;
        }
    }
);


export const addTechnicalQuery = createAsyncThunk(
    'api/addTechnicalQuery',
    async (NearMiss, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('technicalqueries/', NearMiss);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateTechnicalQuery = createAsyncThunk(
    'api/updateTechnicalQuery',
    async ({technicalQueryId, technicalQuery}, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`technicalqueries/${technicalQueryId}/`, technicalQuery);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteTechnicalQuery = createAsyncThunk(
    'api/deleteTechnicalQuery',
    async (technicalQueryId, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            console.log("Near Miss Id", technicalQueryId);

            const response = await api.delete(`technicalqueries/${technicalQueryId}/`);
            console.log(response.data);

            return technicalQueryId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


//Companies
export const fetchCompanies = createAsyncThunk(
    'api/fetchCompanies',
    async (arg, { getState }) => {
        try {
            console.log('fetching Companies');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('companies/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch companies');
            }
            console.log(response.data);
            return response.data;
            
        } catch (error) {
            throw error;
        }
    }
);

export const addCompany = createAsyncThunk(
    'api/addCompany',
    async (Company, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('companies/', Company);
            console.log(response.data);

            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateCompany = createAsyncThunk(
    'api/updateCompany',
    async ({companyId, company}, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            console.log("Company Id", companyId);

            const response = await api.put(`companies/${companyId}/`, company);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteCompany = createAsyncThunk(
    'api/deleteCompany',
    async (companyId, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            console.log("Near Miss Id", companyId);

            const response = await api.delete(`companies/${companyId}/`);
            console.log(response.data);

            return companyId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchTrades = createAsyncThunk(
    'api/fetchTrades',
    async (arg, { getState }) => {
        try {
            console.log('fetching Trades');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('trades',);

            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch trades');
            }
            console.log(response.data);
            return response.data;
        
        } catch (error) {
            throw error;
        }
    }
);

export const fetchApprovals = createAsyncThunk(
    'api/fetchApprovals',
    async (arg, { getState }) => {
        try {
            console.log('fetching Approvals');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('approvals/',);

            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch approvals');
            }
            console.log(response.data);
            return response.data;

        } catch (error) {
            throw error;
        }
    }
);

export const changeApprovalStatus = createAsyncThunk(
    'api/changeApprovalStatus',
    async ({ approvalId, status }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post(`approvals/${approvalId}/change_status/`, { status });
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


//Subcontract Orders
export const fetchSubcontractOrders = createAsyncThunk(
    'api/fetchSubcontractOrders',
    async (arg, { getState }) => {
        try {
            console.log('fetching Subcontract Orders');
            const token = getState().auth.email.access;
            setAuthToken(token);
            
            const response = await api.get('subcontractorders/',);
            
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch subcontract orders');
            }
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);


//Users
export const fetchUsers = createAsyncThunk(
    'api/fetchUsers',
    async (arg, { getState }) => {
        try {
            console.log('fetching Users');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('user/details/',);


            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch users');
            }
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const addUsers = createAsyncThunk(
    'api/addUsers',
    async (user, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('user/create_user/', user);
            console.log(response.data);

            if (response.status !== 201) {
                throw new Error('Failed to add user');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateUser = createAsyncThunk(
    'api/updateUser',
    async ({userId, user}, {rejectWithValue, getState}) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`user/details/${userId}/`, user);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

//Groups
export const fetchGroups = createAsyncThunk(
    'api/fetchGroups',
    async (arg, { getState }) => {
        try {
            console.log('fetching Groups');
            const token = getState().auth.email.access;

            setAuthToken(token);

            const response = await api.get('groups/',);
            console.log(response.data);

            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch groups');
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const fetchStockItems = createAsyncThunk(
    'api/fetchStockItems',
    async (arg, { getState }) => {
        try {
            console.log('fetching Stock Items');
            const token = getState().auth.email.access;
            setAuthToken(token);
            
            const response = await api.get('stockitems/',);

            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch stock items');
            }
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const createStockItem = createAsyncThunk(
    'api/createStockItem',
    async ({ stockItem }, { rejectWithValue, getState }) => {
        
        try { 
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('stockitems/', stockItem);
            console.log(response.data);
            
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            return rejectWithValue(error.response.data);

        }
    }
);

export const updateStockItem = createAsyncThunk(
    'api/updateStockItem',
    async ({ stockItemId, stockItem }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`stockitems/${stockItemId}/`, stockItem);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteStockItem = createAsyncThunk(
    'api/deleteStockItem',
    async (stockItemId, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.delete(`stockitems/${stockItemId}/`);
            console.log(response.data);

            return stockItemId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchStockOrders = createAsyncThunk(
    'api/fetchStockOrders',
    async (arg, { getState }) => {
        try {
            console.log('fetching Stock Orders');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('stockorders/',);

            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch stock orders');
            }
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const acceptStockOrder = createAsyncThunk(
    'api/acceptStockOrder',
    async ({ orderId }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post(`stockorders/${orderId}/accept/`);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createStockOrder = createAsyncThunk(
    'api/createStockOrder',
    async ({ stockOrder }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post('stockorders/', stockOrder);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateStockOrder = createAsyncThunk(
    'api/updateStockOrder',
    async ({ orderId, stockOrder }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`stockorders/${orderId}/`, stockOrder);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchFormTemplates = createAsyncThunk(
    'formTemplates/fetchFormTemplates',
    async (_, { rejectWithValue, getState }) => {
      try {
        const token = getState().auth.email.access; // Ensure the token is correctly retrieved
        setAuthToken(token); // Set token in the headers if required
        const response = await api.get('/formtemplates/');
        
        if (response.status !== 200) {
          throw new Error('Failed to fetch form templates');
        }
        
        return response.data; // Return the templates data
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );

export const addFormTemplate = createAsyncThunk(
    'api/addFormTemplate',
    async ({ formName, ref, fields }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            

            // Construct the form template payload
            const formTemplate = {
                name: formName,
                ref: ref,
                fields: fields,
            };

            // Make the API call to save the form template
            const response = await api.post('formtemplates/', formTemplate);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateFormTemplate = createAsyncThunk(
    'api/updateFormTemplate',
    async ({ templateId, formTemplate }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.put(`formtemplates/${templateId}/`, formTemplate);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const publishFormTemplate = createAsyncThunk(
    'api/publishFormTemplate',
    async ({ templateId }, { rejectWithValue, getState }) => {
        try {
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.post(`formtemplates/${templateId}/publish/`);
            console.log(response.data);

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const setPdfFile = createAction('form/setPdfFile');

export const fetchForms = createAsyncThunk(
    'api/forms/fetchForms',
    async (arg, { getState }) => {
        try {
            console.log('fetching Forms');
            const token = getState().auth.email.access;
            setAuthToken(token);

            const response = await api.get('forms/',);
            if (response.status !== 200 && response.status !== 304) {
                throw new Error('Failed to fetch forms');
            }
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const submitForm = createAsyncThunk(
    'api/forms/submitForm',
    async ({ form, isFormData = false }, { rejectWithValue, getState }) => {
      try {
        const token = getState().auth.email.access;
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
  
        // // If it's FormData, DO NOT set Content-Type
        // if (!(form instanceof FormData)) {
        //   config.headers['Content-Type'] = 'application/json';
        // }
  
        // Log request details
        console.log('Request config:', config);
        console.log('Form data type:', form instanceof FormData ? 'FormData' : typeof form);
  
        // POST request to create a new form
        const response = await api.post('forms/', form, config);
        console.log('Form submission response:', response.data);
  
        return response.data;
      } catch (error) {
        console.error('Form submission error:', error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );
  
  export const updateForm = createAsyncThunk(
    'api/forms/updateForm',
    async ({ formId, form, isFormData = false }, { rejectWithValue, getState }) => {
      try {
        const token = getState().auth.email.access;
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            // Remove Content-Type for FormData, let the browser set it
          },
        };
  
        // If it's FormData, log its contents for debugging
        if (isFormData && form instanceof FormData) {
          console.log('FormData contents:');
          for (let [key, value] of form.entries()) {
            console.log(key, value);
          }
        }
  
        // PUT request to update an existing form
        const response = await api.put(`forms/${formId}/`, form, config);
        console.log('Form update response:', response.data);
  
        return response.data;
      } catch (error) {
        console.error('Form update error:', error.response ? error.response.data : error.message);
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );

  export const updateTemplateVisibility = createAsyncThunk(
    'formTemplates/updateTemplateVisibility',
    async ({ templateId, isVisible }, { rejectWithValue, getState }) => {
      try {
        const token = getState().auth.email.access;
        setAuthToken(token);
  
        // Make the API call to update the visibility
        await api.put(`/formtemplates/${templateId}/visibility/`, { visible: isVisible });
        console.log(templateId, isVisible);
  
        // Instead of relying on the API response, return the updated visibility directly
        return {
          templateId,
          visibility: isVisible, // Pass the visibility that was toggled
        };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  
  export const updateVersionVisibility = createAsyncThunk(
    'formTemplates/updateVersionVisibility',
    async ({ templateId, versionId, isVisible }, { rejectWithValue, getState }) => {
      try {
        const token = getState().auth.email.access;
        setAuthToken(token);
  
        // Send the request to update the version visibility
        await api.put(`/formtemplates/${templateId}/versions/${versionId}/template_version_visibility/`, { visible: isVisible });
        
        // Expecting 'visible' in the API response
        return { templateId, versionId, visibility: isVisible };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  




//Websocket Actions

export const updateSiteDiaryFromWebSocket = createAction('siteDiary/updateFromWebSocket');
export const updatePermitToDigFromWebSocket = createAction('permitToDig/updateFromWebSocket');
export const updateMaterialRequisitionFromWebSocket = createAction('materialRequisition/updateFromWebSocket');
export const updateServiceStrikeFromWebSocket = createAction('serviceStrike/updateFromWebSocket');
export const newNearMissFromWebSocket = createAction('nearMiss/newFromWebSocket');
export const newTechnicalQueryFromWebSocket = createAction('technicalQuery/newFromWebSocket');

export const newCompanyFromWebSocket = createAction('company/newFromWebSocket');
export const deleteCompanyFromWebSocket = createAction('company/deleteFromWebSocket');
export const updateCompanyFromWebSocket = createAction('company/updateFromWebSocket');

export const deleteNearMissFromWebSocket = createAction('nearMiss/deleteFromWebSocket');
export const updateNearMissFromWebSocket = createAction('nearMiss/updateFromWebSocket');
export const updateTechnicalQueryFromWebSocket = createAction('technicalQuery/updateFromWebSocket');
export const deleteTechnicalQueryFromWebSocket = createAction('technicalQuery/deleteFromWebSocket');

export const newProjectFromWebSocket = createAction('projects/newFromWebSocket');
export const updateProjectFromWebSocket = createAction('projects/updateFromWebSocket');
export const deleteProjectFromWebSocket = createAction('projects/deleteFromWebSocket');

export const newStockOrderFromWebSocket = createAction('stockOrder/newFromWebSocket');
export const updateStockOrderFromWebSocket = createAction('stockOrder/updateFromWebSocket');
export const deleteStockOrderFromWebSocket = createAction('stockOrder/deleteFromWebSocket');

export const updateStockLevelFromWebSocket = createAction('stockItem/updateFromWebSocket');
export const newStockItemFromWebSocket = createAction('stockItem/newFromWebSocket');
export const deleteStockItemFromWebSocket = createAction('stockItem/deleteFromWebSocket');



export const addFormElement = createAction('form/addFormElement');
export const updateFormElement = createAction('form/updateFormElement');
export const setSelectedElement = createAction('form/setSelectedElement');


export const newFormFromWebSocket = createAction('form/newFormWebSocket');
export const updateFormFromWebSocket = createAction('form/updateFormWebSocket');
export const deleteFormFromWebSocket = createAction('form/deleteFormWebSocket');

//Create a slice for your API data
const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        REMOVE_DOCUMENT: (state, action) => {
            const { documentId, dataType } = action.payload;
            switch (dataType) {
                case 'nearMiss':
                    state.nearMiss.data = state.nearMiss.data.filter((document) => document.id !== documentId);
                    break;
                case 'serviceStrike':
                    state.serviceStrike.data = state.serviceStrike.data.filter((document) => document.id !== documentId);
                    break;
                // Add cases for other data types here...
                default:
                    break;
            }
            state.pdfFile = action.payload;
        },
    },
    
    extraReducers: (builder) => {
        builder
            //Projects
            .addCase(fetchProjects.pending, (state) => {
                state.status = 'loading';
                console.log('fetchProjects.pending');
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.projects.data = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
            
            })
            .addCase(addProject.fulfilled, (state, action) => {
                state.projects.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(addProject.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const index = state.projects.data.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.projects.data[index] = action.payload;
                }   
                console.log(action.payload);
            })

            //Clients
            .addCase(fetchClients.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.clients = action.payload;
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


            //Material Requisition
            .addCase(fetchMaterialRequisition.pending, (state) => {
                state.materialRequisition.status = 'loading';
            })
            .addCase(fetchMaterialRequisition.fulfilled, (state, action) => {
                state.materialRequisition.status = 'succeeded';
                state.materialRequisition.data = (action.payload);
            })
            .addCase(fetchMaterialRequisition.rejected, (state, action) => {
                state.materialRequisition.status = 'failed';
                state.materialRequisition.error = action.error.message;
            })
            .addCase(addMaterialRequsition.fulfilled, (state, action) => {
                state.materialRequisition.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(addMaterialRequsition.rejected, (state, action) => {
                state.materialRequisition.error = action.payload;
            })

            .addCase(updateMaterialRequsition.fulfilled, (state, action) => {
                const index = state.materialRequisition.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.materialRequisition.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(updateMaterialRequsition.rejected, (state, action) => {
                state.materialRequisition.error = action.payload;
            })


            //Technical Queries
            .addCase(fetchTechnicalQueries.pending, (state) => {
                state.technicalQueries.status = 'loading';
            })
            .addCase(fetchTechnicalQueries.fulfilled, (state, action) => {
                state.technicalQueries.status = 'succeeded';
                state.technicalQueries.data = (action.payload);
            })
            .addCase(fetchTechnicalQueries.rejected, (state, action) => {
                state.technicalQueries.status = 'failed';
                state.technicalQueries.error = action.error.message;
            })
            .addCase(addTechnicalQuery.fulfilled, (state, action) => {
                state.technicalQueries.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(addTechnicalQuery.rejected, (state, action) => {
                state.technicalQueries.error = action.payload;
            })
            .addCase(updateTechnicalQuery.fulfilled, (state, action) => {
                const index = state.technicalQueries.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.technicalQueries.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(updateTechnicalQuery.rejected, (state, action) => {
                state.technicalQueries.error = action.payload;
            })
            .addCase(deleteTechnicalQuery.fulfilled, (state, action) => {
                state.technicalQueries.data = state.technicalQueries.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(deleteTechnicalQuery.rejected, (state, action) => {
                state.technicalQueries.error = action.payload;
            })


            //Companies
            .addCase(fetchCompanies.pending, (state) => {
                state.companies.status = 'loading';
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.companies.status = 'succeeded';
                state.companies.data = (action.payload);
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.companies.status = 'failed';
                state.companies.error = action.error.message;
            })
            .addCase(addCompany.fulfilled, (state, action) => {
                state.companies.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(addCompany.rejected, (state, action) => {
                state.companies.error = action.payload;
            })
            .addCase(updateCompany.fulfilled, (state, action) => {

                console.log("State companies data:", state.companies.data);
                console.log("Action payload:", action.payload);
                const index = state.companies.data.findIndex(company => company.id === action.payload.id);
                if (index !== -1) {
                    console.log("Updating company at index:", index);
                    state.companies.data = [
                        ...state.companies.data.slice(0, index),
                        action.payload,
                        ...state.companies.data.slice(index + 1)
                    ];
                }
                console.log(action.payload);
                console.log(action.payload.id);
            })
            .addCase(updateCompany.rejected, (state, action) => {
                state.companies.error = action.payload;
            })
            .addCase(deleteCompany.fulfilled, (state, action) => {
                state.companies.data = state.companies.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(deleteCompany.rejected, (state, action) => {
                state.companies.error = action.payload;
            })

            //Trades
            .addCase(fetchTrades.pending, (state) => {
                state.trades.status = 'loading';
            })
            .addCase(fetchTrades.fulfilled, (state, action) => {
                state.trades.status = 'succeeded';
                state.trades.data = (action.payload);
            })
            .addCase(fetchTrades.rejected, (state, action) => {
                state.trades.status = 'failed';
                state.trades.error = action.error.message;
            })




            //Approvals
            .addCase(fetchApprovals.pending, (state) => {
                state.approvals.status = 'loading';
            })
            .addCase(fetchApprovals.fulfilled, (state, action) => {
                state.approvals.status = 'succeeded';
                state.approvals.data = (action.payload);
            })
            .addCase(fetchApprovals.rejected, (state, action) => {
                state.approvals.status = 'failed';
                state.approvals.error = action.error.message;
            })
            .addCase(changeApprovalStatus.fulfilled, (state, action) => {
                const index = state.approvals.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.approvals.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(changeApprovalStatus.rejected, (state, action) => {
                state.approvals.error = action.payload;
            })

            //Subcontract Orders
            .addCase(fetchSubcontractOrders.pending, (state) => {
                state.subcontractOrders.status = 'loading';
            })
            .addCase(fetchSubcontractOrders.fulfilled, (state, action) => {
                state.subcontractOrders.status = 'succeeded';
                state.subcontractOrders.data = (action.payload);
            })
            .addCase(fetchSubcontractOrders.rejected, (state, action) => {
                state.subcontractOrders.status = 'failed';
                state.subcontractOrders.error = action.error.message;
            })


            //Users
            .addCase(fetchUsers.pending, (state) => {
                state.users.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.users.status = 'succeeded';
                state.users.data = (action.payload);
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.users.status = 'failed';
                state.users.error = action.error.message;
            })
            .addCase(addUsers.fulfilled, (state, action) => {
                state.users.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(addUsers.rejected, (state, action) => {
                state.users.error = action.payload;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const index = state.users.data.findIndex(user => user.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.users.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.users.error = action.payload;
            })

            //Groups
            .addCase(fetchGroups.pending, (state) => {
                state.groups.status = 'loading';
            })
            .addCase(fetchGroups.fulfilled, (state, action) => {
                state.groups.status = 'succeeded';
                state.groups.data = action.payload;
            })
            .addCase(fetchGroups.rejected, (state, action) => {
                state.groups.status = 'failed';
                state.error = action.error.message;
            })





            //Stock Items
            .addCase(fetchStockItems.pending, (state) => {
                state.stock_items.status = 'loading';
            })
            .addCase(fetchStockItems.fulfilled, (state, action) => {
                state.stock_items.status = 'succeeded';
                state.stock_items.data = (action.payload);
            })
            .addCase(fetchStockItems.rejected, (state, action) => {
                state.stock_items.status = 'failed';
                state.stock_items.error = action.error.message;
            })
            .addCase(createStockItem.fulfilled, (state, action) => {
                state.stock_items.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(createStockItem.rejected, (state, action) => {
                state.stock_items.error = action.payload;
            })
            .addCase(updateStockItem.fulfilled, (state, action) => {
                const index = state.stock_items.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.stock_items.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(updateStockItem.rejected, (state, action) => {
                state.stock_items.error = action.payload;
            })
            .addCase(deleteStockItem.fulfilled, (state, action) => {
                state.stock_items.data = state.stock_items.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(deleteStockItem.rejected, (state, action) => {
                state.stock_items.error = action.payload;
            })
            .addCase(fetchStockOrders.pending, (state) => {
                state.stock_orders.status = 'loading';
            })
            .addCase(fetchStockOrders.fulfilled, (state, action) => {
                state.stock_orders.status = 'succeeded';
                state.stock_orders.data = (action.payload);
            })
            .addCase(fetchStockOrders.rejected, (state, action) => {
                state.stock_orders.status = 'failed';
                state.stock_orders.error = action.error.message;
            })
            .addCase(acceptStockOrder.fulfilled, (state, action) => {
                const updatedOrder = action.payload;
                const existingOrder = state.stock_orders.data.find(order => order.id === updatedOrder.id);
                if (existingOrder) {
                    existingOrder.status = updatedOrder.status;
                }
            })
            .addCase(acceptStockOrder.rejected, (state, action) => {
                state.stock_orders.error = action.payload;
            })
            .addCase(createStockOrder.fulfilled, (state, action) => {
                state.stock_orders.data.push(action.payload);
                console.log(action.payload);
            })
            .addCase(createStockOrder.rejected, (state, action) => {
                state.stock_orders.error = action.payload;
            })
            .addCase(updateStockOrder.fulfilled, (state, action) => {
                const index = state.stock_orders.data.findIndex(order => order.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.stock_orders.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(updateStockOrder.rejected, (state, action) => {
                state.stock_orders.error = action.payload;
            })

            // Form Templates
            .addCase(fetchFormTemplates.pending, (state) => {
                state.formTemplates.status = 'loading';
            })
            .addCase(fetchFormTemplates.fulfilled, (state, action) => {
                state.formTemplates.status = 'succeeded';
                state.formTemplates.data = action.payload;
            })
            .addCase(fetchFormTemplates.rejected, (state, action) => {
                state.formTemplates.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addFormTemplate.fulfilled, (state, action) => {
                state.formTemplates.data.push(action.payload);
            })
            .addCase(addFormTemplate.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addCase(setPdfFile, (state, action) => {
                state.pdfFile = action.payload;
            })
            .addCase(updateFormTemplate.fulfilled, (state, action) => {
                const index = state.formTemplates.data.findIndex(template => template.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.formTemplates.data[index] = action.payload;
                }
            })
            .addCase(publishFormTemplate.fulfilled, (state, action) => {
                const index = state.formTemplates.data.findIndex(template => template.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.formTemplates.data[index] = action.payload;
                }
            })  
            .addCase(updateTemplateVisibility.fulfilled, (state, action) => {
                const { templateId, visibility } = action.payload;
                const templateIndex = state.formTemplates.data.findIndex(template => template.id === templateId);
              
                if (templateIndex !== -1) {
                  // Update the specific template's visibility
                  state.formTemplates.data[templateIndex].visible = visibility;
                }
              })
            .addCase(updateVersionVisibility.fulfilled, (state, action) => {
                const { templateId, versionId, visibility } = action.payload;
                const templateIndex = state.formTemplates.data.findIndex(template => template.id === templateId);
            
                if (templateIndex !== -1) {
                    // Find the index of the specific version within the template
                    const versionIndex = state.formTemplates.data[templateIndex].versions.findIndex(version => version.id === versionId);
                    
                    if (versionIndex !== -1) {
                        // Update the visibility of the specific version
                        state.formTemplates.data[templateIndex].versions[versionIndex].visible = visibility;
                    }
                }
            })
            
            //Forms
            .addCase(fetchForms.pending, (state) => {
                state.forms.status = 'loading';
            })
            .addCase(fetchForms.fulfilled, (state, action) => {
                state.forms.status = 'succeeded';
                state.forms.data = action.payload;
            })
            .addCase(fetchForms.rejected, (state, action) => {
                state.forms.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(submitForm.pending, (state) => {
                state.forms.status = 'loading';
            })
            .addCase(submitForm.fulfilled, (state, action) => {
                const index = state.forms.data.findIndex(form => form.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.forms.data[index] = action.payload;
                } else {
                    // Add the new form to the list
                    state.forms.data.push(action.payload);
                }
            })
            .addCase(submitForm.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateForm.pending, (state) => {
                state.forms.status = 'loading';
            })
            .addCase(updateForm.fulfilled, (state, action) => {
                const index = state.forms.data.findIndex(form => form.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.forms.data[index] = action.payload;
                }
            })
            .addCase(updateForm.rejected, (state, action) => {
                state.error = action.payload;
            })
            


            

            








            





            
            




            
            //Websocket
            .addCase(updateSiteDiaryFromWebSocket, (state, action) => {
                state.siteDiary.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(updatePermitToDigFromWebSocket, (state, action) => {
                state.permitToDig.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(updateMaterialRequisitionFromWebSocket, (state, action) => {
                state.materialRequisition.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(updateServiceStrikeFromWebSocket, (state, action) => {
                state.serviceStrike.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(newNearMissFromWebSocket, (state, action) => {
                state.nearMiss.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(deleteNearMissFromWebSocket, (state, action) => {
                state.nearMiss.data = state.nearMiss.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(updateNearMissFromWebSocket, (state, action) => {
                const index = state.nearMiss.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.nearMiss.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(newTechnicalQueryFromWebSocket, (state, action) => {
                state.technicalQueries.data.push(action.payload); // or use 'push' as needed
              },
            )
            .addCase(updateTechnicalQueryFromWebSocket, (state, action) => {
                const index = state.technicalQueries.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.technicalQueries.data[index] = action.payload;
                }
                console.log(action.payload);
            })
            .addCase(deleteTechnicalQueryFromWebSocket, (state, action) => {
                state.technicalQueries.data = state.technicalQueries.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(newCompanyFromWebSocket, (state, action) => {
                state.companies.data.push(action.payload); 
                console.log(action.payload);

              }
            )
            .addCase(deleteCompanyFromWebSocket, (state, action) => {
                state.companies.data = state.companies.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            .addCase(updateCompanyFromWebSocket, (state, action) => {
                const index = state.companies.data.findIndex(requisition => requisition.id === action.payload.id);
                if (index !== -1) {
                    // Update the existing entry with the new data
                    state.companies.data[index] = action.payload;
                }
                console.log(action.payload);
            })

            //Projects Websocket
            .addCase(updateProjectFromWebSocket, (state, action) => {
                const index = state.projects.data.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    
                    state.projects.data[index] = action.payload;
                }
                console.log('Project updated from WebSocket:', action.payload);
            })
            .addCase(newProjectFromWebSocket, (state, action) => {
                state.projects.data.push(action.payload); 
                console.log(action.payload);

            })
            .addCase(deleteProjectFromWebSocket, (state, action) => {
                state.projects.data = state.projects.data.filter(requisition => requisition.id !== action.payload);
                console.log(action.payload);
            })
            
            //Stock Orders Websocket
            .addCase(newStockOrderFromWebSocket, (state, action) => {
                state.stock_orders.data.push(action.payload); 
                console.log('New Stock Order from WebSocket:', action.payload);
            })
            .addCase(updateStockOrderFromWebSocket, (state, action) => {
                const index = state.stock_orders.data.findIndex(order => order.id === action.payload.id);
                if (index !== -1) {
                    state.stock_orders.data[index] = action.payload;
                }
                console.log('Stock Order updated from WebSocket:', action.payload);
            })
            .addCase(deleteStockOrderFromWebSocket, (state, action) => {
                state.stock_orders.data = state.stock_orders.data.filter(order => order.id !== action.payload);
                console.log('Stock Order deleted from WebSocket:', action.payload);
            })

            //Stock Items Websocket
            .addCase(newStockItemFromWebSocket, (state, action) => {
                state.stock_items.data.push(action.payload); 
                console.log('New Stock Item from WebSocket:', action.payload);
            })
            .addCase(updateStockLevelFromWebSocket, (state, action) => {
                const { item_id, new_stock_level } = action.payload;
                const index = state.stock_items.data.findIndex(item => item.id === item_id);
                if (index !== -1) {
                    state.stock_items.data[index].stock_level = new_stock_level;
                }
                console.log('Stock Item updated from WebSocket:', action.payload);
            })

            .addCase(deleteStockItemFromWebSocket, (state, action) => {
                state.stock_items.data = state.stock_items.data.filter(item => item.id !== action.payload);
                console.log('Stock Item deleted from WebSocket:', action.payload);
            })

            //Forms Websocket
            .addCase(newFormFromWebSocket, (state, action) => {
                state.forms.data.push(action.payload);
                console.log('New Form from WebSocket:', action.payload);
            })
            .addCase(updateFormFromWebSocket, (state, action) => {
                const index = state.forms.data.findIndex(form => form.id === action.payload.id);
                if (index !== -1) {
                    state.forms.data[index] = action.payload;
                    console.log('Form updated from WebSocket:', action.payload);
                }
            })
            .addCase(deleteFormFromWebSocket, (state, action) => {
                state.forms.data = state.forms.data.filter(form => form.id !== action.payload);
                console.log('Form deleted from WebSocket:', action.payload);
            });
            


            




            
            



            



        },
    
});

export default apiSlice.reducer;