import {useSelector} from "react-redux";

// export const useUserGroup = (groupName) => {
//     const groups = useSelector(state => state.auth.email.groups);
//     return groups && groups.includes(groupName);
// };

export const useUserGroup = (groupNames) => {
    const groups = useSelector(state => state.auth.groups);

    const isApproved = groups && groupNames.some(groupName => groups.includes(groupName));

    return isApproved;
};