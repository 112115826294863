import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { addProject, fetchProjects, fetchClients } from '../apislice';
import { Offcanvas, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Uppy from '@uppy/core';
import UppyUploader from './UppyUploader';
import Webcam from '@uppy/webcam';
import { Dashboard } from '@uppy/react';
import { useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { useDispatch } from 'react-redux';
import { setUploadedFiles } from '../actions/formActions';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import { useSelector } from 'react-redux';









const ProjectAddForm = ({ show, onClose }) => {
    const [validationErrors, setValidationErrors] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const userId = useSelector((state) => state.auth.email.id);
    const userEmail = useSelector((state) => state.auth.email.email);
    const projects = useSelector((state) => state.api.projects.data);
    const dispatch = useDispatch();
    const [uppy, setUppy] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const accessToken = useSelector((state) => state.auth.email.access);
    const clients = useSelector((state) => state.api.clients);

    useEffect(() => {
        dispatch(fetchProjects());
        dispatch(fetchClients());
    }, [dispatch]);

    const newProjectSchema = Yup.object().shape({


        project_name: Yup.string()
            .required('Required')
            .trim()
            .lowercase()
            .test(
                'is-unique',
                'Project Name already exists',
                (value) => {
                    const projectNames = projects.map((project) => project.project_name.toLowerCase());
                    return !projectNames.includes(value);
                }
            ),
        project_reference: Yup.string()
            .required('Required')
            .trim()
            .lowercase()
            .test(
                'is-unique',
                'Project Reference already exists',
                (value) => {
                    const projectReferences = projects.map((project) => project.project_refernce.toLowerCase());
                    return !projectReferences.includes(value);
                }
            ),
                
        start_date: Yup.date()
            .required('Required')
            .min(Yup.ref('start_date'), 'Start Date must be before End Date'),


        end_date: Yup.date()
            .required('Required')
            .min(Yup.ref('start_date'), 'End Date must be after Start Date'),
        location: Yup.string().required('Required'),
        client: Yup.string().required('Required'),
        project_manager: Yup.string().required('Required'),
        budget: Yup.number().required('Required'),
        buyer: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
        project_description: Yup.string().required('Required'),
        users: Yup.string().required('Required'),
        
    
    });





    return (
        <Offcanvas show={show} onHide={onClose} placement="end" backdrop="statis" className="custom-offcanvas-width-projects">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Project </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={{
                        project_name: '',
                        Project_description: '',
                        project_refernce: '',
                        start_date: '',
                        end_date: '',
                        location: '',
                        client: '',
                        project_manager: '',
                        budget: '',
                        buyer: '',
                        status: 'Choose',
                        users: '',



                    }}
                    validationSchema={newProjectSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        const formData = new FormData();

                        Object.keys(values).forEach((key) => {
                            if (key !== 'upload_documents'  ){
                                formData.append(key, values[key]);
                            }
                        });
                        // formData.append('user', userId);
                        // const uploadedFiles = uppy.getFiles();

                        // console.log('Uploaded Files:', uploadedFiles);
                        // uploadedFiles.forEach((file, index) => {
                        //     console.log(`File ${index}: `, file);
                        //     formData.append(`upload_documents[${index}]`, file.data, file.name);
                        // });


                        // console.log('Project Form Values:', values);
                        // console.log(Array.from(formData.entries()));
                        dispatch(addProject(formData))
                        .unwrap()
                        .then((result) => {
                            console.log('Project Add)ed Sucessfully:', result);
                            alert('Project Added Sucessfully');
                            onClose();
                        })
                        .catch((error) => {
                            console.log('Failed to add Project:', error);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });


                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="project_name">Project Name:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="project_name" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="project_name" component="div" className="text-danger" />

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="project_refernce">Project Reference:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="project_refernce" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="project_refernce" component="div" className="text-danger" />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="start_date">Start Date:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="date" name="start_date" className="form-control" onChange={handleChange} onBlur={handleBlur}/>
                                <ErrorMessage name="start_date" component="div" className="text-danger" />

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="end_date">Finish Date:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="date" name="end_date" className="form-control" onChange={handleChange} onBlur={handleBlur}/>
                                <ErrorMessage name="end_date" component="div" className="text-danger" />

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="client">Client:</label>
                            </Col>
                            <Col sm={8}>
                                <Field as="select" name="client" className="form-control" onChange={handleChange} onBlur={handleBlur}>
                                    <option value="">Select a Client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>{client.name}</option>
                                    ))}
                                </Field>
                                <ErrorMessage name="client" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="buyer">Buyer:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="buyer" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="buyer" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="location">Location:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="location" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="location" component="div" className="text-danger" />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="budget">Budget:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="number" name="budget" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="budget" component="div" className="text-danger" />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="project_description">Project description:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="project_description" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="project_description" component="div" className="text-danger" />

                            </Col>
                        </Row>


                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="project_manager">Project Manager:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="project_manager" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="project_manager" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="status">Status:</label>
                            </Col>
                            <Col sm={8}>
                                <Field as="select" name="status" className="form-control" onChange={handleChange} onBlur={handleBlur}> 
                                    <option value="">Choose</option>
                                    <option value="Planning">Planning</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Completed">Completed</option>
                                </Field>
                                <ErrorMessage name="status" component="div" className="text-danger" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <label htmlFor="users">Users:</label>
                            </Col>
                            <Col sm={8}>
                                <Field type="text" name="users" className="form-control" onChange={handleChange} onBlur={handleBlur} />
                                <ErrorMessage name="users" component="div" className="text-danger" />
                            </Col>
                        </Row>




                        




                        <Button type="submit" className="btn btn-primary mt-3">Submit</Button>
                    </Form>

                    )}
                </Formik>
                
            </Offcanvas.Body>
            

        </Offcanvas>
    );
};






export default ProjectAddForm;