import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Offcanvas, FormGroup, FormLabel, Button, Row, Col, Table  } from 'react-bootstrap';
import { fetchProjects, fetchClients, addCompany, fetchTrades } from '../../apislice.js';
import '../../App.css';
import { Alerts } from '../utils/Alerts';

// Validation Schema


const ProjectsAddForm = ( {show, onClose} ) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.email.id);
    const companies = useSelector((state) => state.api.companies);
    const projects = useSelector((state) => state.api.projects.data);
    const [category, setCategory] = useState('');
    const trades = useSelector((state) => state.api.trades.data);

    const clients = useSelector((state) => state.api.clients);
    const [uppy, setUppy] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    

    useEffect(() => {
        dispatch(fetchProjects());
        dispatch(fetchClients());
        dispatch(fetchTrades());

    }, [dispatch]);












    const AddCompanySchema = Yup.object().shape({
        name: Yup.string()
            .required('Required')
            .trim()
            .lowercase()
            .matches(/^[a-z0-9 ]+$/i, 'Must be only letters and numbers')
            .test(
                'isUnique',
                'This company already exists',
                (value) => {
                    const companyNames = companies.data.map((companies) => companies.name.toLowerCase());
                    return !companyNames.includes(value);
                }
            ),
            
            
        reference: Yup.string()
            .required('Required')
            .trim()
            .lowercase()
            .test(
                'isUnique',
                'This reference already exists',
                (value) => {
                    const companyRefs = companies.data.map((companies) => companies.reference.toLowerCase());
                    return !companyRefs.includes(value);
                }
            ),
        address: Yup.string().required('Required'),
        phone_number: Yup.string()
            .required('Required'),

            
        
        category: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
    
    });


    return (
        <Offcanvas show={show} onHide={onClose} placement="end" backdrop="statis" className="custom-offcanvas-width-projects">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="custom-offcanvas-title">Add Company</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={{
                        name: '',
                        reference: '',
                        address: '',
                        website: '',
                        phone_number: '',
                        company_number: '',
                        vat_number: '',
                        CIS_number: '',
                        bank_name: '',
                        bank_address: '',
                        bank_account_number: '',
                        bank_sort_code: '',
                        bank_IBAN: '',
                        bank_BIC: '',
                        category: '',
                        status: '',
                    }}
                    validationSchema={AddCompanySchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        if (values.website && !/^(?:f|ht)tps?\:\/\//.test(values.website)) {
                            values.website = `http://${values.website}`;
                        }
                    
                        const formData = new FormData();

                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key]);
                        });
                        formData.append('user', userId);


                        console.log('Submitting: ', values);
                        dispatch(addCompany(formData))
                        .unwrap()
                        .then((result) => {
                            alert('Company Added');
                            setSubmitting(false);
                            resetForm();
                            onClose();
                        })

                    }}

                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form>
                        <Row >
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="name">Name</FormLabel> */}
                                    <Field name="name" type="text" className="form-control" placeholder="Client Name" onChange={handleChange} onBlur={handleBlur} />
                                    <ErrorMessage name="name" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="reference">Reference</FormLabel> */}
                                    <Field name="reference" type="text" className="form-control" placeholder="Reference" onChange={handleChange} onBlur={handleBlur}/>
                                    <ErrorMessage name="reference" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="address">Address</FormLabel> */}
                                    <Field name="address" type="text" className="form-control" placeholder="Address" onChange={handleChange} onBlur={handleBlur} />
                                    <ErrorMessage name="address" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field name="category" as="select" className="form-control" onChange={handleChange} onBlur={handleBlur}>
                                        <option value="" label="Select a category" />
                                        <option value="Client" label="Client" />
                                        <option value="Supplier" label="Supplier" />
                                        <option value="Subcontractor" label="Subcontractor" />
                                        <option value="Designer" label="Designer" />
                                        <option value="Other" label="Other" />
                                    </Field>


                                    <ErrorMessage name="category" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field name="status" as="select" className="form-control" onChange={handleChange} onBlur={handleBlur} >
                                        <option value="" label="Select a status" />
                                        <option value="Active" label="Active" />
                                        <option value="Inactive" label="Inactive" />
                                    </Field>

                                    <ErrorMessage name="status" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.category === 'Subcontractor' && (
                            <Row>
                                <Col className="mb-auto">
                                    <FormGroup className="mb-auto">
                                        <Field name="trades" as="select" className="form-control">
                                            <option value="" label="Select a trade" />
                                            {trades.filter(trade => trade.category === 'Trade').map(filteredTrade => (
                                                <option key={filteredTrade.id} value={filteredTrade.id}>{filteredTrade.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="trades" component="div" className="form-error-message" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        {values.category === 'Designer' && (
                            <Row>
                                <Col className="mb-auto">
                                    <FormGroup className="mb-auto">
                                        <Field name="trades" as="select" className="form-control">
                                            <option value="" label="Select a Category" />
                                            {trades.filter(trade => trade.category === 'Designer').map(filteredTrade => (
                                                <option key={filteredTrade.id} value={filteredTrade.id}>{filteredTrade.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="trades" component="div" className="form-error-message" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="website">Website</FormLabel> */}
                                    <Field name="website" type="text" className="form-control" placeholder="Website" />
                                    <ErrorMessage name="website" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="phone_number">Phone Number</FormLabel> */}
                                    <Field name="phone_number" type="text" className="form-control" placeholder="Phone Number" />
                                    <ErrorMessage name="phone_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="company_number">Company Number</FormLabel> */}
                                    <Field name="company_number" type="text" className="form-control" placeholder="Company Number" />
                                    <ErrorMessage name="company_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="vat_number">VAT Number</FormLabel> */}
                                    <Field name="vat_number" type="text" className="form-control" placeholder="VAT Number" />
                                    <ErrorMessage name="vat_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="CIS_number">CIS Number</FormLabel> */}
                                    <Field name="CIS_number" type="text" className="form-control" placeholder="CIS Number" />
                                    <ErrorMessage name="CIS_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_name">Bank Name</FormLabel> */}
                                    <Field name="bank_name" type="text" className="form-control" placeholder="Bank Name" />
                                    <ErrorMessage name="bank_name" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_address">Bank Address</FormLabel> */}
                                    <Field name="bank_address" type="text" className="form-control" placeholder="Bank Address" />
                                    <ErrorMessage name="bank_address" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_account_number">Bank Account Number</FormLabel> */}
                                    <Field name="bank_account_number" type="text" className="form-control" placeholder="Bank Account Number" />
                                    <ErrorMessage name="bank_account_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_sort_code">Bank Sort Code</FormLabel> */}
                                    <Field name="bank_sort_code" type="text" className="form-control" placeholder="Bank Sort Code" />
                                    <ErrorMessage name="bank_sort_code" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    <Field name="bank_IBAN" type="text" className="form-control" placeholder="Bank IBAN" />
                                    <ErrorMessage name="bank_IBAN" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field name="bank_BIC" type="text" className="form-control" placeholder="Bank BIC" />
                                    <ErrorMessage name="bank_BIC" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" className="btn btn-primary" >Submit</Button>
                            </Col>
                        </Row>


                        
                    </Form>
                    )}
                </Formik>


                
                
            </Offcanvas.Body>
            

        </Offcanvas>
    );
};

export default ProjectsAddForm;