import React from 'react';
import { Handle, Position } from '@xyflow/react';

const ConditionalNode = ({ data }) => {
  return (
    <div style={{ padding: 10, border: '1px solid black', borderRadius: 5 }}>
      <p>{data.label || 'Condition'}</p>
      <p>If <b>{data.checkField}</b> is <b>{data.condition}</b>, go to next</p>
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </div>
  );
};

export default ConditionalNode;