// src/components/FormBuilder/FormComponents/EmailInputItem.js

import React from 'react';
import { TextField } from '@mui/material';

const EmailInputItem = ({ item, handleChange }) => {
  const handleEmailChange = (e) => {
    handleChange({ [item.id]: e.target.value });
  };

  return (
    <TextField
      label={item.label || 'Email'}
      type="email"
      required={item.required || false}
      value={item.value || ''}
      onChange={handleEmailChange}
      fullWidth
      margin="normal"
      variant="outlined"
      size="small"
    />
  );
};

export default EmailInputItem;
