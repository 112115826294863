import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormTemplates, fetchProjects, submitForm, updateForm } from '../../apislice';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  CircularProgress,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Alert,
  Collapse,
} from '@mui/material';
import { Close, CheckCircle } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';

import SignatureItem from './FormComponents/SignatureItem';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import EditIcon from '@mui/icons-material/Edit';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateSize);



const FormEditor = ({ form, onClose }) => {
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true); // To toggle template selection
  const templates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);
  const getCurrentUserId = useSelector(state => state.auth.id);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [showEmailOptions, setShowEmailOptions] = useState(false);
  const [emailRecipient, setEmailRecipient] = useState('');
  const [showEmailSentMessage, setShowEmailSentMessage] = useState(false);
  const [error, setError] = useState('');
  const [tempSignature, setTempSignature] = useState('');
  const [projectError, setProjectError] = useState('');
  const [isEditing, setIsEditing] = useState(!form);
  const [initialFormData, setInitialFormData] = useState({});

  // Fetch templates and projects when the component mounts
  useEffect(() => {
    dispatch(fetchFormTemplates());
    dispatch(fetchProjects());
  }, [dispatch]);

  // Pre-load form data if editing an existing form
  useEffect(() => {
    if (form && templates.length > 0) {
      const template = templates.find(t => t.id === form.template);
      if (!template) return;

      let selectedVersion = null;
      if (form.template_version) {
        selectedVersion = template.versions.find(v => v.id === form.template_version);
      }

      if (!selectedVersion) {
        selectedVersion = getLatestPublishedVersion(template);
      }

      if (selectedVersion) {
        setSelectedTemplate(selectedVersion);
        const alignedFormData = alignFormDataWithTemplateFields(selectedVersion.fields, form.data);
        setFormData(alignedFormData);
        setInitialFormData(alignedFormData);
        // setIsEditing(false);
      }





      setSelectedProject(form.project);
    }
  }, [form, templates]);

  // Helper to find the latest published version of the template
  const getLatestPublishedVersion = (template) => {
    return template.versions
      .filter(v => !v.is_draft && v.visible)
      .sort((a, b) => b.version_number - a.version_number)[0];
  };

  const visibleTemplates = templates.filter(template => {
    const visibleVersion = template.versions.some(v => !v.is_draft && v.visible);
    return template.visible && visibleVersion;
  });

  // Function to get template name by version template ID
  const getTemplateNameByVersion = (version, templates) => {
    const matchingTemplate = templates.find(template => template.id === version.template);
    return matchingTemplate ? matchingTemplate.name : 'Template not found';
  };

  const getFileNameFromUrl = (url) => {
    const pathname = url.split('/').pop().split('?')[0];
    return decodeURIComponent(pathname);
  };

  // Align form data with template fields for editing
  const alignFormDataWithTemplateFields = (fields, data) => {
    const alignedData = {};

    if (data === null) {
      // If data is null, set all fields to empty string or default value
      fields.forEach(field => {
        alignedData[field.id] = ''; // Set default value
      });
    } else {
      // If data exists, align it with the template fields
      fields.forEach(field => {
        alignedData[field.id] = data[field.id] || ''; // Align field id with data, defaulting to empty string if missing
      });
    }

    return alignedData;
  };
  const handleFileChange = (fieldId, files) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fieldId]: files,
    }));
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setIsEditing(false);
  };


  const renderFormField = (field) => {
    // Common properties without the 'onChange' handler
    const commonProps = {
      key: field.id,
      label: field.label || '',
      required: field.required || false,
      value: formData[field.id] || '',
      fullWidth: true,
      margin: 'normal',
      size: 'small',
      InputProps: { style: { fontSize: field.font_size || 14 } },
    };

    switch (field.item_type) {
      case 'textInput':
        return (
          <TextField
            {...commonProps}
            onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            disabled={!isEditing}
          />
        );

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[field.id] || false}
                onChange={(e) => handleFormChange({ [field.id]: e.target.checked })}
                size="small"
                disabled={!isEditing}
              />
            }
            label={field.label}
            sx={{ mb: 2 }}
          />
        );

      case 'dropdown':
        return (
          <FormControl fullWidth sx={{ mb: 2 }} key={field.id} size="small">
            <InputLabel>{field.label || 'Select'}</InputLabel>
            <Select
              value={formData[field.id] || ''}
              onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'dateInput':
        return (
          <FormControl fullWidth key={field.id} sx={{ mb: 2 }} size="small">
            <TextField
              {...commonProps}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            />
          </FormControl>
        );

      case 'emailInput':
        return (
          <TextField
            {...commonProps}
            type="email"
            onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            disabled={!isEditing}
          />
        );



      case 'heading':
        return (
          <Typography
            key={field.id}
            variant={field.size || 'h6'}
            gutterBottom
            align={field.alignment || 'left'}
            sx={{ mb: 2 }}
            disabled={!isEditing}
          >
            {field.label}
          </Typography>
        );

      case 'signature':
        return (
          <Box key={field.id} sx={{ mb: 2, position: 'relative' }}>
            <Typography variant="body1">{field.label}</Typography>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '200px', // Stable size for the signature area
                border: '1px solid #ddd',
                borderRadius: '4px',
                overflow: 'hidden',
                backgroundColor: '#f9f9f9',
              }}
            >
              {/* Show the signature if tempSignature exists */}
              {tempSignature ? (
                <img
                  src={tempSignature}
                  alt="Signature"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                // Display the SignatureItem component to capture signature
                <SignatureItem
                  item={field}
                  handleChange={(id, signatureData) => setTempSignature(signatureData)}
                />
              )}

              {/* Clear button on top of the signature */}
              <IconButton
                onClick={() => setTempSignature('')} // Clear only the temporary signature
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 2,
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        );


      case 'table':
        // Ensure field.columns is defined and has a length before using it
        if (field.columns && field.columns.length && !formData[field.id]?.rows) {
          // Initialize rows with one empty row if not set
          handleFormChange({
            [field.id]: {
              rows: [Array(field.columns.length).fill('')], // Initialize with one empty row
            },
          });
        }

        const handleAddRow = () => {
          const updatedRows = [...(formData[field.id]?.rows || [])];
          const emptyRow = Array(field.columns.length).fill('');
          updatedRows.push(emptyRow);

          handleFormChange({
            [field.id]: { ...formData[field.id], rows: updatedRows },
          });
        };

        const handleCellChange = (rowIndex, colIndex, value) => {
          const updatedRows = [...(formData[field.id]?.rows || [])];
          const updatedRow = [...updatedRows[rowIndex]];

          updatedRow[colIndex] = value;
          updatedRows[rowIndex] = updatedRow;

          handleFormChange({
            [field.id]: { ...formData[field.id], rows: updatedRows },
          });
        };

        return (
          <Box key={field.id} sx={{ mb: 2, overflowX: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              {field.label}
            </Typography>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  {field.columns?.map((col, colIndex) => (
                    <th
                      key={colIndex}
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        textAlign: 'left',
                      }}
                    >
                      {col.label}
                    </th>
                  )) || <th>No columns available</th>}
                </tr>
              </thead>
              <tbody>
                {formData[field.id]?.rows?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => (
                      <td
                        key={colIndex}
                        style={{ border: '1px solid #ddd', padding: '8px' }}
                      >
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={cell || ''}
                          onChange={(e) =>
                            handleCellChange(rowIndex, colIndex, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ mt: 2 }}
              onClick={handleAddRow}
            >
              Add Row
            </Button>
          </Box>
        );

      case 'fileUpload':
        return (
          <Box key={field.id} sx={{ mb: 2 }}>
            <Typography variant="body1">{field.label}</Typography>

            {/* Helper function to extract filename */}
            {form?.files?.length > 0 && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="h6">Uploaded Files:</Typography>
                <ul>
                  {form.files.map((file, index) => (
                    <li key={index}>
                      <a href={file.file} target="_blank" rel="noopener noreferrer">
                        {getFileNameFromUrl(file.file)}
                      </a>
                    </li>
                  ))}
                </ul>
              </Box>
            )}

            {/* FilePond component for uploading files */}
            <FilePond
              files={uploadedFiles[field.id] || []}
              onupdatefiles={(fileItems) => {
                const files = fileItems.map(fileItem => fileItem.file);
                handleFileChange(field.id, files);
              }}
              allowMultiple={true}
              maxFiles={10}
              name="files"
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              disabled={!isEditing}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  const handleShareEmail = () => {
    if (!emailRecipient) {
      alert("Please enter a recipient email.");
      return;
    }
    // Add logic to send the form by email here (e.g., dispatch or API call)
    alert(`Form shared with ${emailRecipient}`);
  };



  // Handle template selection
  const handleTemplateSelect = (template) => {
    const latestVersion = getLatestPublishedVersion(template);
    setSelectedTemplate(latestVersion);
    setFormData({}); // Clear form data when a new template is selected
    setIsEditing(true);
    setSidebarOpen(false); // Close sidebar when template is selected
  };

  const handleCloseSuccessModal = () => {
    setSubmissionSuccess(false);
  };

  // Handle form changes
  const handleFormChange = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  // Handle project selection
  const handleProjectSelect = (event) => {
    setSelectedProject(event.target.value);
    if (projectError) {
      setProjectError(''); // Clear the project error when a selection is made
    }
  };



  const handleSubmit = async (isDraft = false) => {
    setError(''); // Clear any previous error messages
    setProjectError(''); // Clear any previous project error messages

    if (!selectedProject && !isDraft) {
      // setError('Please select a project.');
      setProjectError('Please select a project.');
      return;
    }

    // Validate required form fields
    const missingFields = selectedTemplate.fields
      .filter(field => field.required && !formData[field.id])
      .map(field => field.label);

    if (missingFields.length > 0) {
      alert(`Please fill out the required fields: ${missingFields.join(', ')}`);
      return;
    }

    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('project', selectedProject);
    formDataToSend.append('template_version', selectedTemplate.id);
    formDataToSend.append('template', selectedTemplate.template);
    formDataToSend.append('created_by', getCurrentUserId);
    formDataToSend.append('updated_by', getCurrentUserId);
    formDataToSend.append('status', isDraft ? 'draft' : 'submitted');

    // Append uploaded files
    Object.keys(uploadedFiles).forEach((key) => {
      const filesArray = uploadedFiles[key];
      filesArray.forEach((file) => {
        formDataToSend.append('uploaded_files', file);
      });
    });

    // Append tempSignature to formDataToSend as Blob if it exists
    if (tempSignature) {
      try {
        const response = await fetch(tempSignature); // Convert base64 to Blob
        const signatureBlob = await response.blob();
        formDataToSend.append('signature', signatureBlob, 'signature.png');
        console.log('Appended signature as Blob');
      } catch (error) {
        console.error('Error converting signature to Blob:', error);
      }
    }

    // Append other form data (as JSON for complex fields)
    formDataToSend.append('data', JSON.stringify(formData));

    // Log the complete FormData for verification
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    // Send formDataToSend via Axios
    const submitAction = form?.id
      ? dispatch(updateForm({ formId: form.id, form: formDataToSend }))
      : dispatch(submitForm({ form: formDataToSend }));

    submitAction
      .then((response) => {
        console.log('Form submission successful:', response);
        setLoading(false);
        setSubmissionSuccess(true);
        setTimeout(() => setShowEmailSentMessage(true), 700);
        setTimeout(() => setShowEmailOptions(true), 1500);
      })
      .catch((error) => {
        console.error('Form submission error:', error);
        alert(`Failed to ${isDraft ? 'save draft' : 'submit form'}. Please check the console for details.`);
        setLoading(false);
      });
  };







  return (
    <Modal open={true} onClose={onClose} aria-labelledby="form-editor-modal" sx={{ display: 'flex' }}>
      <Box sx={modalStyle}>
        {!submissionSuccess ? (
          <>
            {/* Only show the drawer if sidebarOpen is true and form is undefined */}
            {!form && sidebarOpen && (
              <Drawer
                variant="persistent"
                open={sidebarOpen}
                sx={{
                  width: '300px',
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: '300px',
                    boxSizing: 'border-box',
                  },
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6">Select a Template</Typography>
                  <List>
                    {visibleTemplates.map((template) => {
                      const latestVersion = getLatestPublishedVersion(template);
                      return (
                        <ListItem
                          button
                          key={template.id}
                          onClick={() => {
                            handleTemplateSelect(template);
                            setSidebarOpen(false); // Close drawer when a template is selected
                          }}
                        >
                          <ListItemText primary={`${template.name} (Version ${latestVersion.version_number})`} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Drawer>
            )}

            {/* Main Form Content Section, expanding to full width if drawer is closed */}
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                width: sidebarOpen && !form ? 'calc(100% - 300px)' : '100%', // Expand fully when drawer is closed or in edit mode
                transition: 'width 0.3s ease', // Smooth transition for width adjustment
              }}
            >
              <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16 }}>
                <Close />
              </IconButton>

              {!isEditing && (
                <IconButton onClick={() => setIsEditing(true)} sx={{ position: 'absolute', right: 56, top: 16 }}>
                  <EditIcon />
                </IconButton>
              )}

              {/* Only show the back arrow if not in edit mode */}
              {!form && (
                <IconButton onClick={() => setSidebarOpen(true)} sx={{ position: 'absolute', left: 16, top: 16 }}>
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Typography variant="h4" gutterBottom align="center" marginBottom="0px">
                {selectedTemplate
                  ? `Form: ${getTemplateNameByVersion(selectedTemplate, templates)} (Version ${selectedTemplate.version_number})`
                  : 'New Form'}
              </Typography>

              {/* Template Visibility and Content */}
              {selectedTemplate && selectedTemplate.visible ? (
                <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: 2, mt: 1 }}>
                  {/* Project Selector */}
                  <FormControl
                    required
                    fullWidth
                    sx={{ mb: 4, mt: 1 }}
                    disabled={!isEditing}
                    error={Boolean(projectError)}
                  >
                    <InputLabel id="project-select-label" required>
                      Select Project
                    </InputLabel>
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={selectedProject}
                      onChange={handleProjectSelect}
                      label="Select Project"
                    >
                      <MenuItem value="">
                        <em>Select a project</em>
                      </MenuItem>
                      {projects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.project_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {projectError && (
                      <Typography variant="caption" color="error">
                        {projectError}
                      </Typography>
                    )}
                  </FormControl>

                  {/* Form Fields */}
                  {selectedTemplate.fields.map((field) => renderFormField(field))}
                </Box>
              ) : (
                <Typography variant="h6" color="error" align="center">
                  Select a template to begin
                </Typography>
              )}

              {selectedTemplate && selectedTemplate.visible && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: 1,
                    p: 1.2,
                    borderTop: '1px solid #e0e0e0',
                  }}
                >
                  {/* Conditional rendering of buttons based on isEditing */}
                  {!isEditing && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsEditing(true)}
                      size="small"
                      sx={{ minWidth: 100 }}
                    >
                      Edit
                    </Button>
                  )}

                  {isEditing && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        size="small"
                        sx={{ minWidth: 100 }}
                      >
                        Cancel
                      </Button>
                      {form?.status !== 'submitted' && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSubmit(true)}
                          disabled={loading}
                          startIcon={loading ? <CircularProgress size={16} /> : null}
                          size="small"
                          sx={{ minWidth: 100 }}
                        >
                          Save Draft
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit(false)}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={16} /> : null}
                        size="small"
                        sx={{ minWidth: 100 }}
                      >
                        {form?.status === 'draft' ? 'Submit' : form?.id ? 'Update' : 'Submit'}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </>
        ) : (
          // Submission Success Message and Email Options Section
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 3,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <Close />
            </IconButton>
            <CheckCircle sx={{ color: 'green', fontSize: 50, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Form Submitted Successfully!
            </Typography>

            {showEmailSentMessage && (
              <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                You’ve been emailed a copy of your form.
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const modalStyle = {
  display: 'flex',
  flexDirection: 'row',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '80%',
  height: '80%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const successModalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '50%',
  height: '30%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default FormEditor;
