function formatDateUK(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) return dateString; // Return original string if invalid date

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate.replace(/\//g, '-'); // Replace '/' with '-'
}

export default formatDateUK;