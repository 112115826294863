import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Offcanvas, FormGroup, FormLabel,Button, Row, Col, Table  } from 'react-bootstrap';
import { fetchProjects, fetchClients, updateCompany, fetchTrades, fetchCompanies } from '../../apislice.js';
import '../../App.css';
import { Modal } from '@mui/material';
import { setAuthToken } from '../../api/api.js';



// Validation Schema
const CompanyEditSchema = Yup.object().shape({

});

const ProjectEditForm = ( {show, onClose, selectedCompanyId} ) => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.api.projects.data);
    const clients = useSelector((state) => state.api.clients);
    const userId = useSelector((state) => state.auth.email.id);
    const trades = useSelector((state) => state.api.trades.data);
    const company = useSelector(state =>
        state.api.companies.data.find(company => company.id === selectedCompanyId)
    );
    const [hasAssociatedRecords, setHasAssociatedRecords] = useState(false);
    const token = useSelector(state => state.auth.email.access);
    
    console.log('Selected Company ID: ', selectedCompanyId);
    console.log('Company: ', company);


    useEffect(() => {
        dispatch(fetchProjects());
        dispatch(fetchClients());
        dispatch(fetchTrades());
        dispatch(fetchCompanies());
    }, [dispatch]);

    const initialValues = {
        name: company?.name || '',
        reference: company?.reference || '',
        address: company?.address || '',
        // Add other fields as necessary, using optional chaining (?.) to handle cases where company might be undefined
    };

    // const checkForAssociatedRecords = async (companyId) => {
    //     const response = await fetch('api/companies/${companyId}/check_associated_records');
    //     if (response.ok) {
    //         const data = await response.json();
    //         return data.has_associated_records;
    //     } else {
    //         console.error('Failed to check for associated records:', response);
    //         return false;
    //     }
    // };

    useEffect(() => {
        const checkForAssociatedRecords = async () => {
            // Set the Authorization header with the token
            const headers = {
                'Authorization': `Bearer ${token}`,
            };
    
            // Assuming your `api` object is set up to accept an options object including headers
            const response = await fetch(`http://localhost:8000/api/companies/${selectedCompanyId}/check_associated_records`, { headers });
            console.log('Response:', response.data);
            if (response.ok) {
                const data = await response.json();
                setHasAssociatedRecords(data.has_associated_records);
                console.log('Associated records:', data.has_associated_records);
            } else {
                console.error('Failed to check for associated records:', response.statusText);
            }
        };
    
        if (selectedCompanyId) {
            checkForAssociatedRecords();
        }
    }, [selectedCompanyId, token]);





    return (
        <Offcanvas show={show} onHide={onClose} placement="end" backdrop="statis" className="custom-offcanvas-width-projects">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="custom-offcanvas-title">Edit Company</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    initialValues={{
                        name: company?.name || '',
                        reference: company?.reference || '',
                        address: company?.address || '',
                        category: company?.category || '',
                        status: company?.status || '',
                        website: company?.website || '',
                        phone_number: company?.phone_number || '',
                        company_number: company?.company_number || '',
                        vat_number: company?.vat_number || '',
                        CIS_number: company?.CIS_number || '',
                        bank_name: company?.bank_name || '',
                        bank_address: company?.bank_address || '',
                        bank_account_number: company?.bank_account_number || '',
                        bank_sort_code: company?.bank_sort_code || '',
                        bank_IBAN: company?.bank_IBAN || '',
                        bank_BIC: company?.bank_BIC || '',
                        trades: company?.trades || '',
                    }}
                    validationSchema={CompanyEditSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        if (values.website && !/^(?:f|ht)tps?\:\/\//.test(values.website)) {
                            values.website = `http://${values.website}`;
                        }
                    
                        const formData = new FormData();

                        Object.keys(values).forEach((key) => {
                            if (key !== 'trades') { // Handle all fields except 'trades'
                                formData.append(key, values[key]);
                            }
                        });
                    
                        // Specifically handle 'trades' to ensure PKs are submitted
                        if (values.trades && Array.isArray(values.trades)) {
                            values.trades.forEach(tradePk => {
                                formData.append('trades', tradePk); // Append each trade PK
                            });
                        } else if (values.trades) {
                            formData.append('trades', values.trades); // Single selection
                        }

                        formData.append('user', userId); // Append the user ID


                        console.log('Submitting: ', values);
                        console.log('selectedCompanyId: ', selectedCompanyId);
                        dispatch(updateCompany({ companyId: selectedCompanyId, company: formData }))
                        .unwrap()
                        .then((result) => {
                            alert('Company Updated'); // Note the change in the message to reflect the action
                            setSubmitting(false);
                            dispatch(fetchCompanies());
                            resetForm();
                            onClose();
                        })
                        .catch((error) => {
                            console.error('Update failed:', error);
                            // Handle the error, e.g., show an error message
                        });

                    }}

                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form>
                        <Row >
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="name">Name</FormLabel> */}
                                    <Field name="name" type="text" className="form-control" placeholder="Name" onChange={handleChange} onBlur={handleBlur} />
                                    <ErrorMessage name="name" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="reference">Reference</FormLabel> */}
                                    <Field name="reference" type="text" className="form-control" placeholder="Reference" onChange={handleChange} onBlur={handleBlur}/>
                                    <ErrorMessage name="reference" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="address">Address</FormLabel> */}
                                    <Field name="address" type="text" className="form-control" placeholder="Address" onChange={handleChange} onBlur={handleBlur} />
                                    <ErrorMessage name="address" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field 
                                        name="category" 
                                        as="select" 
                                        className="form-control" 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        disabled={hasAssociatedRecords}
                                    >
                                        <option value="" label="Select a category" />
                                        <option value="Client" label="Client" />
                                        <option value="Supplier" label="Supplier" />
                                        <option value="Subcontractor" label="Subcontractor" />
                                        <option value="Designer" label="Designer" />
                                        <option value="Other" label="Other" />
                                    </Field>
                                    {hasAssociatedRecords && (
                                        <div className="form-error-message">Cannot change category while associated records exist</div>
                                    )}


                                    <ErrorMessage name="category" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field 
                                        name="status" 
                                        as="select" 
                                        className="form-control" 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                    >
                                        <option value="" label="Select a status" />
                                        <option value="Active" label="Active" />
                                        <option value="Inactive" label="Inactive" />
                                    </Field>

                                    <ErrorMessage name="status" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.category === 'Subcontractor' && (
                            <Row>
                                <Col className="mb-auto">
                                    <FormGroup className="mb-auto">
                                        <Field 
                                            name="trades" 
                                            as="select" 
                                            className="form-control"
                                            onChange={handleChange}
                                            disabled={hasAssociatedRecords}
                                        >
                                            <option value="" label="Select a trade" />
                                            {trades.filter(trade => trade.category === 'Trade').map(filteredTrade => (
                                                <option key={filteredTrade.id} value={filteredTrade.id}>{filteredTrade.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="trades" component="div" className="form-error-message" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        {values.category === 'Designer' && (
                            <Row>
                                <Col className="mb-auto">
                                    <FormGroup className="mb-auto">
                                        <Field name="trades" as="select" className="form-control">
                                            <option value="" label="Select a Category" />
                                            {trades.filter(trade => trade.category === 'Designer').map(filteredTrade => (
                                                <option key={filteredTrade.id} value={filteredTrade.id}>{filteredTrade.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="trades" component="div" className="form-error-message" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="website">Website</FormLabel> */}
                                    <Field name="website" type="text" className="form-control" placeholder="Website" />
                                    <ErrorMessage name="website" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="phone_number">Phone Number</FormLabel> */}
                                    <Field name="phone_number" type="text" className="form-control" placeholder="Phone Number" />
                                    <ErrorMessage name="phone_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="company_number">Company Number</FormLabel> */}
                                    <Field name="company_number" type="text" className="form-control" placeholder="Company Number" />
                                    <ErrorMessage name="company_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="vat_number">VAT Number</FormLabel> */}
                                    <Field name="vat_number" type="text" className="form-control" placeholder="VAT Number" />
                                    <ErrorMessage name="vat_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-auto">
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="CIS_number">CIS Number</FormLabel> */}
                                    <Field name="CIS_number" type="text" className="form-control" placeholder="CIS Number" />
                                    <ErrorMessage name="CIS_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_name">Bank Name</FormLabel> */}
                                    <Field name="bank_name" type="text" className="form-control" placeholder="Bank Name" />
                                    <ErrorMessage name="bank_name" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_address">Bank Address</FormLabel> */}
                                    <Field name="bank_address" type="text" className="form-control" placeholder="Bank Address" />
                                    <ErrorMessage name="bank_address" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_account_number">Bank Account Number</FormLabel> */}
                                    <Field name="bank_account_number" type="text" className="form-control" placeholder="Bank Account Number" />
                                    <ErrorMessage name="bank_account_number" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">
                                    {/* <FormLabel htmlFor="bank_sort_code">Bank Sort Code</FormLabel> */}
                                    <Field name="bank_sort_code" type="text" className="form-control" placeholder="Bank Sort Code" />
                                    <ErrorMessage name="bank_sort_code" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-auto">
                                    <Field name="bank_IBAN" type="text" className="form-control" placeholder="Bank IBAN" />
                                    <ErrorMessage name="bank_IBAN" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-auto">

                                    <Field name="bank_BIC" type="text" className="form-control" placeholder="Bank BIC" />
                                    <ErrorMessage name="bank_BIC" component="div" className="form-error-message"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" className="btn btn-primary" >Submit</Button>
                            </Col>
                        </Row>


                        
                    </Form>
                    )}
                </Formik>


                
                
            </Offcanvas.Body>
            

        </Offcanvas>
    );
};

export default ProjectEditForm;