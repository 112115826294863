import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

function LinkedItemCustomizer({ item, formItems = [], updateItem, close }) {
    const [linkedItems, setLinkedItems] = useState(item.linkedItems || []);
  
    // Toggle a linked item
    const handleToggleLinkedItem = (id) => {
      if (linkedItems.includes(id)) {
        setLinkedItems(linkedItems.filter((itemId) => itemId !== id)); // Remove the item
      } else {
        setLinkedItems([...linkedItems, id]); // Add the item
      }
    };
  
    // Save the linked items
    const handleSave = () => {
      updateItem({ ...item, linkedItems });
      close();
    };
  
    return (
      <Dialog open onClose={close}>
        <DialogTitle>Link Items Conditionally</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="h6" gutterBottom>
              Select Items to Link Conditionally
            </Typography>
            <Grid container spacing={2}>
              {formItems.length > 0 ? (
                formItems.map((formItem) => (
                  <Grid item xs={12} key={formItem.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={linkedItems.includes(formItem.id)}
                          onChange={() => handleToggleLinkedItem(formItem.id)}
                        />
                      }
                      label={formItem.label || formItem.item_type}
                    />
                  </Grid>
                ))
              ) : (
                <Typography>No form items available</Typography>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default LinkedItemCustomizer;
  
