// src/components/FormItems/TextInputItem.js

import React from 'react';
import { TextField } from '@mui/material';

function TextInputItem({ item, handleChange, error = false, helperText = '' }) {
  return (
    <TextField
      label={item.label}
      variant="outlined"
      fullWidth
      required={item.required}
      value={item.value || ''} // Support for default value
      onChange={(e) => handleChange && handleChange(item.id, e.target.value)}
      sx={{ mb: 2 }}
      inputProps={{ style: { fontSize: item.font_size || 14 } }} // Support dynamic font size
      error={error}
      helperText={helperText}
    />
  );
}

export default TextInputItem;
