import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const DataGridComponent = ({ columns, rows, pageSize = 5, checkboxSelection = false, ...props }) => {
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        checkboxSelection={checkboxSelection}
        {...props}
      />
    </Box>
  );
};

export default DataGridComponent;
