import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import html2pdf from 'html2pdf.js';

const DownloadPdfButton = ({ formId }) => {
  const forms = useSelector(state => state.api.forms.data);
  const formTemplates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);

  const handleDownload = () => {
    const form = forms.find(f => f.id === formId);
    const template = formTemplates.find(t => t.id === form.template);

    if (!form || !template) {
      alert('Form or Template data not found');
      return;
    }

    generatePdf(form, template, projects);
  };

  const getProjectName = (projectId, projects) => {
    const project = projects.find(p => p.id === projectId);
    return project ? project.project_name : '';
  };

  const generatePdf = (form, template, projects) => {
    const projectName = getProjectName(form.project, projects);
    const fields = template.versions[0].fields;

    const pdfContent = document.createElement('div');
    pdfContent.innerHTML = `
      <h1>${template.name}</h1>
      <p><strong>Project:</strong> ${projectName}</p>
      <p><strong>Date:</strong> ${new Date(form.created_at).toLocaleDateString()}</p>
      <p><strong>Number:</strong> ${form.number}</p>
      <table border="1" cellpadding="5" cellspacing="0" style="width:100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="text-align: left;">Field</th>
            <th style="text-align: left;">Value</th>
          </tr>
        </thead>
        <tbody>
          ${
            fields && Array.isArray(fields)
              ? fields.map(field => {
                  // Find the corresponding value in form.data using the field ID
                  const formDataItem = Object.values(form.data).find(item => item.id === field.id);
                  const value = formDataItem ? formDataItem.value : '';

                  // Ignore 'Header' elements and null values
                  if (field.element === 'Header' || value === null) {
                    return '';
                  }

                  // Render signature images
                  if (field.element === 'Signature' && value) {
                    return `
                      <tr>
                        <td>${field.label}</td>
                        <td><img src="${value}" alt="Signature" style="max-width: 200px; max-height: 100px; border: 1px solid #000;" /></td>
                      </tr>
                    `;
                  }

                  // Render other form fields
                  return `
                    <tr>
                      <td>${field.label}</td>
                      <td>${value}</td>
                    </tr>
                  `;
                }).join('')
              : '<tr><td colspan="2">No form fields found.</td></tr>'
          }
        </tbody>
      </table>
    `;

    html2pdf()
      .from(pdfContent)
      .save(`${template.name}.pdf`);
  };

  return (
    <IconButton color="secondary" onClick={handleDownload}>
      <GetApp />
    </IconButton>
  );
};

export default DownloadPdfButton;
