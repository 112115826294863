// src/components/Login.js

import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { loginUser, loginUserWithRedirectCode } from '../actions/authActions';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Link,
  Paper,
  Container,
  LinearProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Adjust primary color here
    },
    secondary: {
      main: '#dc004e', // Adjust secondary color here
    },
  },
  typography: {
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
  },
});

// Utility function to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ loginUser, loginUserWithRedirectCode }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const loginStatus = useSelector((state) => state.auth.loginStatus);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false); // Loading state

  // Extract redirect_code from query parameters
  const redirectCode = query.get('redirect_code');
  const hasExchangedCode = useRef(false);

  useEffect(() => {
    if (redirectCode && !hasExchangedCode.current) {
      hasExchangedCode.current = true; // Prevent multiple exchanges
      setLoading(true);
      loginUserWithRedirectCode(redirectCode)
        .then(() => {
          setLoading(false);
          // Redirect to home page after successful token exchange
          navigate('/', { replace: true });
        })
        .catch(() => {
          setLoading(false);
          alert('Failed to log in. Please try again.');
          // Redirect to login page if token exchange fails
          navigate('/login', { replace: true });
        });
    }
  }, [redirectCode, loginUserWithRedirectCode, navigate]);

  // Redirect authenticated users away from the login page
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/'); // Redirect to home page
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Submitting login form');

    setLoading(true); // Set loading to true on form submission

    // Submit login credentials
    loginUser(formData)
      .then((data) => {
        setLoading(false); // Stop loading after login
        console.log('Login response:', data);

        if (data.redirect) {
          // Redirect is needed
          const tenantDomain = data.tenant_domain;
          const redirectCode = data.redirect_code;

          console.log(`Redirecting to tenant domain: ${tenantDomain} with code: ${redirectCode}`);

          // Redirect to the tenant domain with the redirect code
          window.location.href = `${tenantDomain}/login?redirect_code=${redirectCode}`;
        } else {
          // Normal login flow
          navigate('/');
        }
      })
      .catch((error) => {
        setLoading(false); // Stop loading in case of an error
        console.error('Login error:', error);
        // Handle error, e.g., show error message
        alert('Authentication failed. Please check your credentials and try again.');
      });
  };

  useEffect(() => {
    if (loginStatus === 'success') {
      setLoading(false); // Ensure loading is false after successful login
      navigate('/');
    }
  }, [loginStatus, navigate]);

  // Debugging: Log the redirectCode value
  useEffect(() => {
    if (redirectCode) {
      console.log('Received redirect_code:', redirectCode);
    }
  }, [redirectCode]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper elevation={4} sx={{ p: 5, mt: 8, position: 'relative' }}>
          {/* Loading bar at the top of the login form */}
          {loading && (
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />
          )}

          <Typography variant="h4" align="center">
            Login
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              fullWidth
              label="Email address"
              variant="outlined"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              sx={{ my: 2, mt: 4 }}
              disabled={loading} // Disable input while loading
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              disabled={loading} // Disable input while loading
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ py: 1.5, mb: 1.5 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? 'Logging in...' : 'Login'}
            </Button>

            <Link
              component={RouterLink}
              to="/forgot-password"
              variant="body2"
              sx={{ display: 'block', mb: 2 }}
            >
              Forgot Password?
            </Link>
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default connect(null, { loginUser, loginUserWithRedirectCode })(Login);
