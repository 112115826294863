import React from 'react';
import { FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

function CheckboxItem({ item, handleChange, error = false, helperText = '' }) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            required={item.required}
            checked={item.checked || false} // Support for default value
            onChange={(e) => handleChange && handleChange(item.id, e.target.checked)}
          />
        }
        label={item.label}
        sx={{ mb: 2 }}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
}

export default CheckboxItem;

