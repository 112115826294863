import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useDroppable, useDraggable } from '@dnd-kit/core';

// DropZone Component
const DropZone = ({ zoneId, linkedItemId, formElements, handleDrop }) => {
    const { isOver, setNodeRef } = useDroppable({
      id: `dropzone-${zoneId}`,
      data: { linkedItemId } // Pass the linkedItemId as data for the drop zone
    });
  
    return (
      <Box
        ref={setNodeRef}
        sx={{
          border: `1px dashed ${isOver ? 'green' : 'gray'}`,
          padding: '16px',
          minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f0f0f0',
          marginBottom: '16px',
        }}
        onDrop={(e) => {
          e.preventDefault();
          const element = e.dataTransfer.getData('text');
          handleDrop(zoneId, element); // Handle the drop and store the element
        }}
        onDragOver={(e) => e.preventDefault()} // Allow drop
      >
        {/* Display the dropped item or a placeholder */}
        {formElements[zoneId] ? (
          <Typography>{formElements[zoneId]}</Typography>
        ) : (
          <Typography color="textSecondary">Drop an item here</Typography>
        )}
      </Box>
    );
  };

// LinkedItem Component
const LinkedItem = ({ item, onDrop }) => {
    const [dropZones, setDropZones] = useState([1, 2]); // Start with two drop zones
    const [formElements, setFormElements] = useState(item.formElements || {}); // Load from item
  
    const handleDrop = (zoneId, element) => {
      setFormElements((prevState) => ({
        ...prevState,
        [zoneId]: element, // Store the dropped form element in the corresponding drop zone
      }));
  
      // Notify the parent to update the global form state
      onDrop && onDrop(item.id, zoneId, element);
    };
  
    const addDropZone = () => {
      setDropZones([...dropZones, dropZones.length + 1]);
    };
  
    return (
      <Box className="linked-item" sx={{ padding: 2 }}>
        <Typography variant="h6">Linked Item</Typography>
  
        {/* Render the drop zones */}
        {dropZones.map((zoneId) => (
          <DropZone
            key={zoneId}
            zoneId={zoneId}
            linkedItemId={item.id} // Pass the LinkedItem ID to each drop zone
            formElements={formElements}
            handleDrop={handleDrop}
          />
        ))}
  
        {/* Button to add more drop zones */}
        <Button variant="outlined" onClick={addDropZone}>
          Add another drop zone
        </Button>
      </Box>
    );
  };
  

export default LinkedItem;
