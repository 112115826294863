import '../../App.css';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchCompanies, deleteCompany, fetchProjects, fetchClients, newCompanyFromWebSocket, deleteNearMissFromWebSocket, updateNearMissFromWebSocket  } from '../../apislice.js';

import CompanyAddForm from './CompanyAdd.js'
// import CompanyEditForm from './CompanyEdit.js';
import formatDateUK from '../UKDate.js';
// import Form from 'react-bootstrap/Form';
// import Badge from 'react-bootstrap/Badge';

// import {setAuthToken} from '../../api/api.js';
// import { render } from 'react-dom';
// import api from '../../api/api.js';

import { connectWebSocket } from '../../websocketService';
import { useUserGroup } from '../users/userGroup.js';


import PermissionDenied from '../permissionDenied.js';

import CompaniesManage from './CompaniesManage.js';

//MUI
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
import { AddCircleOutline, GetApp, Refresh } from '@mui/icons-material';
// import { useTheme } from '@mui/material/styles';
// import { useMediaQuery } from '@mui/material';
// import Grid2 from '@mui/material/Unstable_Grid2/Grid2.js';
import { Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Chip from '@mui/material/Chip';






const CompaniesHome = () => {
    const dispatch = useDispatch();
    const isApproved = useUserGroup(['Admin', 'Manager', 'Director']);
    // const [nearMissData, setnearMissData] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [showCompanyAddForm, setCompanyAddForm] = useState(false);
    const [showCompanyEditForm, setCompanyEditForm] = useState(false);
    const [selectedCompanyId, setCompanyId] = useState(null);
    // const [ showEditForm, setShowEditForm ] = useState(false);
    // const state = useSelector((state) => state.api);
    // const accessToken = useSelector((state) => state.auth.email.access);
    const companies = useSelector((state) => state.api.companies.data);
    const status = useSelector((state) => state.api.companies.status);
    // const projects = useSelector((state) => state.api.projects.data);
    // const projectStatus = [...new Set(projects.map(project => project.status))];;
    // const [selectedStatus, setSelectedStatus] = useState('In Progress');
    const currentUserId = useSelector(state => state.auth.email.id);
    const error = useSelector((state) => state.api.error);
    const [visibleEntries, setVisibleEntries] = useState(10);
    // const [filters, setFilters] = useState({ reference: '', name: '', status: '', category: '' });
    const [searchText, setSearchText] = useState('');
    // const [showCompaniesManage, setShowCompaniesManage] = useState(false);
    const [showManageModal, setShowManageModal] = useState(false);
    const [sortModel, setSortModel] = useState([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
    const [selectedCategory, setSelectedCategory] = useState('');

    
    const categoryChoices = [
      { value: 'Client', label: 'Client' },
      { value: 'Subcontractor', label: 'Subcontractor' },
      { value: 'Supplier', label: 'Supplier' },
      { value: 'Designer', label: 'Designer' },
      { value: 'Consultant', label: 'Consultant' },
      { value: 'Other', label: 'Other' },
    ];

    // const statusChoices = [
    //   { value: 'Active', label: 'Active' },
    //   { value: 'Inactive', label: 'Inactive' },
    // ];

    const filteredCompanies = companies.filter((company) => {
      const searchLowered = searchText.toLowerCase();
    
      const matchesSearchText = company.name.toLowerCase().includes(searchLowered) ||
        company.reference.toLowerCase().includes(searchLowered) ||
        company.status.toLowerCase().includes(searchLowered) ||
        company.category.toLowerCase().includes(searchLowered) ||
        (company.trades_names && company.trades_names.join(' ').toLowerCase().includes(searchLowered));
      
      // Check if the company's category matches the selected category
      const matchesCategory = selectedCategory ? company.category === selectedCategory : true;

    
      return matchesSearchText && matchesCategory;
    });

    



    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(fetchProjects());
          dispatch(fetchClients());
          dispatch(fetchCompanies());

          
        } catch (error) {
          // Handle errors, possibly update status to 'failed'
        }
      };
    
      if (status === 'idle') {
        fetchData();
      }
    }, [status, dispatch]);

    const handleRefreshClick = () => {
      dispatch(fetchCompanies());
    };

    useEffect(() => {
      const handleWebSocketMessage = (data) => {
        console.log('Websocket message received: ', data);

        if (data.message && data.message.new_company) {
          // Update the state with the new diary entry
          if(currentUserId && data.message.new_company.user !== currentUserId){
            console.log('Dispatching updateCompaniesMissFromWebSocket: ', data.message.new_company)

            dispatch(newCompanyFromWebSocket(data.message.new_company));
          }
        }
        else if (data.message && data.message.near_miss_deleted) {
          if(currentUserId && data.message.user !== currentUserId){
            console.log('Dispatching deleteCompany: ', data.message.near_miss_deleted)
            dispatch(deleteNearMissFromWebSocket(data.message.near_miss_deleted));
          }
          }
        else if (data.message && data.message.updated_near_miss) {
          if(currentUserId && data.message.updated_near_miss.user !== currentUserId){
            console.log('Dispatching deleteCompany: ', data.message.updated_near_miss)
            dispatch(updateNearMissFromWebSocket(data.message.updated_near_miss));
          }
          }}

      connectWebSocket('companies',handleWebSocketMessage);
      
    }, [dispatch, currentUserId]);

    const handleCategoryClick = (categoryValue) => {
      // Toggle selection: if the same category is clicked, clear the selection, otherwise update it
      setSelectedCategory(prevCategory => prevCategory === categoryValue ? '' : categoryValue);
    };


    const handleCompanyEditForm = (companyId) => {
        setCompanyId(companyId);
        setCompanyEditForm(true);
        console.log('Edit Company Form', companyId);
    };

    // const handleAddCompanyForm = () => {
    //     setCompanyAddForm(true);
    //     console.log('Edit Company Form');
        
    // };


    const handleCompaniesManage = () => {
      setShowManageModal(!showManageModal);
    };
      

    const handleCompanyDelete = (companyId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Near Miss?');

        if (confirmDelete) {
          console.log('Delete Near Miss', companyId);
          dispatch(deleteCompany(companyId));
        }else{
          console.log('Delete Cancelled');
        }
        
    };


    // const handleDownloadPdf = async (companyId) => {
    //   console.log('Download PDF', companyId);
    //   const response = await fetch(`http://localhost:8000/api/pdf/nearmiss/?id=${companyId}`, {
    //     method: 'GET',
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //       'Content-Type': 'application/pdf',
    //     },
    //   });
    
    //   if (response.ok) {
    //     const blob = await response.blob();
    //     const downloadUrl = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = downloadUrl;
    //     link.setAttribute('download', 'my_pdf.pdf'); // Any file name
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   }
    // };


    // const filteredProjects = projects.filter((project) => project.status === selectedStatus);

    if (!isApproved) {
      // Render an alternative view or message
      // return <div>You do not have permission to access this page.</div>;
      return <PermissionDenied />
    }

    const handleSearch = (event) => {
      setSearchText(event.target.value);
    };
    
    // const filteredCompanies = companies.filter((company) => {
    //   // Convert search text to lowercase
    //   const searchLowered = searchText.toLowerCase();
    
    //   // Return true if any field includes the search text
    //   return (
    //     company.name.toLowerCase().includes(searchLowered) ||
    //     company.reference.toLowerCase().includes(searchLowered) ||
    //     company.status.toLowerCase().includes(searchLowered) ||
    //     company.category.toLowerCase().includes(searchLowered) ||
    //     // Add more fields as needed
    //     (company.trades_names && company.trades_names.join(' ').toLowerCase().includes(searchLowered)) 
        
    //   );
    // });

    const exportToCsv = (filename, rows) => {
      // Create CSV content
      const csvContent = rows.map(row => Object.values(row).join(",")).join("\n");
  
      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
      // Create an anchor tag to trigger the download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  const prepareDataForExport = () => {
    // Assuming 'companies' is the state or prop holding the data shown in the DataGrid
    return companies.map(company => ({
        ID: company.id,
        Name: company.name,
        Reference: company.reference,
        Status: company.status,
        Category: company.category,
        Type: company.trades_names.join(', '), // Assuming trades_names is an array of strings
        'Date Created': formatDateUK(company.created_at),
        'Date Modified': formatDateUK(company.updated_at),

    }));
  };



    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'name',
            headerName: 'Name',
            // width: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'reference',
            headerName: 'Reference',
            // width: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
        },
        {
            field: 'category',
            headerName: 'Category',
            // width: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'trades_names',
            headerName: 'Type',
            flex: 1,
            editable: false,

        },
        {
            field: 'created_at', // Use the actual field name from your data
            headerName: 'Date Created',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            },
        },
        {
            field: 'updated_at',
            headerName: 'Date Modified',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <Button
                        // variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleCompanyEditForm(params.row.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        // variant="contained"
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleCompanyDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </strong>
            ),
        },
    ];





    const renderCompanyList = () => {


        if (status === 'loading') {
          return <div>Loading...</div>;
        }
    
        if (status === 'failed') {
          return <div>
            Error: An error occurred while fetching data.
            <button onClick={handleRefreshClick}>Try again</button>
            {error && <div>Error Details{error}</div>}
            
            </div>;
          
        }

    
        return (

            
            <Container maxWidth style={{padding: '10px 0px 0px 0px'}}>
                <Container maxWidth style={{padding: '0px 0px 0px 0px'}}>

                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4" gutterBottom>
                            Companies
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        sx={{ fontSize: '12px', padding: '5px 0px 8px 16px' }}
                    >

                        <Grid item sx={{ order: { xs: 1, sm: 1 } }}>


                        </Grid>
                        <Grid item sx={{ order: { xs: 1, sm: 2 } }}> 
                            <Box display="flex" gap={1}> {/* Use Box to align buttons next to each other with some gap */}
                              <Button 
                                // variant="outlined" 
                                color="primary" 
                                onClick={handleCompaniesManage}
                                startIcon={<AddCircleOutline />}
                                >
                                  Add</Button>
                                <Button onClick={handleRefreshClick} startIcon={<Refresh />}>
                                </Button>
                                <Button
                                    // variant="contained"
                                    color="primary"
                                    onClick={() => exportToCsv('companies.csv', prepareDataForExport())}
                                    startIcon={<GetApp />}
                                >
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '0px' }}>
                      <TextField
                        id="search"
                        size='small'
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Search Companies..."
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>

                      {categoryChoices.map((category) => (
                        <Chip
                          key={category.value}
                          label={category.label}
                          onClick={() => handleCategoryClick(category.value)}
                          style={{ marginRight: '10px', marginBottom: '10px', marginTop: '10px'}}
                          variant={selectedCategory === category.value ? "filled" : "outlined"}
                          color={selectedCategory === category.value ? "primary" : "default"}
                        />
                      ))}
                    </Grid>
                  

                    

                    <Box sx={{ height: `calc(100vh - 300px)` }}>
                      <DataGrid
                          rows={filteredCompanies}
                          columns={columns}
                          pageSize={visibleEntries}
                          sortModel={sortModel}
                          onSortModelChange={(model) => setSortModel(model)}
                          autoHeight={false}
                          stickyHeader
                          // checkboxSelection
                          disableColumnResize={false}
                          disableSelectionOnClick
                          onSelectionModelChange={(newSelection) => {
                              console.log(newSelection);
                          }}

                      />


                    </Box>

                </Container>

            </Container>


        )

          





    };
    



      return (
        <Container fluid style={{padding: "0px 0px 0px 0px"}} maxWidth={false}>
          {renderCompanyList()}

          {showCompanyAddForm && (
                <div>
                    <CompanyAddForm show={showCompanyAddForm} onClose={() => setCompanyAddForm(false)} />
                </div>
          )}

          {showCompanyEditForm && (


                    <CompaniesManage open={showCompanyEditForm} handleClose={() => setCompanyEditForm(false)} companyId={selectedCompanyId}/>
                    

          )}
          {/* <CompanyEditForm show={showEditCompanyModal} onHide={() => setCompanyEditForm(false)} companyId={selectedCompanyId} /> */}
          {showManageModal && (
            <CompaniesManage open={showManageModal} handleClose={() => setShowManageModal(false)} />
          )}

        

        </Container>
        
        );
    };

export default CompaniesHome;