import React, { useState } from 'react';
import { Dialog, Toolbar, IconButton, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';

import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



const FullScreenModal = ({ open, handleClose, title, children }) => {
    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            </Toolbar>
            {children}
        </Dialog>
    );
}

export default FullScreenModal;

