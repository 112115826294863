import * as XLSX from 'xlsx';

export const exportOrdersToExcel = (orders, users, projects) => {
    const data = orders.map(order => {
        const user = users.find(u => u.id === order.user);
        const project = projects.find(proj => proj.id === order.project);
        return {
            OrderID: order.id,
            User: user ? `${user.first_name} ${user.last_name}` : 'Unknown User',
            ProjectRef: project ? project.project_refernce : 'Unknown Project',
            Project: project ? project.project_name : 'Unknown Project',
            DateOrdered: order.date_ordered,
            DeliveryDate: order.delivery_date,
            Status: order.status
        };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    XLSX.writeFile(workbook, 'orders.xlsx');
};