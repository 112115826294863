import React from 'react';
import { Typography } from '@mui/material';

function HeadingItem({ item }) {
  return (
    <Typography
      variant={item.size || 'h6'} // Support dynamic heading size, defaults to h6
      align={item.alignment || 'left'} // Support alignment (left, center, right), defaults to left
      sx={{ mb: 2 }} // Add margin-bottom for spacing
    >
      {item.label || 'Default Heading'} 
    </Typography>
  );
}

export default HeadingItem;
