import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addProject} from '../apislice';
import { updateProject } from '../actions/projectActions';

const ProjectForm = ({ projectData, isEditMode, addProject, updateProject, onClose }) => {
  const [formData, setFormData] = useState({
    project_name: '',
    project_reference: '',
    budget: '',
    start_date: '',
    end_date: '',
    status: 'Planning',
    project_description: '',
    location: '',
  });
  const [validationErrors, setValidationErrors] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    if (projectData && isEditMode) {
      // If in edit mode and projectData is provided, set the form data
      setFormData(projectData);
    }
  }, [projectData, isEditMode]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.project_name) {
      errors.project_name = 'Project name is required';
    }
    if (!formData.budget) {
      errors.budget = 'Budget is required';
    }
    if (!formData.start_date) {
      errors.start_date = 'Start date is required';
    }
    if (!formData.end_date) {
      errors.end_date = 'End date is required';
    }
    if (!formData.project_description) {
      errors.project_description = 'Project description is required';
    }
    if (!formData.location) {
      errors.location = 'Location is required';
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the form before dispatching the action
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      if (isEditMode) {
        // If in edit mode, update the project
        updateProject(formData)
          .then(() => {
            setIsFormSubmitted(true);
          })
          .catch((error) => {
            console.error('API request error:', error);
          });
      } else {
        // If not in edit mode, add a new project
        addProject(formData)
          .then(() => {
            setIsFormSubmitted(true);
          })
          .catch((error) => {
            console.error('API request error:', error);
          });
      }
      setValidationErrors([]);
    } else {
      // If there are validation errors, set them in the state
      setValidationErrors(errors);
    }
  };

  return (
    <div className="edit-window">
      {isFormSubmitted ? (
        <div className="success-message">
          {isEditMode ? 'Project has been updated successfully!' : 'Project has been added successfully!'}
        </div>
      ) : (
        <div>
          <h2>{isEditMode ? 'Edit Project' : 'Add Project'}</h2>
          <form onSubmit={handleSubmit}>
            {/* Render input fields and validation errors here */}
            {/* ... */}
            <button type="submit">{isEditMode ? 'Update Project' : 'Add Project'}</button>
            <button type="button" onClick={onClose}>Close</button>
          </form>
          <div className="error-messages">
            {/* Display validation error messages */}
            {Object.values(validationErrors).map((error, index) => (
              <div key={index} className="error">
                {error}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addProject: (project) => dispatch(addProject(project)),
  updateProject: (project) => dispatch(updateProject(project)),
});

export default connect(null, mapDispatchToProps)(ProjectForm);
