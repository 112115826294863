import { 
    SET_SELECTED_PROJECT, 
    UPDATE_PROJECT,
    SELECT_PROJECT_TO_EDIT,
    OPEN_EDIT_WINDOW,
    CLOSE_EDIT_WINDOW,
    DELETE_PROJECT,
    ADD_PROJECT,
 } from "../actions/projectActions";

const initialState = {
    selectedProject: null,
    projects: [],
    clients: [],
    isEditWindowVisible: false,
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload,
            };
        case ADD_PROJECT:
            return {
                ...state,
                projects: [...state.projects, action.payload],
            };
        case DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.payload),
            };

        case UPDATE_PROJECT:
            const updatedProjects = state.projects.map((project) =>
                project.id === action.payload.id ? action.payload : project
            );
            return {
                ...state,
                projects: updatedProjects,
                //selectedProject: state.selectedProject && state.selectedProject.id === action.payload.id ? action.payload : state.selectedProject,
            };
        case 'SET_SHOW_DETAILS':
            return {
                ...state,
                showDetails: action.showDetails,
            };
        case SELECT_PROJECT_TO_EDIT:
            console.log('SELECT_PROJECT_TO_EDIT', action.payload);
            return {
                ...state,
                selectedProject: action.payload,
            };
        case OPEN_EDIT_WINDOW:
            console.log('OPEN_EDIT_WINDOW');
            return {
                ...state,
                isEditWindowVisible: true,
            };
        case CLOSE_EDIT_WINDOW:
            return {
                ...state,
                isEditWindowVisible: false,
            };
        
        default:
            return state;
    }
};

export default projectReducer;