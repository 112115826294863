import { combineReducers } from "redux";
import projectReducer from "./projectReducer";
import formsReducer from "./formsReducer";
import authReducer from "./authReducer";
import apiSliceReducer from '../apislice';

const rootReducer = combineReducers({
    project: projectReducer,
    // forms: formsReducer,
    auth: authReducer,
    api: apiSliceReducer,


});

export default rootReducer;