import React, { useCallback, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the plugins
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateSize);

const FileUploadItem = ({ item, handleChange }) => {
  const [files, setFiles] = useState([]);

  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems);
    const uploadedFiles = fileItems.map((fileItem) => fileItem.file);
    handleChange(item.id, uploadedFiles); // Pass the uploaded files to the parent handler
  };

  return (
    <Box
      sx={{
        border: '1px dashed #ccc',
        p: 2,
        borderRadius: '4px',
        textAlign: 'center',
        mb: 2,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography variant="body1">{item.label}</Typography>
      <FilePond
        files={files}
        onupdatefiles={handleUpdateFiles}
        allowMultiple={true}
        maxFiles={10}
        name="files"
        labelIdle="Drag & Drop your files or <span class='filepond--label-action'>Browse</span>"
      />
    </Box>
  );
};

export default FileUploadItem;