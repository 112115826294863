// Get the WebSocket base URL from environment variables
console.log('WebSocket Base URL:', process.env.REACT_APP_WEBSOCKET_URL); // Debugging line
const BASE_WS_URL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:8001/ws'; // Default to localhost for development

// Define a function to construct the WebSocket URL for a specific route
// const getWebSocketUrl = (route) => {
//     const url = `${BASE_WS_URL}/${route}/`;
//     console.log(`Constructed WebSocket URL: ${url}`); // Debugging line
//     return url;

// };

const getWebSocketUrl = (route) => {
    // Ensure BASE_WS_URL does not end with a slash and route does not start with one
    const sanitizedBaseUrl = BASE_WS_URL.replace(/\/+$/, '');
    const sanitizedRoute = route.replace(/^\/+/, '');
    const url = `${sanitizedBaseUrl}/${sanitizedRoute}/`;
    console.log(`Constructed WebSocket URL: ${url}`); // Debugging line
    return url;
};

let sockets = {};

export const connectWebSocket = (route, onMessage) => {
    const wsUrl = getWebSocketUrl(route);
    console.log(`Connecting to WebSocket at ${wsUrl}`); // Debugging line
    const socket = new WebSocket(wsUrl);

    socket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        onMessage(data);
    };

    socket.onopen = () => {
        console.log(`Connected to WebSocket at ${wsUrl}`);
    };

    socket.onclose = () => {
        console.log(`Disconnected from WebSocket at ${wsUrl}`);
    };

    socket.onerror = (e) => {
        console.log("WebSocket error: ", e);
    };

    // Store the socket in the sockets dictionary
    sockets[route] = socket;
};

export const sendMessage = (route, message) => {
    const socket = sockets[route];
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
    } else {
        console.log(`WebSocket for route ${route} is not open.`);
    }
};

export const disconnectWebSocket = (route) => {
    const socket = sockets[route];
    if (socket) {
        socket.close();
        delete sockets[route];
    } else {
        console.log(`No WebSocket connection found for route ${route}.`);
    }
};
