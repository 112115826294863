import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormGroup, FormControlLabel, Dialog, AppBar, Toolbar, IconButton, Typography, Box, List, ListItem, ListItemText, Snackbar, Alert, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, FieldArray } from 'formik';
import api from '../../api/api';  // Your API utility
import { useSelector, useDispatch } from 'react-redux';
import { fetchGroups } from '../../apislice';  // Your action to fetch groups

const SettingsModal = ({ open, handleClose, onNotify }) => {
    const groups = useSelector(state => state.api.groups.data);
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedApp, setSelectedApp] = useState('');  // Track selected app
    const [adminPermissionsByApp, setAdminPermissionsByApp] = useState({});  // Grouped by app
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [modifiedPermissions, setModifiedPermissions] = useState(new Set());  // Track modified permissions
    const [loading, setLoading] = useState(false);  // Track loading state

    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });  // Snackbar state

    // Fetch Admin permissions grouped by app
    useEffect(() => {
        if (open && groups.length > 0) {
            const adminGroup = groups.find(g => g.name === 'Admin');  // Admin permissions as baseline
            if (adminGroup) {
                const permissionsByApp = {};
                adminGroup.permissions.forEach(permission => {
                    const appLabel = permission.content_type__app_label;
                    if (appLabel !== 'Address') {  // Exclude "Address" app from the list
                        if (!permissionsByApp[appLabel]) {
                            permissionsByApp[appLabel] = [];
                        }
                        permissionsByApp[appLabel].push(permission);
                    }
                });
                setAdminPermissionsByApp(permissionsByApp);
            }
        }
    }, [open, groups]);

    // Handle group selection and set permissions
    const handleGroupChange = (groupId, setFieldValue) => {
        const group = groups.find(g => g.id === parseInt(groupId));
        setSelectedGroup(groupId);
        const permissions = group.permissions.map(permission => permission.id);
        setGroupPermissions(permissions);  // Set selected group's permissions state
        setFieldValue('permissions', permissions); // Set Formik's permissions field with selected group's permissions
    };

    // Handle app selection
    const handleAppSelect = (appLabel) => {
        setSelectedApp(appLabel);  // Set the selected app
    };

    // Handle permission changes and immediately highlight
    const handlePermissionChange = (permissionId, values, setFieldValue) => {
        const updatedPermissions = values.permissions.includes(permissionId)
            ? values.permissions.filter(id => id !== permissionId)  // Remove permission
            : [...values.permissions, permissionId];  // Add permission

        setFieldValue('permissions', updatedPermissions);

        // Track modified permissions for highlighting
        setModifiedPermissions(prev => {
            const updatedModified = new Set(prev);
            updatedModified.add(permissionId);  // Add permission to the modified set (highlight as clicked)
            return updatedModified;
        });
    };

    // Save updated permissions
    const handleSave = async (values) => {
        setLoading(true);  // Start loading
        try {
            await api.post('/group-permissions/update/', {
                group_id: selectedGroup,
                permission_ids: values.permissions,  // Permissions from Formik state
            });

            setLoading(false);  // End loading

            // Display success notification using Snackbar
            onNotify('Permissions updated successfully!', 'success');

            // Fetch updated groups after the permissions are updated successfully
            dispatch(fetchGroups());
            

            handleClose();
        } catch (error) {
            setLoading(false);  // End loading

            // Display error notification using Snackbar
            onNotify('Error updating permissions', 'error');
            console.error('Error updating group permissions', error);
        }
    };

    // Handle closing of notifications
    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification({ ...notification, open: false });
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Update Group Permissions
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box display="flex" height="100%">
                {/* Left Sidebar (List of Apps) */}
                <Box sx={{ width: '20%', borderRight: '1px solid #ddd' }}>
                    <Typography variant="h6" sx={{ p: 2 }}>
                        Apps
                    </Typography>
                    <List>
                        {Object.keys(adminPermissionsByApp).map(appLabel => (
                            <ListItem
                                key={appLabel}
                                button
                                selected={appLabel === selectedApp}
                                onClick={() => handleAppSelect(appLabel)}
                                disabled={!selectedGroup} 
                            >
                                <ListItemText primary={appLabel} />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Right Panel (Permissions for Selected App) */}
                <Box sx={{ width: '80%', p: 3, overflowY: 'auto' }}>
                    <Formik
                        initialValues={{
                            permissions: [],  // Holds the selected group's permissions
                        }}
                        onSubmit={(values) => handleSave(values)}  // Save form values
                        enableReinitialize={true}  // To reinitialize form values when a new group is selected
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div>
                                    <label>Select Group:</label>
                                    <select onChange={(e) => handleGroupChange(e.target.value, setFieldValue)} value={selectedGroup || ''}>
                                        <option value="">Select a group</option>
                                        {groups.map(group => (
                                            <option key={group.id} value={group.id}>
                                                {group.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    {selectedApp && (
                                        <>
                                            <Typography variant="h6">{selectedApp} Permissions</Typography>
                                            <FieldArray name="permissions">
                                                {() => (
                                                    <FormGroup>
                                                        {adminPermissionsByApp[selectedApp]?.map(permission => (
                                                            <FormControlLabel
                                                                key={permission.id}
                                                                control={
                                                                    <Checkbox
                                                                        checked={values.permissions.includes(permission.id)}
                                                                        onChange={() => handlePermissionChange(permission.id, values, setFieldValue)}
                                                                        sx={{
                                                                            backgroundColor: modifiedPermissions.has(permission.id)
                                                                                ? 'rgba(255, 165, 0, 0.2)'  // Highlight modified permissions
                                                                                : 'transparent',
                                                                        }}
                                                                        disabled={!selectedGroup} 
                                                                    />
                                                                }
                                                                label={`${permission.name} (${permission.codename})`}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                )}
                                            </FieldArray>
                                        </>
                                    )}
                                </div>

                                {/* Fixed button container at the bottom right */}
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        bottom: 20,
                                        right: 20,
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading || !selectedGroup}  // Disable when loading or no group selected
                                        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}  // Display spinner
                                    >
                                        {loading ? 'Saving...' : 'Save Changes'}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>


            </Box>
        </Dialog>
    );
};

export default SettingsModal;
