import React, { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import { useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { useDispatch } from 'react-redux';
import { setUploadedFiles } from '../actions/formActions';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

const UppyUploader = ({uppy}) => {
  const dispatch = useDispatch();




  useEffect(() => {
    if (uppy) {
      uppy.on('complete', (result) => {
        const successfulUploads = result.successful.map((file) => file.response.body);
        dispatch(setUploadedFiles(successfulUploads));
      });
    }
  }, [uppy, dispatch]);

  return uppy ? <Dashboard uppy={uppy} hideUploadButton={true} /> : null;
};

export default UppyUploader;
