// src/components/FormItems/DateInputItem.js
import React from 'react';
import { TextField } from '@mui/material';

function DateInputItem({ item, handleChange }) {
  return (
    <TextField
      label={item.label}
      type="date"
      value={item.value || ''}
      onChange={(e) => handleChange && handleChange(item.id, e.target.value)}
      fullWidth
      required={item.required}
      InputLabelProps={{
        shrink: true, // Ensures the label stays above the input when a date is selected
      }}
      sx={{ mb: 2 }}
    />
  );
}

export default DateInputItem;
