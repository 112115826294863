import React from 'react';
import { Container, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Button } from 'react-bootstrap';

import Grid from '@mui/material/Unstable_Grid2';
import HomePage from './HomePage';


const PermissionDenied = () => {
    return (
        <Container >
            <Grid container spacing={6} >
                <Grid xs={12} display="flex" justifyContent="center" alignItems="center" >
                    
                </Grid>
                <Grid xs={12} display="flex" justifyContent="center" alignItems="center" >
                    <Typography variant="h3" component="h3">
                        Permission Denied
                    </Typography>
                </Grid>
                <Grid xs display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h5" component="h5">
                        You do not have permission to view this page.
                        Please contact your administrator.
                    </Typography>
                </Grid>
                <Grid xs={12} display="flex" justifyContent="center" alignItems="center" >
                    <Button href="/" variant="primary" size="lg" >
                        Go Home
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PermissionDenied