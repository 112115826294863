import React from 'react';
import { Card, CardContent, CardActionArea, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const GridItemCard = ({ link, title, description }) => {
    return (
        <Link to={link} style={{textDecoration: 'none'}}>
            <Card sx={{ width: 280 }} elevation={3} >
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>

    );
}

export default GridItemCard;
