// src/components/FormItemEditor/customizers/TextAreaCustomizer.js
import React, { useState } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControlLabel, Checkbox, Slider, Typography } from '@mui/material';

const TextAreaCustomizer = ({ item, updateItem, close }) => {
  const [label, setLabel] = useState(item.label);
  const [required, setRequired] = useState(item.required);
  const [rows, setRows] = useState(item.rows || 4);
  const [fontSize, setFontSize] = useState(item.font_size);

  const handleSave = () => {
    updateItem({ ...item, label, required, rows, font_size: fontSize });
    close();
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Text Area</DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <FormControlLabel
          control={<Checkbox checked={required} onChange={(e) => setRequired(e.target.checked)} />}
          label="Required"
          sx={{ mt: 2 }}
        />
        <Typography gutterBottom sx={{ mt: 2 }}>
          Number of Rows
        </Typography>
        <Slider
          value={rows}
          min={1}
          max={10}
          step={1}
          onChange={(e, newValue) => setRows(newValue)}
          valueLabelDisplay="auto"
        />
        <Typography gutterBottom sx={{ mt: 2 }}>
          Font Size
        </Typography>
        <Slider
          value={fontSize}
          min={10}
          max={30}
          step={1}
          onChange={(e, newValue) => setFontSize(newValue)}
          valueLabelDisplay="auto"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextAreaCustomizer;
