import '../../App.css';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, connect } from 'react-redux';
import { fetchCompanies, deleteCompany, fetchProjects, fetchClients, newCompanyFromWebSocket, deleteNearMissFromWebSocket, updateNearMissFromWebSocket, fetchUsers, fetchGroups  } from '../../apislice.js';


import formatDateUK from '../UKDate.js';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import {setAuthToken} from '../../api/api.js';
import { render } from 'react-dom';
import api from '../../api/api.js';

import { connectWebSocket, sendMessage } from '../../websocketService.js';


import { useUserGroup } from './userGroup.js';
import UsersManage from './UsersManage.js';
import SettingsModal from './userSettings.js';
import PermissionDenied from '../permissionDenied.js';


//MUI
import { DataGrid } from '@mui/x-data-grid';
import { Button, Drawer, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddCircleOutline, Delete, Edit, GetApp, Refresh } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2.js';
import { Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import Chip from '@mui/material/Chip';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Alert } from '@mui/material';






const UsersHome = () => {
    const dispatch = useDispatch();
    const isApproved = useUserGroup(['Admin', 'Manager', 'Director']);
    // const [nearMissData, setnearMissData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCompanyAddForm, setCompanyAddForm] = useState(false);
    const [showUserEditForm, setUserEditForm] = useState(false);
    const [selectedUserId, setuserId] = useState(null);
    const [ showEditForm, setShowEditForm ] = useState(false);
    const state = useSelector((state) => state.api);
    const accessToken = useSelector((state) => state.auth.email.access);
    const companies = useSelector((state) => state.api.companies.data);
    const status = useSelector((state) => state.api.companies.status);
    const projects = useSelector((state) => state.api.projects.data);
    const projectStatus = [...new Set(projects.map(project => project.status))];;
    const [selectedStatus, setSelectedStatus] = useState('In Progress');
    const currentUserId = useSelector(state => state.auth.email.id);
    const error = useSelector((state) => state.api.error);
    const [visibleEntries, setVisibleEntries] = useState(10);
    const [filters, setFilters] = useState({ reference: '', name: '', status: '', category: '' });
    const [searchText, setSearchText] = useState('');
    const [showUsersManage, setShowUsersManage] = useState(false);
    const [showManageModal, setShowManageModal] = useState(false);
    const groups = useSelector((state) => state.api.groups.data);
    const [settingsModal, setSettingsModal] = useState(false); 
    const [notification, setNotification] = useState('');

    const users = useSelector((state) => state.api.users.data);

    const [sortModel, setSortModel] = useState([
      {
        field: 'id',
        sort: 'desc',
      },
    ]);
    const [selectedCategory, setSelectedCategory] = useState('');

    
    const categoryChoices = [
      { value: 'Admin', label: 'Admin' },
      { value: 'Manager', label: 'Manager' },
      { value: 'Director', label: 'Director' },
      { value: 'Operative', label: 'Operative' },

    ];

    const statusChoices = [

    ];

    const companyMap = companies.reduce((map, company) => {
      map[company.id] = company.name.toLowerCase();
      return map;
    }, {});

    const filteredUsers = users.filter((user) => {

      const searchLowered = searchText.toLowerCase();
    
      const matchesSearchText = 
        (user.first_name && user.first_name?.toLowerCase().includes(searchLowered)) ||
        (user.last_name && user.last_name?.toLowerCase().includes(searchLowered)) ||
        (user.groups && user.groups.some(group => group.toString().toLowerCase().includes(searchLowered))) ||
        user.job_title?.toLowerCase().includes(searchLowered) ||
        user.email?.toLowerCase().includes(searchLowered) ||
        (user.company && companyMap[user.company] && companyMap[user.company].includes(searchLowered));

    
      // Check if the company's category matches the selected category
      const matchesCategory = selectedCategory ? user.groups.includes(selectedCategory) : true;

    
      return matchesSearchText && matchesCategory;
    });

    // const filteredUsers = users;





    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(fetchUsers());
          dispatch(fetchProjects());
          dispatch(fetchCompanies());
          dispatch(fetchClients());
          dispatch(fetchGroups());


          
        } catch (error) {
          // Handle errors, possibly update status to 'failed'
        }
      };
    
      if (status === 'idle') {
        fetchData();
      }
    }, [status, dispatch]);


    const handleNotify = (message, severity) => {
      setNotification({ open: true, message, severity });
    };

    const handleCloseNotification = () => {
      setNotification({ open: false, message: '', severity: 'success' });
    };




    const handleRefreshClick = () => {
      dispatch(fetchUsers());
    };

    useEffect(() => {
      const handleWebSocketMessage = (data) => {
        console.log('Websocket message received: ', data);

        if (data.message && data.message.new_company) {
          // Update the state with the new diary entry
          if(currentUserId && data.message.new_company.user !== currentUserId){
            console.log('Dispatching updateCompaniesMissFromWebSocket: ', data.message.new_company)

            dispatch(newCompanyFromWebSocket(data.message.new_company));
          }
        }
        else if (data.message && data.message.near_miss_deleted) {
          if(currentUserId && data.message.user !== currentUserId){
            console.log('Dispatching deleteCompany: ', data.message.near_miss_deleted)
            dispatch(deleteNearMissFromWebSocket(data.message.near_miss_deleted));
          }
          }
        else if (data.message && data.message.updated_near_miss) {
          if(currentUserId && data.message.updated_near_miss.user !== currentUserId){
            console.log('Dispatching deleteCompany: ', data.message.updated_near_miss)
            dispatch(updateNearMissFromWebSocket(data.message.updated_near_miss));
          }
          }}

      connectWebSocket('companies',handleWebSocketMessage);
      
    }, [dispatch, currentUserId]);

    const handleCategoryClick = (categoryValue) => {
      // Toggle selection: if the same category is clicked, clear the selection, otherwise update it
      setSelectedCategory(prevCategory => prevCategory === categoryValue ? '' : categoryValue);
    };


    const handleUserEditForm = (userId) => {
        setuserId(userId);
        setUserEditForm(true);
        console.log('Edit User Form', userId);
    };

    const handleAddCompanyForm = () => {
        setCompanyAddForm(true);
        console.log('Edit User Form');
        
    };

    const handleSettingsModal = () => {
        setSettingsModal(true);
    };


    const handleUsersManage = () => {
      setShowManageModal(!showManageModal);
    };
      

    const handleUserDelete = (userId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Near Miss?');

        if (confirmDelete) {
          console.log('Delete Near Miss', userId);
          dispatch(deleteCompany(userId));
        }else{
          console.log('Delete Cancelled');
        }
        
    };




    const filteredProjects = projects.filter((project) => project.status === selectedStatus);

    if (!isApproved) {
      // Render an alternative view or message
      // return <div>You do not have permission to access this page.</div>;
      return <PermissionDenied />
    }

    const handleSearch = (event) => {
      setSearchText(event.target.value);
    };
    


    const exportToCsv = (filename, rows) => {
      // Create CSV content
      const csvContent = rows.map(row => Object.values(row).join(",")).join("\n");
  
      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
      // Create an anchor tag to trigger the download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  const prepareDataForExport = () => {
    // Assuming 'companies' is the state or prop holding the data shown in the DataGrid
    return companies.map(company => ({
        ID: company.id,
        Name: company.name,
        Reference: company.reference,
        Status: company.status,
        Category: company.category,
        Type: company.trades_names.join(', '), // Assuming trades_names is an array of strings
        'Date Created': formatDateUK(company.created_at),
        'Date Modified': formatDateUK(company.updated_at),

    }));
  };



    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'first_name',
            headerName: 'First Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'company', 
            headerName: 'Company',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
              const company = companies.find(company => company.id === params.row.company);
              return company ? company.name : '';
            }
        },
        {
            field: 'job_title',
            headerName: 'Job Title',
            flex: 1,
            editable: false,
        }, 
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            editable: false,
        },
        {
            field: 'groups',
            headerName: 'Groups',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
              const userGroups = groups.filter(group => params.row.groups.includes(group.id));
              return userGroups.map(group => group.name).join(', ');
            }
          
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <Button
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleUserEditForm(params.row.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleUserDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </strong>
            ),
        }



    ];





    const renderUserList = () => {


        if (status === 'loading') {
          return <div>Loading...</div>;
        }
    
        if (status === 'failed') {
          return <div>
            Error: An error occurred while fetching data.
            <button onClick={handleRefreshClick}>Try again</button>
            {error && <div>Error Details{error}</div>}
            
            </div>;
          
        }

    
        return (

            
            <Container maxWidth style={{padding: '10px 0px 0px 0px'}}>
                <Container maxWidth style={{padding: '0px 0px 0px 0px'}}>

                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4" gutterBottom>
                            Users
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        sx={{ fontSize: '12px', padding: '5px 0px 8px 16px' }}
                    >

                        <Grid item sx={{ order: { xs: 1, sm: 1 } }}>


                        </Grid>
                        <Grid item sx={{ order: { xs: 1, sm: 2 } }}> 
                            <Box display="flex" gap={1}> {/* Use Box to align buttons next to each other with some gap */}
                              <Button 
                                // variant="outlined" 
                                color="primary" 
                                onClick={handleUsersManage}
                                startIcon={<AddCircleOutline />}
                                >
                                  Add</Button>
                                <Button onClick={handleRefreshClick} startIcon={<Refresh />}>
                                </Button>
                                <Button
                                    // variant="contained"
                                    color="primary"
                                    onClick={() => exportToCsv('users.csv', prepareDataForExport())}
                                    startIcon={<GetApp />}
                                >
                                </Button>
                                <Button
                                    // variant="contained"
                                    color="primary"
                                    onClick={handleSettingsModal}
                                    startIcon={<SettingsIcon />}
                                >
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '0px' }}>
                      <TextField
                        id="search"
                        size='small'
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Search Users..."
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>

                      {categoryChoices.map((category) => (
                        <Chip
                          key={category.value}
                          label={category.label}
                          onClick={() => handleCategoryClick(category.value)}
                          style={{ marginRight: '10px', marginBottom: '10px', marginTop: '10px'}}
                          variant={selectedCategory === category.value ? "filled" : "outlined"}
                          color={selectedCategory === category.value ? "primary" : "default"}
                        />
                      ))}
                    </Grid>
                  

                    

                    <Box sx={{ height: `calc(100vh - 300px)` }}>
                      <DataGrid
                          rows={filteredUsers}
                          columns={columns}
                          pageSize={visibleEntries}
                          sortModel={sortModel}
                          onSortModelChange={(model) => setSortModel(model)}
                          autoHeight={false}
                          stickyHeader
                          // checkboxSelection
                          disableColumnResize={false}
                          disableSelectionOnClick
                          onSelectionModelChange={(newSelection) => {
                              console.log(newSelection);
                          }}

                      />


                    </Box>

                </Container>

            </Container>


        )

          





    };
    



      return (
        <Container fluid style={{padding: "0px 0px 0px 0px"}} maxWidth={false}>
          {renderUserList()}



          {showUserEditForm && (


                    <UsersManage open={showUserEditForm} handleClose={() => setUserEditForm(false)} userId={selectedUserId} onNotify={handleNotify}/>
                    

          )}

          {showManageModal && (
            <UsersManage open={showManageModal} handleClose={() => setShowManageModal(false)} onNotify={handleNotify}/>
          )}

          {settingsModal && <SettingsModal open={settingsModal} handleClose={() => setSettingsModal(false)} onNotify={handleNotify} />}

          {/* Snackbar for notifications */}
          <Snackbar
              open={notification.open}
              autoHideDuration={3000}
              onClose={handleCloseNotification}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
              <Alert onClose={handleCloseNotification} severity={notification.severity}>
                  {notification.message}
              </Alert>
          </Snackbar>

        </Container>
        
        );
    };

export default UsersHome;