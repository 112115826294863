// src/components/ApprovalsGrid.js

import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApprovals } from '../../apislice';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Drawer, Grid } from '@mui/material';
import api from '../../api/api';


const ApprovalsGrid = () => {
    const dispatch = useDispatch();
    // const [approvals, setApprovals] = useState([]);
    const approvals = useSelector((state) => state.api.approvals.data);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [setSelectedApproval] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [detailedInfo, setDetailedInfo] = useState(null);

    useEffect(() => {
        dispatch(fetchApprovals());
    }, [dispatch]);


    // const changeStatus = (id, newStatus) => {
    //     dispatch(changeApprovalStatus({ id, newStatus }));
    // };

    const modelToEndpoint = {
        'sitediaries': '/sitediaries/',
        'permittodigs': '/forms/permittodigs/',
        'nearmisss': '/forms/nearmisss/',
        'technicalqueries': '/technicalqueries/',
    };

    const fetchDetails = async (selectedApproval) => {
        setLoadingDetails(true);
        console.log(selectedApproval.app_label)
        const endpoint = modelToEndpoint[selectedApproval.app_label];
        console.log(`Fetching details from: ${endpoint}${selectedApproval.object_id}`)
        if (endpoint) {
            try {
                const response = await api.get(`${endpoint}${selectedApproval.object_id}`); // Assuming `item_id` is the ID of the specific item
                setDetailedInfo(response.data);
            } catch (error) {
                console.error("Error fetching details:", error);
                // Handle error
            }
        }
        setLoadingDetails(false);
    };

    

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },

        {
            field: 'model_name',
            headerName: 'App',
            width: 180,
            editable: false,
        },
        {
            field: 'number',
            headerName: 'Number',
            width: 80,
            editable: false,
        },
        {
            field: 'created_date',
            headerName: 'Date',
            width: 100,
            editable: false,
        },
        {
            field: 'project_name',
            headerName: 'Project',
            width: 120,
            editable: false,
        },
        {
            field: 'user',
            headerName: 'User',
            width: 130,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 110,
            editable: false,
        },

        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 120,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: 16 }}
                            onClick={() => {
                                setIsDrawerOpen(true);
                                setSelectedApproval(params.row);
                                fetchDetails(params.row);
                            }}
                        >
                            View
                        </Button>

                    </>
                );
            },

        },
        {
            field: '',
            headerName: 'Approver',
            width: 110,
            editable: false,
        },
    ];

    return (
        <Grid container>
            <Grid xs={12} style={{padding: '5px 0px 5px 0px'}}>
                <h1>Approvals</h1>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    rows={approvals}
                    columns={columns}
                    // pageSize={5}
                    // checkboxSelection
                    // disableSelectionOnClick
                    sortModel={[
                        {
                            field: 'id',
                            sort: 'desc',
                        },
                    ]}
                    getRowClassName={(params) => 
                        params.row.status === 'Approved' ? 'approvedRow' : ''}
                        

                    autoWidth={true}

                    
                
                />
            </Grid>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                >
                <div style={{ width: 250, padding: 20 }}>
                    {loadingDetails ? (
                        <p>Loading...</p>
                    ) : detailedInfo ? (
                        <>
                            <h2>Approval Details</h2>
                            {/* Display detailed information based on `detailedInfo` structure */}
                            <p>{detailedInfo.someField}</p>
                            {/* Add more fields as needed */}
                        </>
                    ) : (
                        <p>No details available.</p>
                    )}
                </div>
            </Drawer>
        </Grid>
    );
};

export default ApprovalsGrid;
