import React from 'react';
import { Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { UseSelector } from 'react-redux';

// const ProtectedRoute = ({ element, isAuthenticated, ...rest }) => {
//     return (
//         <Route
//             {...rest}
//             element={
//                 isAuthenticated ? element : <Navigate to="/login" />
//             }
//         />
//     );
// };

// const ProtectedRoute = ({ children}) => {
//     const user = useSelector((state) => state.auth.user);
//     let location = useLocation();
//     console.log(user);

//     if (!user.state.isAuthenticated) {
//         return <Navigate to="/login" state={{ from: location }} replace/>;
//     }

//     return children;

// };

const ProtectedRoute = ({ isAuthenticated }) => {
    return ( isAuthenticated ? <Outlet /> : <Navigate to="/login" /> );
}
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(ProtectedRoute);
 
// export default ProtectedRoute;