import { useCallback, useState } from 'react';
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
    Background,
    Controls,
    MiniMap,

} from '@xyflow/react';
import '@xyflow/react/dist/style.css';


import TextUpdaterNode from './Nodes/TextUpdaterNode';
import ConditionalNode from './Nodes/ConditionalNode';



const initialNodes = [
    {
      id: 'node-1',
      type: 'textUpdater',
      position: { x: 0, y: 0 },
      data: { value: 123 },
    },
    {
      id: 'node-2',
      type: 'output',
      targetPosition: 'top',
      position: { x: 0, y: 200 },
      data: { label: 'node 2' },
    },
    {
      id: 'node-3',
      type: 'output',
      targetPosition: 'top',
      position: { x: 200, y: 200 },
      data: { label: 'node 3' },
    },
    {
      id: 'conditional-1',
      type: 'conditionalNode',
      position: { x: 100, y: 100 },
      data: { checkField: 'node-1', condition: 'equals 123' },
    },
  ];
  
const initialEdges = [];

const nodeTypes = { 
    textUpdater: TextUpdaterNode,
    conditionalNode: ConditionalNode,

};

function Flow() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState([initialEdges]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );

  return (
    <div style={{ width: '98vw', height: '90vh' }}>
        <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView

        >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
     </div>

  );
}

export default Flow;