import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Container, Box } from '@mui/material';

import api from '../../api/api';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);


    const handleChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(true); // Allow the user to edit the email
        setMessage(''); // Clear any previous messages
    };

    const handleBlur = (e) => {
        checkUsername(email);
    }

    // Fetch CSRF token on component mount
    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                await api.get('/csrf-cookie/', {
                    credentials: 'include',  // Ensures cookies are included in the request/response
                });
            } catch (err) {
                console.error('Error fetching CSRF token:', err);
            }
        };

        fetchCsrfToken();
    }, []);

    // Helper function to get the CSRF token from cookies
    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };


    const checkUsername = (email) => {
        api.get(`/check-username/?email=${email}`)
            .then((response) => {
                if (!response.data.exists) { 
                    setMessage('Email address does not exist');
                    setIsEmailValid(false);
                } else {
                    setMessage('');
                    setIsEmailValid(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setMessage('An error occurred, please try again.');
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isEmailValid) {
            console.log('Email is not valid.');
            return;
        }

        try {
            const response = await api.post('/auth/password/reset/', {
                email
            });
            setMessage('Reset password email has been sent.');
        } catch (error) {
            setMessage('Error sending reset email. Please try again.');
            console.error(error);
        }
    }

    return (
        <Container component='main' maxWidth='xs'>
            <Box 
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component='h1' variant='h5'>
                    Forgot Password
                </Typography>

                <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant='outlined'
                        type='email'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        onBlur={handleBlur}
                        autoComplete='email'
                        autoFocus
                        value={email}
                        
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!isEmailValid}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send Reset Email
                    </Button>
                    {message && <Typography color='textSecondary' >{message}</Typography>}
                </Box>
                


            
            </Box>    
            
        </Container>
    );
};

export default ForgotPassword;