// src/components/FormItemCustomizer/DateInputCustomizer.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

function DateInputCustomizer({ item, updateItem, close }) {
  const [label, setLabel] = useState(item.label || '');
  const [required, setRequired] = useState(item.required || false);

  const handleSave = () => {
    updateItem({ ...item, label, required });
    close();
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Date Input</DialogTitle>
      <DialogContent>
        {/* Label Customization */}
        <TextField
          label="Label Text"
          variant="outlined"
          fullWidth
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          sx={{ mt: 2 }}
        />

        {/* Required Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={required}
              onChange={(e) => setRequired(e.target.checked)}
            />
          }
          label="Required"
          sx={{ mt: 2 }}
        />

        {/* Additional customizations can go here if needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateInputCustomizer;
