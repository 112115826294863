import React, { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { Button, Box } from '@mui/material';
import { GetApp } from '@mui/icons-material';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
    },
    subtitle: {
        fontSize: 14,
        marginBottom: 5,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '20%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
});

// Create Document Component
const OrderPdfDocument = ({ order, project, deliveryDate, orderItems = [] }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.title}>Order Details</Text>
                <Text style={styles.subtitle}>Project: {project}</Text>
                <Text style={styles.subtitle}>Delivery Date: {deliveryDate}</Text>
                <Text style={styles.subtitle}>Status: {order.status}</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.subtitle}>Items:</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Item Ref</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Name</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Quantity Ordered</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Stock Level</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Packed</Text></View>
                    </View>
                    {orderItems.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.ref}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.name}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.quantityOrdered}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.stockLevel}</Text></View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {order.status === 'Pending' || order.status === 'Accepted' ? '' : item.quantityPacked}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

const DownloadPdf = ({ order, project, deliveryDate, orderItems }) => {
    const [url, setUrl] = useState('');

    const generatePdf = async () => {
        const doc = <OrderPdfDocument order={order} project={project} deliveryDate={deliveryDate} orderItems={orderItems} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `order_${order.id}.pdf`;
        link.click();
    };

    return (
        <Button
            // variant="contained"
            color="primary"
            startIcon={<GetApp />}
            onClick={generatePdf}
        >

        </Button>
    );
};

export default DownloadPdf;
