import '../../App.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStockItems, 
    updateStockItem, 
    deleteStockItem, 
    fetchStockOrders, 
    acceptStockOrder, 
    fetchProjects, 
    fetchUsers, 
    newStockOrderFromWebSocket, 
    updateStockOrderFromWebSocket, 
    deleteStockOrderFromWebSocket, 
    updateStockLevelFromWebSocket,
    newStockItemFromWebSocket, 
    deleteStockItemFromWebSocket } from '../../apislice.js';
import PermissionDenied from '../permissionDenied.js';
import { useUserGroup } from '../users/userGroup.js';
import ItemEditForm from './ItemEditForm'; // Import the edit form component
import OrderForm from './OrderForm'; // Import the order form component
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Container, Typography, TextField, Box, Tabs, Tab, Grid, List, ListItem, ListItemText } from '@mui/material';
import { AddCircleOutline, Delete, Edit, Refresh, GetApp } from '@mui/icons-material';
import DownloadPdf from './DownloadPDF.js';
import { exportOrdersToExcel } from './exportToExcel';

import { connectWebSocket, sendMessage } from '../../websocketService.js';

const StockItemsHome = () => {
    const dispatch = useDispatch();
    const isApproved = useUserGroup(['Admin', 'Manager', 'Director']);
    const isOperative = useUserGroup(['Operative']); // Identify operative users
    const [loading, setLoading] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState(0); // 0 for dashboard, 1 for items, 2 for orders
    const [modalOpen, setModalOpen] = useState(false); // State for the modal
    const [selectedOrder, setSelectedOrder] = useState(null); // State for selected order
    const [selectedProject, setSelectedProject] = useState(null); // State for selected project
    const items = useSelector((state) => state.api.stock_items.data);
    const orders = useSelector((state) => state.api.stock_orders.data);
    const status = useSelector((state) => state.api.stock_items.status);
    const orderStatus = useSelector((state) => state.api.stock_orders.status); // Add order status
    const error = useSelector((state) => state.api.error);
    const [visibleEntries, setVisibleEntries] = useState(10);
    const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);
    const users = useSelector((state) => state.api.users.data);
    const projects = useSelector((state) => state.api.projects.data);
    const currentUserId = useSelector((state) => state.auth.email.id);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchProjects());
                dispatch(fetchStockItems());
                dispatch(fetchStockOrders());
                dispatch(fetchUsers());
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchStockItems());
        }
        if (orderStatus === 'idle') {
            dispatch(fetchStockOrders());
        }
    }, [status, orderStatus, dispatch]);

    const handleRefreshItemsClick = () => {
        dispatch(fetchStockItems())
            .then(() => {
                alert('Items Updated!');
            })
            .catch(() => {
                alert('An error occurred');
            });
    };

    const handleRefreshOrdersClick = () => {
        dispatch(fetchStockOrders())
            .then(() => {
                alert('Orders Updated!');
            })
            .catch(() => {
                alert('An error occurred');
            });
    };

    useEffect(() => {
        const handleWebSocketMessage = (data) => {
            console.log('WebSocket message received: ', data);

            if (data.action === 'stock_level_updated') {
                console.log('Stock level updated: ', data.stock);
                dispatch(updateStockLevelFromWebSocket(data.stock));
            } else if (currentUserId && data.order.user !== currentUserId) {
                if (data.action === 'created') {
                    console.log('New order created: ', data.order);
                    dispatch(newStockOrderFromWebSocket(data.order));
                } else if (data.action === 'updated') {
                    console.log('Order updated: ', data.order);
                    dispatch(updateStockOrderFromWebSocket(data.order));
                } else if (data.action === 'deleted') {
                    console.log('Order deleted: ', data.order.id);
                    dispatch(deleteStockOrderFromWebSocket(data.order.id));
                }
            } else {
                console.log('Ignoring WebSocket message from the current user: ', data);
            }
        };

        connectWebSocket('stockcontrol', handleWebSocketMessage);

        // return () => {
        //     disconnectWebSocket('stockcontrol');
        // };
    }, [dispatch, currentUserId]);

    // useEffect(() => {
    //     const handleWebSocketMessage = (data) => {
    //         console.log('WebSocket message received: ', data);
    
    //         if (data.action === 'stock_level_updated') {
    //             console.log('Stock level updated: ', data.stock);
    //             dispatch(updateStockLevelFromWebSocket(data.stock));
    //         } else if (data.action === 'stock_item_created') {
    //             console.log('New stock item created: ', data.stockItem);
    //             dispatch(newStockItemFromWebSocket(data.stockItem));
    //         } else if (data.action === 'stock_item_updated') {
    //             console.log('Stock item updated: ', data.stockItem);
    //             dispatch(updateStockLevelFromWebSocket(data.stockItem));
    //         } else if (data.action === 'stock_item_deleted') {
    //             console.log('Stock item deleted: ', data.stockItemId);
    //             dispatch(deleteStockItemFromWebSocket(data.stockItemId));
    //         } else if (currentUserId && data.order && data.order.user !== currentUserId) {
    //             if (data.action === 'created') {
    //                 console.log('New order created: ', data.order);
    //                 dispatch(newStockOrderFromWebSocket(data.order));
    //             } else if (data.action === 'updated') {
    //                 console.log('Order updated: ', data.order);
    //                 dispatch(updateStockOrderFromWebSocket(data.order));
    //             } else if (data.action === 'deleted') {
    //                 console.log('Order deleted: ', data.order.id);
    //                 dispatch(deleteStockOrderFromWebSocket(data.order.id));
    //             }
    //         } else {
    //             console.log('Ignoring WebSocket message from the current user: ', data);
    //         }
    //     };
    
    //     connectWebSocket('stockcontrol', handleWebSocketMessage);
    // }, [dispatch, currentUserId]);

    const handleItemEditForm = (itemId) => {
        setSelectedItemId(itemId);
        setShowEditForm(true);
    };

    const handleItemDelete = (itemId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this item?');
        if (confirmDelete) {
            dispatch(deleteStockItem(itemId));
        }
    };

    const handleOrderAccept = (orderId) => {
        dispatch(acceptStockOrder(orderId))
            .then(() => {
                alert('Order Accepted!');
                dispatch(fetchStockOrders()); // Refresh orders after accepting
            })
            .catch(() => {
                alert('An error occurred while accepting the order');
            });
    };

    const handleViewOrder = (order) => {
        setSelectedOrder(order);
        setModalOpen(true);
    };

    const handleProjectClick = (projectId) => {
        setSelectedProject(projectId);
        setActiveTab(2); // Switch to orders tab
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredItems = items.filter((item) => {
        const searchLowered = searchText.toLowerCase();
        return item.name.toLowerCase().includes(searchLowered) || 
               item.description.toLowerCase().includes(searchLowered);
    });

    const itemColumns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'ref', headerName: 'Ref', flex: 1, editable: false},
        { field: 'name', headerName: 'Name', flex: 1, editable: false },
        { field: 'description', headerName: 'Description', flex: 1, editable: false },
        { field: 'price', headerName: 'Price', flex: 1, editable: false },
        { field: 'stock_level', headerName: 'Stock Level', flex: 1, editable: false },
        { field: 'unapproved_quantity', headerName: 'Unapproved Orders', flex: 1, editable: false },
        { 
            field: 'balance',
            headerName: 'Balance',
            flex: 1,
            editable: false,
            valueGetter: (params) => params.row.stock_level - params.row.unapproved_quantity,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <Button
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleItemEditForm(params.row.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleItemDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </strong>
            ),
        },
    ];

    console.log('Users:', users);


    const orderColumns = [
        { field: 'id', headerName: 'Order ID', width: 100 },
        {
            field: 'user',
            headerName: 'User',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const user = users.find(u => u.id === params.row.user);
                return user ? `${user.first_name} ${user.last_name}` : 'Unknown User';
            }
        },
        {
            field: 'project_ref',
            headerName: 'Project Ref',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const project = projects.find(proj => proj.id === params.row.project);
                return project ? project.project_refernce : 'Unknown Project';
            }
        },
        { 
            field: 'project', 
            headerName: 'Project', 
            flex: 1, 
            editable: false,
            valueGetter: (params) => {
                const project = projects.find(proj => proj.id === params.row.project);
                return project ? project.project_name : 'Unknown Project';
            }
        },
        {
            field: 'date_ordered',
            headerName: 'Date Created',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                return format(new Date(params.row.date_ordered), 'yyyy-MM-dd');
            }
        },
        {
            field: 'delivery_date',
            headerName: 'Collection Date',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                return format(new Date(params.row.delivery_date), 'yyyy-MM-dd');
            }
        },
        { field: 'status', headerName: 'Status', flex: 1, editable: false },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <Button
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleViewOrder(params.row)}
                    >
                        View
                    </Button>
                    {/* {params.row && <DownloadPdf order={params.row} projects={projects} />} */}
                </strong>
            ),
        },
    ];
    

    const renderItemList = () => {
        if (status === 'loading') {
            return <div>Loading...</div>;
        }

        if (status === 'failed') {
            return (
                <div>
                    Error: An error occurred while fetching data.
                    <button onClick={handleRefreshItemsClick}>Try again</button>
                    {error && <div>Error Details: {error}</div>}
                </div>
            );
        }

        return (
            <Container maxWidth style={{ padding: '10px 0px 0px 0px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4" gutterBottom>
                        Stock Items
                    </Typography>
                </Grid> 
                <Grid 
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    sx={{ fontSize: '12px', padding: '5px 0px 8px 0px' }}
                >
                    <Grid item xs={3}>
                        <TextField
                            id="search"
                            size="small"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search Items..."
                            fullWidth
                        />
                    </Grid>
                    <Grid item sx={{ order: { xs: 1, sm: 2 } }}>
                        <Box display="flex" gap={1}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setSelectedItemId(null);
                                    setShowEditForm(true);
                                }}
                                startIcon={<AddCircleOutline />}
                            >
                                Add
                            </Button>
                            <Button onClick={handleRefreshItemsClick} startIcon={<Refresh />}></Button>


                        </Box>
                    </Grid>

                </Grid>

                <Box sx={{ height: `calc(100vh - 250px)` }}>
                    <DataGrid
                        rows={filteredItems}
                        columns={itemColumns}
                        pageSize={visibleEntries}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        autoHeight={false}
                        stickyHeader
                        disableColumnResize={false}
                        disableSelectionOnClick
                    />
                </Box>
            </Container>
        );
    };


    const filteredOrders = orders.filter((order) => {
        const searchLowered = searchText.toLowerCase();
        const user = users.find(u => u.id === order.user);
        const project = projects.find(proj => proj.id === order.project);
        const userText = user ? `${user.first_name} ${user.last_name}`.toLowerCase() : '';
        const projectText = project ? `${project.project_refernce} ${project.project_name}`.toLowerCase() : '';
        const orderText = `${order.id} ${userText} ${projectText} ${format(new Date(order.date_ordered), 'yyyy-MM-dd')} ${format(new Date(order.delivery_date), 'yyyy-MM-dd')} ${order.status}`.toLowerCase();
        return orderText.includes(searchLowered);
    });

    const renderOrderList = () => {
        const filteredOrdersByProject = selectedProject 
            ? filteredOrders.filter(order => order.project === selectedProject) 
            : isOperative 
                ? filteredOrders.filter(order => order.user === currentUserId) 
                : filteredOrders;

        return (
            <Container maxWidth style={{ padding: '10px 0px 0px 0px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4" gutterBottom>
                        Orders
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    sx={{ fontSize: '12px', padding: '5px 0px 8px 0px' }}
                >
                    <Grid item xs={3}>
                        <TextField
                            id="search"
                            size="small"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search Items..."
                            fullWidth
                        />
                    </Grid>
                    <Grid item sx={{ order: { xs: 1, sm: 2 } }}>
                        <Box display="flex" gap={1}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setSelectedOrder(null);
                                    setModalOpen(true);
                                }}
                                startIcon={<AddCircleOutline />}
                            >
                                Add
                            </Button>
                            <Button onClick={handleRefreshOrdersClick} startIcon={<Refresh />}>
                            </Button>
                            <Button 
                                color="primary" 
                                onClick={() => exportOrdersToExcel(orders, users, projects)} 
                                startIcon={<GetApp />}
                            >
                                
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ height: `calc(100vh - 250px)` }}>
                    <DataGrid
                        rows={filteredOrdersByProject}
                        columns={orderColumns}
                        // pagination={false}
                        pageSize={visibleEntries}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        autoHeight={false}
                        stickyHeader
                        disableColumnResize={false}
                        disableSelectionOnClick
                        getRowClassName={(params) => 
                        params.row.status === 'Fulfilled' ? 'approvedRow' : ''}
                        
                    />
                </Box>
            </Container>
        );
    };

    const renderDashboard = () => {
        const lowStockItems = items.filter(item => item.stock_level < 5);
    
        return (
            <Container maxWidth="lg" style={{ padding: '10px 0px 0px 0px', height: '100vh' }}>
                <Typography variant="h4" component="h4" gutterBottom>
                    Dashboard
                </Typography>
                <Box sx={{ height: 'calc(100vh - 150px)' }}>
                    <Typography variant="h6" component="h6">
                        Overview of Stock Items and Orders
                    </Typography>
                    <Typography>Total Stock Items: {items.length}</Typography>
                    <Typography>Total Orders: {orders.length}</Typography>
                    <Typography variant="h6" component="h6" style={{ marginTop: '20px' }}>
                        Stock Items with Low Levels
                    </Typography>
                    <Box sx={{ height: 'calc(100vh - 600px)', overflow: 'auto', border: '1px dashed grey' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={5} >
                                <List>
                                    {lowStockItems.map((item) => (
                                        <ListItem key={item.id}>
                                            <ListItemText primary={`${item.name} - Stock Level: ${item.stock_level}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography variant="h6" component="h6" style={{ marginTop: '20px' }}>
                                    Pending Orders
                                </Typography>
                                <List>



                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        if (newValue !== 2) {
            setSelectedProject(null);
        }
    };

    if (!isApproved && !isOperative) {
        return <PermissionDenied />;
    }

    return (
        <Container fluid style={{ padding: '0px 0px 0px 0px' }} maxWidth={false}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="stock and orders tabs">
                <Tab label="Orders" />
                <Tab label="Stock Items" />
                {/* <Tab label="Dashboard" /> */}
            </Tabs>
            {activeTab === 2 && renderDashboard()}
            {activeTab === 1 && renderItemList()}
            {activeTab === 0 && renderOrderList()}
            {showEditForm && (
                <ItemEditForm open={showEditForm} handleClose={() => setShowEditForm(false)} itemId={selectedItemId} />
            )}
            <OrderForm open={modalOpen} handleClose={() => setModalOpen(false)} order={selectedOrder} />
        </Container>
    );
};

export default StockItemsHome;
