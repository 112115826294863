// src/components/FormItemEditor/customizers/CheckboxCustomizer.js
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControlLabel, Checkbox, TextField } from '@mui/material';

const CheckboxCustomizer = ({ item, updateItem, close }) => {
  const [label, setLabel] = useState(item.label);
  const [required, setRequired] = useState(item.required);

  const handleSave = () => {
    updateItem({ ...item, label, required });
    close();
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Checkbox</DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={<Checkbox checked={required} onChange={(e) => setRequired(e.target.checked)} />}
          label="Required"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckboxCustomizer;
