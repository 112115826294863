import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

function TableItemCustomizer({ item, updateItem, close }) {
  const [columns, setColumns] = useState(item.columns || []);

  // Handle adding a new column
  const handleAddColumn = () => {
    setColumns([...columns, { label: '', required: false }]);
  };

  // Handle removing a column
  const handleRemoveColumn = (index) => {
    const updatedColumns = [...columns];
    updatedColumns.splice(index, 1);
    setColumns(updatedColumns);
  };

  // Handle updating a column label or required state
  const handleUpdateColumn = (index, key, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [key]: value };
    setColumns(updatedColumns);
  };

  const handleSave = () => {
    updateItem({ ...item, columns });
    close();
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Table Columns</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Columns
          </Typography>
          <Grid container spacing={2}>
            {columns.map((column, index) => (
              <React.Fragment key={index}>
                <Grid item xs={5}>
                  <TextField
                    label="Column Label"
                    variant="outlined"
                    fullWidth
                    value={column.label}
                    onChange={(e) => handleUpdateColumn(index, 'label', e.target.value)}
                  />
                </Grid>
 
                <Grid item xs={1}>
                  <IconButton color="error" onClick={() => handleRemoveColumn(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={handleAddColumn}
          sx={{ mt: 2 }}
        >
          Add Column
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TableItemCustomizer;
