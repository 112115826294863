import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrades, addUsers, updateUser, fetchGroups } from '../../apislice';
import FullScreenModal from '../utils/FullScreenModal';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, FormGroup, Button, FormControlLabel, Switch } from '@mui/material';
import { TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, Tooltip } from '@mui/material';
import { Formik, useField, Form } from 'formik';
import * as Yup from 'yup';
import api from '../../api/api';


const UsersManage = ({ open, handleClose, onNotify, userId }) => {
    const dispatch = useDispatch();
    const userIdRef = useRef(userId);
    const [groups, setGroups] = useState([]); // Store available groups
    const [isLoadingGroups, setIsLoadingGroups] = useState(true); // Loading state for groups
    const [isEditMode, setIsEditMode] = useState(false);

    const trades = useSelector((state) => state.api.trades.data);
    const companies = useSelector((state) => state.api.companies.data);
    const users = useSelector((state) => state.api.users.data);
    const userData = users.find((user) => user.id === userIdRef.current);

    // Fetch available groups when the component mounts
    useEffect(() => {
        dispatch(fetchGroups()).then((response) => {
            setGroups(response.payload || []); // Ensure payload is not undefined
            setIsLoadingGroups(false); // Stop loading state when groups are fetched
        });
    }, [dispatch]);

    // Determine edit mode
    useEffect(() => {
        if (userId) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
        dispatch(fetchTrades());
    }, [dispatch, userId]);

    const FormikTextField = ({ name, label, ...otherProps }) => {
        const [field, meta] = useField(name);
        const errorText = meta.error && meta.touched ? meta.error : '';

        return (
            <TextField
                {...field}
                {...otherProps}
                label={label}
                helperText={errorText}
                error={!!errorText}
                size="small"
                sx={{ mb: 2 }}
                fullWidth
            />
        );
    };

    const FormikMuiSelect = ({ label, options, multiple, ...props }) => {
        const [field, meta] = useField(props);
        return (
            <FormControl fullWidth error={meta.touched && Boolean(meta.error)} size="small">
                <InputLabel>{label}</InputLabel>
                <Select {...field} {...props} label={label} multiple={multiple} sx={{ mb: 1.3 }}>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {meta.touched && meta.error ? (
                    <FormHelperText>{meta.error}</FormHelperText>
                ) : null}
            </FormControl>
        );
    };


    console.log('userId:', userId);    
    const handleResendWelcomeEmail = async () => {
        try {
            const response = await api.post(`/user/${userId}/resend_welcome_email/`);
            if (response.status === 200) {
                onNotify('Welcome email has been resent.', 'success'); // Trigger success notification
            }
        } catch (error) {
            onNotify('Failed to resend welcome email.', 'error'); // Trigger error notification
        }
    };

    console.log('userData:', userData);

    const initialValues = userData
        ? {
              email: userData.email,
              first_name: userData.first_name,
              last_name: userData.last_name,
              job_title: userData.job_title,
              company: userData.company || '', // Ensure company ID is available
              is_active: userData.is_active,
              is_staff: userData.is_staff,
              status: userData.status,
              groups: Array.isArray(userData.groups) ? userData.groups : [], // Ensure groups is an array of IDs
          }
        : {
              email: '',
              first_name: '',
              last_name: '',
              job_title: '',
              company: '',
              is_active: true,
              is_staff: false,
              groups: [], // Initialize groups as empty for new user
          };

    console.log('initialValues:', initialValues);



    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email format')
            .required('Required')
            .test('unique-email', 'Email already exists', (value) => {
                if (!value) return true;
                return !users.some((user) => user.email === value && user.id !== userIdRef.current);
            }),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        job_title: Yup.string().required('Required'),
        company: Yup.string().required('Required'),
        groups: Yup.array().min(1, 'At least one group is required').required('Required'),
    });

    return (
        <FullScreenModal open={open} handleClose={handleClose} title={isEditMode ? 'Edit User' : 'Add User'}>
            {isLoadingGroups ? (
                <div>Loading groups...</div>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        const payload = {
                            ...values,
                            groups: values.groups
                        };

                        if (isEditMode) {
                            dispatch(updateUser({ userId: userData.id, company: payload }))
                                .unwrap()
                                .then(() => {
                                    alert('User Updated');
                                    setSubmitting(false);
                                    resetForm();
                                    handleClose();
                                });
                        } else {
                            dispatch(addUsers(payload))
                                .unwrap()
                                .then(() => {
                                    alert('User Added');
                                    setSubmitting(false);
                                    resetForm();
                                    handleClose();
                                })
                                .catch((error) => {
                                    alert('An error occurred');
                                    setSubmitting(false);
                                });
                        }
                    }}
                >
                    {({ values, handleChange, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={6}>
                                    <FormGroup>
                                        <FormikTextField name="email" type="email" label="Email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikTextField name="first_name" type="text" label="First Name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikTextField name="last_name" type="text" label="Last Name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikMuiSelect
                                            name="job_title"
                                            label="Job Title"
                                            options={[
                                                { value: 'Site Manager', label: 'Site Manager' },
                                                { value: 'Project Manager', label: 'Project Manager' },
                                                { value: 'Quantity Surveyor', label: 'Quantity Surveyor' },
                                                { value: 'Foreman', label: 'Foreman' },
                                                { value: 'Engineer', label: 'Engineer' },
                                                { value: 'Director', label: 'Director' },
                                                { value: 'Buyer', label: 'Buyer' },
                                                { value: 'Estimator', label: 'Estimator' },
                                                { value: 'Accounts', label: 'Accounts' },
                                                { value: 'Assistant', label: 'Assistant' },
                                                { value: 'Other', label: 'Other' },
                                            ]}
                                        />
                                    </FormGroup>
                                </Grid2>
                                <Grid2 item xs={6}>
                                    <FormGroup>
                                        <FormikMuiSelect
                                            name="company"
                                            label="Company"
                                            options={companies.map((company) => ({
                                                value: company.id,
                                                label: company.name,
                                            }))}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikMuiSelect
                                            name="groups"
                                            label="Groups"
                                            multiple
                                            options={groups.map((group) => ({
                                                value: group.id,
                                                label: group.name,
                                            }))}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={values.is_active}
                                                    onChange={handleChange}
                                                    name="is_active"
                                                    color="primary"
                                                />
                                            }
                                            label="Active"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={values.is_staff}
                                                    onChange={handleChange}
                                                    name="is_staff"
                                                    color="primary"
                                                />
                                            }
                                            label="Staff"
                                        />
                                    </FormGroup>
                                        <FormGroup>
                                        {isEditMode && (
                                            <Tooltip title={values.status === 'active' ? "User is already active" : ""}>
                                            <span> {/* Wrapping the button in a span for disabled Tooltip */}
                                                <Button 
                                                variant="outlined" 
                                                color="primary" 
                                                onClick={handleResendWelcomeEmail} 
                                                sx={{ mr: 2 }}
                                                disabled={values.status === 'active'} // Disable if status is 'active'
                                                >
                                                Resend Welcome Email
                                                </Button>
                                            </span>
                                            </Tooltip>
                                        )}
                                        </FormGroup>
                                </Grid2>
                            </Grid2>
                            <Box sx={{ position: 'absolute', right: 0, bottom: 0, p: 2 }}>
                                <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
                                    Close
                                </Button>
                                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                    Submit
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </FullScreenModal>
    );
};

export default UsersManage;
