// src/components/FormItemEditor/customizers/DropdownCustomizer.js
import React, { useState } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DropdownCustomizer = ({ item, updateItem, close }) => {
  const [label, setLabel] = useState(item.label);
  const [required, setRequired] = useState(item.required);
  const [options, setOptions] = useState(item.options || []);
  const [newOption, setNewOption] = useState('');

  const handleSave = () => {
    updateItem({ ...item, label, required, options });
    close();
  };

  const addOption = () => {
    if (newOption.trim()) {
      setOptions([...options, newOption]);
      setNewOption('');
    }
  };

  const removeOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Dropdown</DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={<Checkbox checked={required} onChange={(e) => setRequired(e.target.checked)} />}
          label="Required"
        />
        <TextField
          label="New Option"
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          fullWidth
        />
        <Button onClick={addOption}>Add Option</Button>
        <List>
          {options.map((option, index) => (
            <ListItem key={index}>
              <ListItemText primary={option} />
              <IconButton edge="end" onClick={() => removeOption(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropdownCustomizer;
