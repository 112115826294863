import axios from 'axios';
// import thunk from 'redux-thunk';
import { fetchProjects } from '../apislice';


export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';


export const SELECT_PROJECT_TO_EDIT = 'SELECT_PROJECT_TO_EDIT';
export const OPEN_EDIT_WINDOW = 'OPEN_EDIT_WINDOW';
export const CLOSE_EDIT_WINDOW = 'CLOSE_EDIT_WINDOW';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';


export const addProject = (project) => ({
    type: ADD_PROJECT,
    payload: project,
});

export const deleteProject = (projectId) => ({
    type: DELETE_PROJECT,
    payload: projectId,
});




// export const setSelectedProject = (project) => ({
//     type: 'SET_SELECTED_PROJECT',
//     payload: project,
// });

// export async function setSelectedProject(dispatch, getState) {
//     const response = await axios.get('http://localhost:8000/api/projects/');
//     dispatch({
//         type: SET_SELECTED_PROJECT,
//         payload: response.data,
//     });
// }

export const setSelectedProject = (project) => ({
  type: SET_SELECTED_PROJECT,
  payload: project,
});

// export const updateProject = (editedProject) => ({
//   type: UPDATE_PROJECT,
//   payload: editedProject,
// });


export const selectProjectToEdit = (project) => ({
    type: 'SELECT_PROJECT_TO_EDIT',
    payload: project,
});

export const openEditWindow = () => {
    console.log('openEditWindow');
    return {
      type: OPEN_EDIT_WINDOW,
    };
};

export const closeEditWindow = () => ({
    type: CLOSE_EDIT_WINDOW,
});



export const updateProject = (project) => async (dispatch) => {
  try {
    const response = await axios.put(`http://localhost:8000/api/projects/${project.id}/`, project);
    const updatedProject = response.data;

    // Dispatch a plain object action with the updated project data
    dispatch({
      type: UPDATE_PROJECT,
      payload: updatedProject,
    });

    // Dispatch a thunk action to fetch the updated project
    dispatch(fetchProjects());
  } catch (error) {
    // Handle error here
  }
};












// export function updateProject(project) {
//     return {
//         type: 'UPDATE_PROJECT',
//         payload: project
//     };
// }


// export const updateProject = (project) => ({
//     type: UPDATE_PROJECT,
//     payload: { project },
//   });

// export function updateProject(project) {
//     return dispatch => {
//         axios.put(`http://localhost:8000/api/projects/${project.id}`, project)
//             .then(response => {
//                 dispatch({
//                     type: 'UPDATE_PROJECT',
//                     payload: response.data
//                 });
//             })
//             .catch(error => {
//                 // handle error here
//             });
//     };
// }
