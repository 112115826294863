import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//API
import { fetchTrades, addCompany, updateCompany } from '../../apislice';
import axios from 'axios';

//MODAL
import FullScreenModal from '../utils/FullScreenModal';

//MUI
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, FormGroup, Typography} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
// import { InputBase } from '@mui/material';
// import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Select, MenuItem, InputLabel, FormControl, FormHelperText, Switch, FormControlLabel} from '@mui/material';
import { Button, Input } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { List, ListItem, ListItemText } from '@mui/material';

//FORMIK
import { ErrorMessage } from 'formik';
import { useField, useFormikContext } from 'formik';
import { Form, Formik } from 'formik';

//YUP
import * as Yup from 'yup';
// import { SignalCellularNullRounded } from '@mui/icons-material';


//COMPONENTS
// import GridItemCard from '../utils/GridItemCard';

import { format } from 'date-fns';





const CompaniesManage = ({ open, handleClose, companyId}) => {
    const dispatch = useDispatch();
    const companyIdRef = useRef(companyId);
    const [value, setValue] = React.useState('1');
    
    const trades = useSelector((state) => state.api.trades.data);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const companies = useSelector((state) => state.api.companies.data);
    const companyData = companies.find((company) => company.id === companyId);
    const userId = useSelector((state) => state.auth.email.id);
    const projects = useSelector((state) => state.api.projects.data);
    const companyProjects = projects.filter(project => project.companyId === companyId);
    const [contacts, setContacts] = React.useState([]);




    
    useEffect(() => {
        companyIdRef.current = companyId;

    }, [companyId]);







    useEffect(() => {
        if(companyId) {
            setIsEditMode(true);
        }else {
            setIsEditMode(false);
        }
        dispatch(fetchTrades());
    }, [dispatch]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        if (companyId) {
            axios.get(`/api/companies/${companyId}/contacts/`)
                .then(response => {
                    setContacts(response.data);
                })
                .catch(error => {
                    console.error("There was an error fetching the contacts!", error);
                });
        }
    }, [companyId]);


    const FormikTextField = ({ name, label, disabled, ...otherProps }) => {
        const [field, meta] = useField(name);
        // const [isFocused, setIsFocused] = React.useState(false);
        const errorText = meta.error && meta.touched ? meta.error : '';


        return (
            <TextField
                {...field}
                {...otherProps}
                label={label}
                disabled={disabled}
                helperText={errorText}
                error={!!errorText}
                size="small"
                sx={{ mb: 2}}
                fullWidth
            />
        );
    };

    const FormikMuiSelect = ({ label, options, ...props }) => {
        const [field, meta] = useField(props);
        return (
          <FormControl fullWidth error={meta.touched && Boolean(meta.error)} size="small" >
            <InputLabel >{label}</InputLabel>
            <Select {...field} {...props} label={label} sx={{ mb: 1.3}}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {meta.touched && meta.error ? (
              <FormHelperText>{meta.error}</FormHelperText>
            ) : null}
          </FormControl>
        );
      };

    const FormikDateField = ({ name, label, ...otherProps }) => {
        const { setFieldValue } = useFormikContext();
        const [field, meta] = useField(name);
      
        return (
            <FormControl fullWidth error={meta.touched && Boolean(meta.error)} >
                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                    <DatePicker
                    {...field}
                    {...otherProps}
                    label={label}
                    size="small"
                    sx={{ mb: 1.3}}
                    format='dd/MM/yyyy'
                    fullWidth
                    value={field.value || null} // Handle null values to avoid warnings
                    onChange={(value) => {
                        setFieldValue(field.name, value);
                    }}
                    slotProps={{ textField: {size: 'small'}}}
                    

                    />
                </LocalizationProvider>
            </FormControl>
        );
      };

    //   const FormikFileField = ({ name, ...otherProps }) => {
    //     const [field, meta, helpers] = useField(name);
      
    //     const handleChange = (event) => {
    //       const files = event.target.files;
    //       let fileList = [];
    //       if (files) {
    //         for (let i = 0; i < files.length; i++) {
    //           // Add files to the fileList array
    //           fileList.push(files.item(i));
    //         }
    //       }
    //       // Update Formik's state with the fileList array
    //       helpers.setValue(fileList);
    //     };
      
    //     return (
    //       <>
    //         <Input
    //           {...field}
    //           {...otherProps}
    //           type="file"
    //           onChange={handleChange}
    //           style={{ display: 'none' }} // Hide the native file input
    //           id="file-upload"
    //           multiple // Remove this prop if only single file upload is required
    //         />
    //         <label htmlFor="file-upload">
    //           <Button variant="contained" component="span">
    //             Upload Document
    //           </Button>
    //         </label>
    //         {meta.touched && meta.error ? <div style={{ color: 'red' }}>{meta.error}</div> : null}
    //       </>
    //     );
    //   };
    
      const FormikSwitch = ({ name, label, ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(name);
      
        return (
          <FormControlLabel
            control={
              <Switch
                {...props}
                checked={field.value}
                onChange={(event, checked) => setFieldValue(name, checked)}
              />
            }
            label={label}
          />
        );
      };


    const categoryChoices = [
        { value: 'Client', label: 'Client' },
        { value: 'Subcontractor', label: 'Subcontractor' },
        { value: 'Supplier', label: 'Supplier' },
        { value: 'Designer', label: 'Designer' },
        { value: 'Consultant', label: 'Consultant' },
        { value: 'Other', label: 'Other' },
      ];

    const statusChoices = [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ];

    const initialValues = companyData ? {
        name: companyData.name,
        reference: companyData.reference,
        address: companyData.address,
        website: companyData.website,
        phone_number: companyData.phone_number,
        vat_number: companyData.vat_number,
        CIS_number: companyData.CIS_number,
        company_number: companyData.company_number,
        trades: companyData.trades,
        category: companyData.category,
        status: companyData.status,
        employers_liability_policy_number: companyData.employers_liability_policy_number || '',
        employers_liability_broker: companyData.employers_liability_broker || '',
        employers_liability_expiry: companyData.employers_liability_expiry || '',
        employers_liability_document: companyData.employers_liability_document || '', // Use 'undefined' for file inputs
        public_liability_policy_number: companyData.public_liability_policy_number || '',
        public_liability_broker: companyData.public_liability_broker || '',
        public_liability_expiry: companyData.public_liability_expiry || '',
        public_liability_document: companyData.public_liability_document || '', 
        professional_indemnity_policy_number: companyData.professional_indemnity_policy_number || '',
        professional_indemnity_broker: companyData.professional_indemnity_broker || '',
        professional_indemnity_expiry: companyData.professional_indemnity_expiry || '',
        professional_indemnity_document: companyData.professional_indemnity_document || '',
        contractors_all_risk_policy_number: companyData.contractors_all_risk_policy_number  || '',
        contractors_all_risk_broker: companyData.contractors_all_risk_broker || '',
        contractors_all_risk_expiry: companyData.contractors_all_risk_expiry || '',
        contractors_all_risk_document: companyData.contractors_all_risk_document || '',

    
    } : {
        name: '',
        reference: '',
        address: '',
        website: '',
        phone_number: '',
        vat_number: '',
        CIS_number: '',
        company_number: '',

        category: '',
        status: 'Active',
        employers_liability_policy_number: '',
        employers_liability_broker: '',
        employers_liability_expiry: '',
        employers_liability_document: '',
        public_liability_policy_number: '',
        public_liability_broker: '',
        public_liability_expiry: '',
        public_liability_document: '',
        professional_indemnity_policy_number: '',
        professional_indemnity_broker: '',
        professional_indemnity_expiry: '',
        professional_indemnity_document: '',
        contractors_all_risk_policy_number: '',
        contractors_all_risk_broker: '',
        contractors_all_risk_expiry: '',
        contractors_all_risk_document: '',


        
    }




    const formTitle = companyData ? `Edit Company: ${companyData.name}` : 'Add Company';

    //Validation Schema
    const validationSchemea = Yup.object({
        name: Yup.string()
        .required('Required')
        .trim()
        .lowercase()
        .matches(/^[a-z0-9 ]+$/i, 'Must be only letters and numbers')
        .test('isUnique', 'This company already exists', function (value) {
          // Skip uniqueness check if in edit mode and the value matches the original company name
          if (isEditMode && companyData && value.toLowerCase() === companyData.name.toLowerCase()) {
            return true;
          }
          const companyNames = companies
            .filter(company => company.id !== companyData?.id) // Exclude the current company
            .map(company => company.name.toLowerCase());
    
          return !companyNames.includes(value.toLowerCase());
        }),
        reference: Yup.string() 
            .required('Required')
            .trim()
            .lowercase()
            .test(
                'isUnique',
                'This reference already exists',
                (value) => {
                    const currentCompanyId = companyId
                    const companyReferences = companies.filter(company => company.id !== currentCompanyId).map((companies) => companies.reference.toLowerCase());
                    return !companyReferences.includes(value);
                }
            ),
        address: Yup.string().required('Required'),
        phone_number: Yup.string().required('Required'),
        category: Yup.string().required('Required'),
        status: Yup.string().required('Required'),


    });




    return (
        <FullScreenModal open={open} handleClose={handleClose} title={formTitle}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemea}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (values.website && !/^(?:f|ht)tps?\:\/\//.test(values.website)) {
                        values.website = `http://${values.website}`;
                    }





                    
                
                    const formData = new FormData();


                    
                    

                    Object.keys(values).forEach((key) => {
                        // Check if the key is 'trades'. If it is, ensure it has a value before appending.
                        if (key !== 'trades' || (values.trades && values.trades.length > 0)) {
                            formData.append(key, values[key]);
                        }
                    });
                    formData.append('user', userId);

                    if (values.employers_liability_expiry) {
                        const formattedEmployersLiabilityExpiry = format(new Date(values.employers_liability_expiry), 'yyyy-MM-dd');
                        formData.append('employers_liability_expiry', formattedEmployersLiabilityExpiry);
                    }
                    
                    if (values.public_liability_expiry) {
                        const formattedPublicLiabilityExpiry = format(new Date(values.public_liability_expiry), 'yyyy-MM-dd');
                        formData.append('public_liability_expiry', formattedPublicLiabilityExpiry);
                    }
                    
                    if (values.professional_indemnity_expiry) {
                        const formattedProfessionalIndemnityExpiry = format(new Date(values.professional_indemnity_expiry), 'yyyy-MM-dd');
                        formData.append('professional_indemnity_expiry', formattedProfessionalIndemnityExpiry);
                    }
                    
                    if (values.contractors_all_risk_expiry) {
                        const formattedContractorsAllRiskExpiry = format(new Date(values.contractors_all_risk_expiry), 'yyyy-MM-dd');
                        formData.append('contractors_all_risk_expiry', formattedContractorsAllRiskExpiry);
                    }


                    





                    console.log('Submitting: ', values);

                    if (isEditMode) {

                        dispatch(updateCompany({companyId: companyId, company: formData}))
                        .unwrap()
                        .then((result) => {
                            alert('Company Updated');
                            setSubmitting(false);
                            resetForm();
                            handleClose();
                        });
                    } else {
                    console.log('Adding Company:', formData);
                    dispatch(addCompany(formData))
                    .unwrap()
                    .then((result) => {
                        alert('Company Added');
                        setSubmitting(false);
                        resetForm();
                        handleClose();
                    })
                    .catch((error) => {
                        alert('An error occurred');
                        console.log('Error:', error);
                        setSubmitting(false);
                    });

                }}}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label="Company Details" value="1" />
                                    <Tab label="Insurance" value="2" />
                                    <Tab label="Documents" value="3" />
                                    <Tab label="Projects" value="4" />
                                    <Tab label="Contacts" value="5" />
                                    <Tab label="Users" value="6" />

                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                
                                <Grid2 container spacing={2}>
                                    <Grid2 item xs={3} spacing={2}>
                                        <FormGroup>
                                        <FormikMuiSelect
                                            name="category"
                                            label="Category"
                                            options={categoryChoices.map(choice => ({
                                                value: choice.value,
                                                label: choice.label
                                            }))}
                                        />

                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="name" type="text" label="Company Name" />

                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="reference" type="text" label="Reference" disabled={isEditMode} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="address" type="text" label="Address" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="website" type="text" label="Website" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="email" type="text" label="Email"/>
                                            
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="phone_number" type="text" label="Phone Number" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="company_number" type="text" label="Company Number" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="vat_number" type="text" label="VAT Number"/>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormikMuiSelect 
                                                name="status" 
                                                label="Status" 
                                                options={statusChoices.map(choice => ({
                                                    value: choice.value,
                                                    label: choice.label
                                                }))}/>
                                        </FormGroup>

                                    </Grid2>
                                    <Grid2 item xs={3} spacing={2}>

                                        {values.category === 'Subcontractor' && (
                                            <FormGroup className="mb-auto">
                                                <FormikMuiSelect 
                                                    name="trades" 
                                                    label="Trade" 
                                                    options={trades.filter(trade => trade.category ==='Trade').map(choice => ({
                                                        value: choice.id,
                                                        label: choice.name
                                                }))}/>
                                                <ErrorMessage name="trades" component="div" className="form-error-message" />
                                            </FormGroup>
                                        )}
                                        {values.category === 'Designer' && (
                                            <FormGroup className="mb-auto">
                                                <FormikMuiSelect 
                                                    name="trades" 
                                                    label="Trade" 
                                                    options={trades.filter(trade => trade.category ==='Designer').map(choice => ({
                                                        value: choice.id,
                                                        label: choice.name
                                                }))}/>
                                                <ErrorMessage name="trades" component="div" className="form-error-message" />
                                            </FormGroup>
                                        )}
                                        {values.category === 'Subcontractor' && (
                                            <FormGroup>
                                                <FormikTextField name="CIS_number" type="text" label="CIS Number" />
                                            </FormGroup>
                                        )}

                                    </Grid2>

                                </Grid2>

                            </TabPanel>
                            <TabPanel value="2">
                                <Box>
                                    <Grid2 container spacing={2}> {/* Add spacing for some space between the grid items */}

                                        {/* Employers Liability Insurance Section */}
                                        <Grid2 item xs={3}>
                                            <Grid2 item xs={12} sx={{ mb: 1}}>
                                                <Typography variant="h7" gutterBottom sx={{ mb: 1 }}>
                                                    Employers Liability Insurance
                                                </Typography>

                                                {/* <FormikSwitch name="employersLiabilityEnabled" /> */}
                                            </Grid2>
                                        {/* {values.employersLiabilityEnabled && (
                                            <> */}
                                            <FormikTextField name="employers_liability_policy_number" type="text" label="Number" />
                                            <FormikTextField name="employers_liability_broker" type="text" label="Broker" />
                                            <FormikDateField name="employers_liability_expiry" label="Insurance Expiry" />
                                            {/* <FormikFileField name="employers_liability_document" label="Upload Document" /> */}
                                            {/* </>
                                        )} */}
                                        </Grid2>

                                        {/* Public Liability Insurance Section */}
                                        <Grid2 item xs={3}>
                                            <Grid2 xs={12} sx={{ mb: 1}}>
                                                <Typography variant="h7" gutterBottom sx={{ mb: 2 }}>
                                                    Public Liability Insurance
                                                </Typography>
                                            </Grid2>

                                        
                                            <FormikTextField name="public_liability_policy_number" type="text" label="Number" />
                                            <FormikTextField name="public_liability_broker" type="text" label="Broker" />
                                            <FormikDateField name="public_liability_expiry" label="Insurance Expiry"  />
                                            {/* <FormikFileField name="public_liability_document" label="Upload Document" /> */}

                                        </Grid2>

                                        {/* Professional Liability Insurance Section */}
                                        <Grid2 item xs={3}>
                                            <Grid2 xs={12} sx={{ mb: 1}}>
                                                <Typography variant="h7" gutterBottom sx={{ mb: 2 }}>
                                                    Professional Indemnity Insurance
                                                </Typography>
                                            </Grid2>
                                        
                                            <FormikTextField name="professional_indemnity_policy_number" type="text" label="Number" />
                                            <FormikTextField name="professional_indemnity_broker" type="text" label="Broker" />
                                            <FormikDateField name="professional_indemnity_expiry" label="Insurance Expiry" />
                                            {/* <FormikFileField name="professional_indemnity_document" label="Upload Document" /> */}
                                        </Grid2>
                                        <Grid2 item xs={3}>
                                            <Grid2 xs={12} sx={{ mb: 1}}>
                                                <Typography variant="h7" gutterBottom sx={{ mb: 2 }}>
                                                    Contractors All Risk Insurance
                                                </Typography>
                                            </Grid2>                                            
                                                <FormikTextField name="contractors_all_risk_policy_number" type="text" label="Number" />
                                                <FormikTextField name="contractors_all_risk_broker" type="text" label="Broker" />
                                                <FormikDateField name="contractors_all_risk_expiry" label="Insurance Expiry" size="small" />
                                                {/* <FormikFileField name="contractors_all_risk_document" label="Upload Document" /> */}

                                        </Grid2>

                                    </Grid2>
                                </Box>
                            </TabPanel>
                            <TabPanel value="4">
                                <Box>
                                    <List>
                                        <Typography
                                            variant="h6"
                                            sx={{ mb: 2 }}
                                        >
                                            Projects
                                        </Typography>
                                        {companyProjects.length > 0 ? (
                                            companyProjects.map((project) => (
                                                <ListItem key={project.id}>
                                                    <ListItemText primary={project.name} />
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem>
                                                <ListItemText primary="No projects found" />
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            
                            </TabPanel>
                            <TabPanel value="6">
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{ mb: 2 }}
                                    >
                                        Contacts
                                    </Typography>
                                    <List>
                                        {contacts.length > 0 ? (
                                            contacts.map(contact => (
                                                <ListItem key={contact.id}>
                                                    <ListItemText primary={contact.name} secondary={contact.position} />
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem>
                                                <ListItemText primary="No contacts found" />
                                            </ListItem>
                                        )}
                                    </List>


                                    

                                </Box>
                            </TabPanel>

                        
                        </TabContext>
                        <Box sx={{ position: 'absolute', right: 0, bottom: 0, p: 2 }}>
                            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{mr: 1}}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Box>



                    </Form>
                    )}
                </Formik>
        </FullScreenModal>
    );
}

export default CompaniesManage;