import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchForms,
  fetchProjects,
  fetchFormTemplates,
  newFormFromWebSocket,
  updateFormFromWebSocket,
  deleteFormFromWebSocket,
} from '../../apislice';
import { Button, Box, Typography, Container, IconButton, Modal, TextField, Grid, Tabs, Tab } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { AddCircleOutline, GetApp, Refresh } from '@mui/icons-material';
import FormEditor from '../Formbuilder/FormEditor';  // Replace FormFiller with FormEditor
import DownloadPdfButton from './formPdf';  // Add DownloadPdfButton

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { TreeView, TreeItem } from '@mui/x-tree-view';

import { connectWebSocket, disconnectWebSocket } from '../../websocketService';  // Add connectWebSocket, disconnectWebSocket

// Define styles for the modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const FormHomePage = () => {
  const dispatch = useDispatch();
  const forms = useSelector(state => state.api.forms.data);
  const formTemplates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);
  const [isFormEditorOpen, setIsFormEditorOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [tabIndex, setTabIndex] = useState(0);  // Manage tabs for views
  const currentUserId = useSelector((state) => state.auth.id);


  const [selectedProject, setSelectedProject] = useState(projects[0]?.id || null);  // Initialize with the first project


  const users = useSelector((state) => state.api.users.data);
  const isAdminOrManager = useSelector((state) => state.auth.group === 'Admin' || state.auth.group === 'Manager');

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchFormTemplates());
    dispatch(fetchProjects());
  }, [dispatch]);

  // WebSocket connection
  useEffect(() => {
    const handleWebSocketMessage = (data) => {
      if (data.created_by !== currentUserId) {
        if (data.action === 'created') {
          dispatch(newFormFromWebSocket(data));
        } else if (data.action === 'updated') {
          dispatch(updateFormFromWebSocket(data));
        } else if (data.action === 'deleted') {
          dispatch(deleteFormFromWebSocket(data.id));
        }
      }
    };

    connectWebSocket('formcreator', handleWebSocketMessage);
    return () => disconnectWebSocket('formcreator');
  }, [dispatch, currentUserId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);  // Switch between tabs
  };

  const getProjectName = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    return project ? project.project_name : '';
  };

  const getFormName = (templateId) => {
    const template = formTemplates.find(temp => temp.id === templateId);
    return template ? template.name : 'Unknown Template';
  };

  const getFormRef = (templateId) => {
    const template = formTemplates.find(temp => temp.id === templateId);
    return template ? template.ref : 'Unknown Reference';
  };

  const handleAddForm = () => {
    setSelectedForm(null);
    setIsFormEditorOpen(true);
  };

  const handleEditForm = (form) => {
    setSelectedForm(form);
    setIsFormEditorOpen(true);
  };

  const handleCloseFormEditor = () => {
    setIsFormEditorOpen(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredForms = forms.filter((form) => {
    const searchLowered = searchText.toLowerCase();
    return (
      getFormName(form.template).toLowerCase().includes(searchLowered) ||
      getFormRef(form.template).toLowerCase().includes(searchLowered) ||
      getProjectName(form.project).toLowerCase().includes(searchLowered)
    );
  });

  const sortedForms = filteredForms.slice().sort((a, b) => b.id - a.id);



  const columns = [
    { field: 'id', headerName: 'ID', width: 90, sortComparator: (v1, v2) => v2 - v1 },
    {
      field: 'project_name',
      headerName: 'Project',
      flex: 1,
      valueGetter: (params) => getProjectName(params.row.project),
    },
    {
      field: 'form_name',
      headerName: 'Form Name',
      flex: 1,
      valueGetter: (params) => getFormName(params.row.template),
    },
    {
      field: 'form_ref',
      headerName: 'Reference',
      flex: 1,
      valueGetter: (params) => getFormRef(params.row.template),
    },
    {
      field: 'number',
      headerName: 'Number',
      flex: 1,
      valueGetter: (params) => params.row.number,
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      flex: 1,
      valueGetter: (params) => {
        const user = users.find((user) => user.id === params.row.created_by);
        return user ? `${user.first_name} ${user.last_name}` : 'Unknown User';
      },
      hide: !isAdminOrManager,  // Conditionally hide based on user role
    },
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      valueGetter: (params) => new Date(params.row.created_at).toLocaleDateString(),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton color="primary" onClick={() => handleEditForm(params.row)}>
            <EditIcon />
          </IconButton>
          <DownloadPdfButton formId={params.row.id} />
        </Box>
      ),
    },
  ];


  const handleRefreshClick = () => {
    dispatch(fetchForms())
      .then(() => {
        alert('Forms Updated!');
      })
      .catch(() => {
        alert('An error occurred while updating forms.');
      });
  };

  const exportToCsv = (filename, rows) => {
    const csvContent = rows.map(row => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const prepareDataForExport = () => {
    return forms.map(form => ({
      ID: form.id,
      Name: getFormName(form.template),
      Reference: getFormRef(form.template),
      Project: getProjectName(form.project),
      Date: new Date(form.created_at).toLocaleDateString(),
    }));
  };

  const handleProjectChange = (event, newProjectId) => {
    setSelectedProject(newProjectId);
    setSelectedForm(null);  // Reset form selection when changing project
  };

  const getFormTypes = (projectId) => {
    const formsForProject = forms.filter(form => form.project === projectId);
    const formTypes = formsForProject.reduce((acc, form) => {
      const formName = getFormName(form.template);
      if (!acc[formName]) acc[formName] = [];
      acc[formName].push(form);
      return acc;
    }, {});
    return formTypes;
  };



  const renderSidebarWithPreview = () => {
    if (!projects || projects.length === 0) return <Typography>No projects available</Typography>;

    return (
      <Box display="flex" height="calc(100vh - 222px)">
        {/* Sidebar */}
        <Box width="25%" bgcolor="lightgray" p={2} overflow="auto">
          <Typography variant="h6" gutterBottom>
            Projects and Forms
          </Typography>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ maxHeight: '100%', overflowY: 'auto' }}
          >
            {/* Map projects */}
            {projects.map((project) => {
              const formTypes = getFormTypes(project.id);
              if (Object.keys(formTypes).length === 0) {
                return null; // Skip projects with no forms
              }

              return (
                <TreeItem nodeId={`project-${project.id}`} label={project.project_name} key={project.id}>
                  {/* Map form types within each project */}
                  {Object.entries(formTypes).map(([formType, forms]) => (
                    <TreeItem nodeId={`formType-${project.id}-${formType}`} label={formType} key={formType}>
                      {/* Map individual forms within each form type */}
                      {forms.map((form) => (
                        <TreeItem
                          nodeId={`form-${form.id}`}
                          label={`Number: ${form.number}`}
                          key={form.id}
                          onClick={() => setSelectedForm(form)}
                          sx={{ cursor: 'pointer' }}
                        />
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              );
            })}
          </TreeView>
        </Box>

        {/* Preview Panel */}
        <Box width="75%" p={2}>
          {selectedForm ? (
            <FormEditor form={selectedForm} onClose={() => setSelectedForm(null)} />
          ) : (
            <Typography variant="body1">Select a form to view details</Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth={false} style={{ padding: "0px 0px 0px 0px" }}>
      <Box sx={{ padding: '10px 0px 0px 0px' }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
          <Grid item>
            <Typography variant="h4" component="h4" gutterBottom>
              Forms
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" gap={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddForm}
                startIcon={<AddCircleOutline />}
              >
                Add
              </Button>
              <Button onClick={handleRefreshClick} startIcon={<Refresh />}>

              </Button>
              <Button
                color="primary"
                onClick={() => exportToCsv('forms.csv', prepareDataForExport())}
                startIcon={<GetApp />}
              >
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Table" />
          <Tab label="Sidebar" />
        </Tabs>

        {tabIndex === 0 && (
          <>
            <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '8px' }}>
              <TextField
                id="search"
                size="small"
                variant="outlined"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search Forms..."
                fullWidth
              />
            </Grid>
            <Box sx={{ height: `calc(100vh - 262px)` }}>
              <DataGrid
                rows={sortedForms}
                columns={columns}
                pageSize={10}
                autoHeight={false}
                disableSelectionOnClick
                getRowId={(row) => row.id}
              />
            </Box>
          </>
        )}

        {tabIndex === 1 && renderSidebarWithPreview()}
      </Box>

      <Modal
        open={isFormEditorOpen}
        onClose={handleCloseFormEditor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleCloseFormEditor}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <FormEditor form={selectedForm} onClose={handleCloseFormEditor} />
        </Box>
      </Modal>
    </Container>
  );
};

export default FormHomePage;
