import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStockItem, createStockItem } from '../../apislice';
import FullScreenModal from '../utils/FullScreenModal';
import { Box, Button, FormGroup, TextField } from '@mui/material';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

const ItemEditAndAddForm = ({ open, handleClose, itemId }) => {
    const dispatch = useDispatch();
    const item = useSelector((state) => state.api.stock_items.data.find((item) => item.id === itemId));

    const initialValues = {
        ref: item ? item.ref : '',
        name: item ? item.name : '',
        description: item ? item.description : '',
        price: item ? item.price : '',
        stock_level: item ? item.stock_level : 0,
        reorder_level: item ? item.reorder_level : 0,
        unit: item ? item.unit : '',
        // unapproved_quantity: item ? item.unapproved_quantity : 0,
    };

    const validationSchema = Yup.object({
        ref: Yup.string().required('This field is required.'),
        name: Yup.string().required('This field is required.'),
        price: Yup.number().required('This field is required.'),
        stock_level: Yup.number().required('This field is required.').integer().min(0),
        reorder_level: Yup.number().required('This field is required.').integer().min(0),
        unit: Yup.string().required('This field is required.'),
        // unapproved_quantity: Yup.number().required('This field is required.').integer().min(0),
    });

    const FormikTextField = ({ name, label, ...otherProps }) => {
        const [field, meta] = useField(name);
        const errorText = meta.error && meta.touched ? meta.error : '';

        return (
            <TextField
                {...field}
                {...otherProps}
                label={label}
                helperText={errorText}
                error={!!errorText}
                size="small"
                fullWidth
                sx={{ mb: 2 }}
            />
        );
    };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        if (itemId) {
            // Update existing item
            const updatedItem = { ...item, ...values };
            console.log(values);
            dispatch(updateStockItem({ stockItemId: itemId, stockItem: updatedItem }))
                .unwrap()
                .then(() => {
                    alert('Item updated successfully');
                    setSubmitting(false);
                    resetForm();
                    handleClose();
                })
                .catch(() => {
                    alert('An error occurred');
                    setSubmitting(false);
                });
        } else {
            // Add new item
            console.log(values);
            dispatch(createStockItem({ stockItem: values}))
                .unwrap()
                .then(() => {
                    alert('Item created successfully');
                    setSubmitting(false);
                    resetForm();
                    handleClose();
                })
                .catch(() => {
                    alert('An error occurred');
                    setSubmitting(false);
                });
        }
    };

    return (
        <FullScreenModal open={open} handleClose={handleClose} title={itemId ? `Edit Item: ${item.name}` : 'Add New Item'}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormGroup>
                            <FormikTextField name="ref" label="Reference" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="name" label="Name" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="description" label="Description" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="price" label="Price" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="stock_level" label="Stock Level" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="reorder_level" label="Reorder Level" />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField name="unit" label="Unit" />
                        </FormGroup>
                        {/* <FormGroup>
                            <FormikTextField name="unapproved_quantity" label="Unapproved Quantity" />
                        </FormGroup> */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                {itemId ? 'Update' : 'Add'} Item
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FullScreenModal>
    );
};

export default ItemEditAndAddForm;
