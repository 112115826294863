import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchProjects, deleteProject, fetchClients, addProject } from '../apislice';
import { selectProjectToEdit, openEditWindow, closeEditWindow, updateProject} from '../actions/projectActions';
import '../App.css';
import { connect} from 'react-redux';
import EditProjectForm from './editProjectForm';
import ProjectAddForm from './ProjectAddForm';
import ModalForm from './modalForm';





const mapStateToProps = (state) => ({
    projects: state.api.projects.data,
    status: state.api.projects.status,
    isEditWindowVisible: state.project.isEditWindowVisible,
    selectedProject: state.project.selectedProject,
    editedProject: state.project.editedProject,
    token: state.auth.token,


});

const mapDispatchToProps = (dispatch) => ({
    fetchProjects: () => dispatch(fetchProjects()),
    selectProjectToEdit: (project) => dispatch(selectProjectToEdit(project)),
    openEditWindow: () => dispatch(openEditWindow()),
    closeEditWindow: () => dispatch(closeEditWindow()),
    updateProject: (editedProject) => dispatch(updateProject(editedProject)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    addProject: (project) => dispatch(addProject(project)),
});


const ProjectDisplay = () => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.api.projects.data);
    const status = useSelector((state) => state.api.projects.status);
    const isEditWindowVisible = useSelector((state) => state.project.isEditWindowVisible);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [editedProject, setEditedProject] = useState();
    const [showProjectAddForm, setShowProjectAddForm] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ showEditForm, setShowEditForm ] = useState(false);
    const clients = useSelector((state) => state.api.clients);
    const accessToken = useSelector((state) => state.auth.token);
    const error = useSelector((state) => state.api.error);

    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 5;


    useEffect(() => {
        console.log('useEffect');
        if (status === 'idle') {
            dispatch(fetchProjects());
            dispatch(fetchClients());
        }
    }, [status, dispatch]);

    const handleEditClick = (project) => {
        console.log('handleEditClick');
        setEditedProject(project);
        setShowEditForm(true);
        // dispatch(selectProjectToEdit(project));
        // setEditedProject(project);
        // const offcanvasElement = document.getElementById('offcanvasWithBothOptions');
        // const bsOffCanvas = new Offcanvas(offcanvasElement);
        // bsOffCanvas.show();
        //dispatch(openEditWindow());
    };

    const handleCloseEditWindow = () => {
        setShowEditForm(false);
        // dispatch(closeEditWindow());
    };
    const handleFieldChange = (event) => {
        console.log('handleFieldChange event');
        const { name, value } = event.target;
        setEditedProject({
            ...editedProject,
            [name]: value,

        });

    };

    const handleAddUsers = (projectId) => {
      console.log('handleAddUsers');
      setIsModalOpen(true);
    };

    


    const handleUpdateProject = () => {
        console.log('handleUpdateProject action');
        dispatch(updateProject(editedProject));
        handleCloseEditWindow();
    };

    const handleDeleteLink = (projectId) => {
      if(window.confirm('Are you sure you want to delete this project?')) {
        dispatch(deleteProject(projectId))
          .then(() => {
            dispatch(fetchProjects());
          })
          .catch((err) => {
            console.log(err);
          }
        );
        
        
      }
    };

    const handleAddProject = () => {
      setShowProjectAddForm(true);
    }



    const getClientName = (projectId) => {
      const client = clients.find((client) => client.id === projectId);
      return client ? client.client_name : '';
    };

    const handleRefreshClick = () => {
      dispatch(fetchProjects());
    };



    const renderProjects = () => {


        if (status === 'loading') {
          return <div>Loading...</div>;
        }
    
        if (status === 'failed') {
          return <div>
            Error: An error occurred while fetching data.
            <button onClick={handleRefreshClick}>Try again</button>
            {error && <div>Error Details{error}</div>}
            
            </div>;
          
        }
    
        return (
          
          <div className="container-fluid">
            <div className="row">
              <div className="col border">
                <h1>Project List</h1>
              </div>
            </div>
            <div className="row">
              <div className="col border">
                <h2></h2>
              </div>
              <div className="col border d-flex justify-content-end">
                <div className="button-container">
                  <button className="btn btn-primary " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" onClick={handleAddProject}>Add Project</button>                      <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasWithBothOptions"
                        aria-controls="offcanvasWithBothOptions"
                        onClick={handleRefreshClick}
                      >
                        Refresh
                      </button>                </div>
              </div>
            </div>

            <div className="row justify-content-md-center">
              <div className="col border">
                <div className='scrollable-container'>
                  <table className="table table-responsive table-hover">
                      <thead>
                      <tr>
                          <th style={{ width: '20%' }}>Project Name</th>
                          <th style={{ width: '20%' }}>Budget</th>
                          <th style={{ width: '20%' }}>Start Date</th>
                          <th style={{ width: '20%' }}>End Date</th>
                          <th style={{ width: '20%' }}>Status</th>
                          <th style={{ width: '20%' }}>Client</th>
                          <th style={{ width: '20%' }}>Actions</th>
                          
                      </tr>
                      </thead>
                      <tbody>
                        {projects && projects.map((project) => (
                          
                          <tr key={project.id}>
                            <td>{project.project_name}</td>
                            <td>{project.budget}</td>
                            <td>{project.start_date}</td>
                            <td>{project.end_date}</td>
                            <td>{project.status}</td>
                            <td>{clients.find((client) => client.id === project.client)?.name}</td>
                            
                            <td>
                              <div className="button-container">
                                <button onClick={() => handleEditClick(project)} className="btn btn-sm"> <i className='bi bi-pencil-square edit-icon'></i> </button> 
                                <button onClick={() => handleDeleteLink(project.id)} className="btn btn-sm"><i className='bi bi-archive archive-icon' viewBox="0 0 16 16"></i></button>
                                <button onClick={() => handleAddUsers(project.id)} className="btn btn-sm"><i className='bi bi-people people-icon' viewBox="0 0 16 16"></i></button>
                              </div>
                            </td>
                          </tr>

                        ))}



                      </tbody>
                  </table>
                </div>
              </div>
            </div>
           
          </div>
        );
      };
      
      return (
        <div>
          {renderProjects()}
          {showEditForm && (
            <div>
                <EditProjectForm
                  editedProject={editedProject}
                  handleClose={handleCloseEditWindow}
                  handleFieldChange={handleFieldChange}
                  handleUpdateProject={handleUpdateProject}
                  handleCloseEditWindow={handleCloseEditWindow}
                  show={showEditForm}
                />

            </div>
          )}
          {/* {isEditWindowVisible && editedProject && (
            <div>
              <EditProjectForm
                editedProject={editedProject}
                handleFieldChange={handleFieldChange}
                handleUpdateProject={handleUpdateProject}
                handleCloseEditWindow={handleCloseEditWindow}
              />
            </div>
          )} */}
          {showProjectAddForm && (
            <div>
              <ProjectAddForm show={showProjectAddForm} onClose={() => setShowProjectAddForm(false)}/>
            </div>
          )}
        </div>

        );
    };
    
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDisplay);