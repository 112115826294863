import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function TableItem({ item, handleChange }) {
  // Function to handle value change in the table cells
  const handleCellChange = (rowIndex, colIndex, value) => {
    const updatedRows = [...item.rows]; // Clone the current rows
    updatedRows[rowIndex][colIndex] = value; // Update the specific cell
    handleChange(item.id, updatedRows); // Trigger the change with the new rows
  };

  // Function to add a new row to the table
  const handleAddRow = () => {
    const newRow = Array(item.columns.length).fill(''); // Create an empty row
    const updatedRows = [...item.rows, newRow]; // Append the new row
    handleChange(item.id, updatedRows); // Trigger the change with the new rows
  };

  // Function to remove a row from the table
  const handleRemoveRow = (rowIndex) => {
    const updatedRows = item.rows.filter((_, index) => index !== rowIndex); // Remove the selected row
    handleChange(item.id, updatedRows); // Trigger the change with the updated rows
  };

  return (
    <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            {item.columns.map((column, colIndex) => (
              <TableCell key={colIndex} align="center" style={{ fontSize: item.font_size || 14 }}>
                {column.label}
              </TableCell>
            ))}
            <TableCell align="center" style={{ fontSize: item.font_size || 14 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, colIndex) => (
                <TableCell key={colIndex} align="center">
                  <TextField
                    value={cell || ''}
                    onChange={(e) => handleCellChange(rowIndex, colIndex, e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
              ))}
              <TableCell align="center">
                <IconButton onClick={() => handleRemoveRow(rowIndex)}>
                  <RemoveCircleIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={item.columns.length + 1} align="center">
              <IconButton onClick={handleAddRow}>
                <AddCircleIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableItem;
