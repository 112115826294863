import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  ToggleButton,
  TextField,
  ToggleButtonGroup,
  Box,
  Typography,
} from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

function HeadingCustomizer({ item, updateItem, close }) {
  const [label, setLabel] = useState(item.label || '');
  const [size, setSize] = useState(item.size || 'h6');
  const [alignment, setAlignment] = useState(item.alignment || 'left');
  const [bold, setBold] = useState(item.bold || false);
  const [italic, setItalic] = useState(item.italic || false);

  const handleSave = () => {
    updateItem({ ...item, label, size, alignment, bold, italic });
    close();
  };

  const handleAlignmentChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Dialog open onClose={close}>
      <DialogTitle>Customize Heading</DialogTitle>
      <DialogContent>
        {/* Heading Text Input */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            label="Heading Text"
            variant="outlined"
            fullWidth
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </FormControl>

        {/* Font Size Selector */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Size</InputLabel>
          <Select value={size} onChange={(e) => setSize(e.target.value)}>
            <MenuItem value="h1">H1</MenuItem>
            <MenuItem value="h2">H2</MenuItem>
            <MenuItem value="h3">H3</MenuItem>
            <MenuItem value="h4">H4</MenuItem>
            <MenuItem value="h5">H5</MenuItem>
            <MenuItem value="h6">H6</MenuItem>
          </Select>
        </FormControl>

        {/* Alignment Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignmentChange}
            aria-label="text alignment"
          >
            <ToggleButton value="left" aria-label="left aligned">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center" aria-label="center aligned">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Bold and Italic Toggles */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <IconButton onClick={() => setBold(!bold)} color={bold ? 'primary' : 'default'}>
            <FormatBoldIcon />
          </IconButton>
          <IconButton onClick={() => setItalic(!italic)} color={italic ? 'primary' : 'default'}>
            <FormatItalicIcon />
          </IconButton>
        </Box>

        {/* Real-Time Preview */}
        <Typography
          variant={size}
          align={alignment}
          sx={{
            fontWeight: bold ? 'bold' : 'normal',
            fontStyle: italic ? 'italic' : 'normal',
            mt: 4,
            border: '1px solid #ddd',
            padding: '10px',
          }}
        >
          {label || 'Heading Preview'}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HeadingCustomizer;
