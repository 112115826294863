import { useState, useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';

const handleStyle = { left: 10 };

function TextUpdaterNode({ data, isConnectable }) {
  const [text, setText] = useState(data.text || '');

  const onChange = useCallback(
    (evt) => {
      setText(evt.target.value); // Update the internal state
      if (data.onChange) {
        data.onChange(evt.target.value); // Call the external change handler if provided
      }
    },
    [data]
  );

  return (
    <div className="text-updater-node" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div>
        <label htmlFor="text">Text:</label>
        <input
          id="text"
          name="text"
          value={text}
          onChange={onChange}
          className="nodrag"
          style={{ width: '100%' }}
        />
      </div>
      <Handle type="source" position={Position.Bottom} id="a" style={handleStyle} isConnectable={isConnectable} />
      <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
    </div>
  );
}

export default TextUpdaterNode;
