// src/components/RedirectLogin.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUserWithRedirectCode } from '../actions/authActions';
import {
  Container,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    h4: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RedirectLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const redirectCode = query.get('redirect_code');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (redirectCode) {
      dispatch(loginUserWithRedirectCode(redirectCode))
        .then(() => {
          setLoading(false);
          navigate('/', { replace: true });
        })
        .catch((err) => {
          setLoading(false);
          setError('Failed to authenticate with tenant. Please try logging in again.');
          console.error('Redirect login error:', err);
        });
    } else {
      setLoading(false);
      setError('No redirect code provided.');
    }
  }, [redirectCode, dispatch, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper elevation={4} sx={{ p: 5, mt: 8, textAlign: 'center' }}>
          {loading && (
            <>
              <Typography variant="h4" gutterBottom>
                Redirecting...
              </Typography>
              <CircularProgress />
            </>
          )}
          {!loading && error && (
            <>
              <Typography variant="h4" color="error" gutterBottom>
                {error}
              </Typography>
            </>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default RedirectLogin;
