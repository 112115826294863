import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Box } from '@mui/material';

const SignatureItem = ({ item, handleChange }) => {
    const signatureRef = useRef();

    const handleSignatureClear = () => {
        signatureRef.current.clear();
        handleChange(item.id, ''); // Clear the form data for the signature
    };

    // Capture signature as base64 when form submits
    useEffect(() => {
        if (signatureRef.current && !signatureRef.current.isEmpty()) {
            const signatureData = signatureRef.current.toDataURL(); // Capture base64 data
            handleChange(item.id, signatureData); // Send base64 data to formData in FormEditor
        }
    }, [handleChange, item.id]);

    return (
        <Box>
            <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            />
            <Box mt={1}>
                <Button variant="outlined" color="secondary" onClick={handleSignatureClear} sx={{ ml: 2 }}>
                    Clear
                </Button>
            </Box>
        </Box>
    );
};

export default SignatureItem;