import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function DropdownItem({ item, handleChange }) {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{item.label}</InputLabel>
      <Select
        value={item.value || ''}
        onChange={(e) => handleChange && handleChange(item.id, e.target.value)}
        required={item.required}
        label={item.label}
        sx={{ fontSize: item.font_size || 14 }}
      >
        {item.options?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownItem;
