// EditProjectForm.js

import React from 'react';
import { Offcanvas, Button, Form, Row, Col } from 'react-bootstrap';
import '../App.css';


const EditProjectForm = ({ show, editedProject, handleFieldChange, handleUpdateProject, handleCloseEditWindow, handleClose }) => {
  return (
    <Offcanvas show={show} onHide={handleClose} placement='end' className="custom-offcanvas-width">
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Project</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formProjectName">
                    <Form.Label column sm={2}>Project Name</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            name="project_name"
                            value={editedProject.project_name}
                            onChange={handleFieldChange}

                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formProjectDescription">
                    <Form.Label column sm={2}>Description</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            name="project_description"
                            value={editedProject.project_description}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formLocation">
                    <Form.Label column sm={2}>Location</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            name="location"
                            value={editedProject.location}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formBudget">
                    <Form.Label column sm={2}>Budget</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="number"
                            name="budget"
                            value={editedProject.budget}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formStartDate">
                    <Form.Label column={2}>Start Date</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="date"
                            name="start_date"
                            value={editedProject.start_date}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Form.Group>
                
                <Form.Group as={Row} className="mb-3" controlId="formEndDate">
                    <Form.Label column={2}>End Date</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="date"
                            name="end_date"
                            value={editedProject.end_date}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formStatus">
                    <Form.Label column={2}>Status</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            name="status"
                            value={editedProject.status}
                            onChange={handleFieldChange}
                        >
                            <option>Planning</option>
                            <option>In Progress</option>
                            <option>Completed</option>
                    
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Button variant="primary" type="submit" onClick={handleUpdateProject}>
                    Update
                </Button>
                <Button variant="secondary" onClick={handleCloseEditWindow}>
                    Cancel
                </Button>
            </Form>
        </Offcanvas.Body>
    </Offcanvas>













        
  );
};

export default EditProjectForm;
