import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStockOrder, updateStockOrder } from '../../apislice';
import { Button, TextField, Container, Typography, Box, Modal, Fade, List, ListItem, ListItemText, InputBase, Paper, IconButton, Select, MenuItem, FormControl, InputLabel, Alert, Grid, CircularProgress } from '@mui/material';
import { Close, Delete, CheckCircle } from '@mui/icons-material';
import { useUserGroup } from '../users/userGroup.js';
import DownloadPdf from './DownloadPDF.js'; // Import the DownloadPdf component

const OrderForm = ({ open, handleClose, order }) => {
    const isApproved = useUserGroup(['Admin', 'Manager', 'Plant User']);
    const isOperative = useUserGroup(['Operative']);
    const dispatch = useDispatch();
    const items = useSelector((state) => state.api.stock_items.data);
    const projects = useSelector((state) => state.api.projects.data);
    const user = useSelector((state) => state.auth.id); // Assuming user info is in the state
    const stockItems = useSelector((state) => state.api.stock_items.data); // Subscribe to stock items updates
    const [orderItems, setOrderItems] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [deliveryType, setDeliveryType] = useState('Delivery'); // New state for delivery type
    const [searchText, setSearchText] = useState('');
    const [error, setError] = useState('');
    const [warning, setWarning] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [loadingMessage, setLoadingMessage] = useState('');

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        if (order) {
            setSelectedProject(order.project);
            const formattedDate = order.delivery_date.split('T')[0];
            setDeliveryDate(formattedDate);
            setDeliveryType(order.delivery_type || 'Delivery'); // Set delivery type
            setOrderItems(order.order_items.map(item => {
                const stockItem = items.find(stockItem => stockItem.id === item.item);
                return {
                    item: item.item,
                    ref: stockItem ? stockItem.ref : 'Unknown Ref',
                    name: stockItem ? stockItem.name : 'Unknown Item',
                    quantityOrdered: item.quantity,
                    quantityPacked: item.quantity_packed || item.quantity,  // Include quantityPacked, default to quantity
                    stockLevel: stockItem ? stockItem.stock_level : 'N/A'
                };
            }));
        } else {
            setSelectedProject('');
            setDeliveryDate('');
            setDeliveryType('Delivery'); // Reset delivery type
            setOrderItems([]);
        }
    }, [order, items]);

    // Effect to handle updates from WebSocket
    useEffect(() => {
        if (stockItems.length > 0) {
            setOrderItems(prevOrderItems =>
                prevOrderItems.map(orderItem => {
                    const updatedStockItem = stockItems.find(stockItem => stockItem.id === orderItem.item);
                    if (updatedStockItem) {
                        return {
                            ...orderItem,
                            stockLevel: updatedStockItem.stock_level
                        };
                    }
                    return orderItem;
                })
            );
        }
    }, [stockItems]);

    useEffect(() => {
        if (!open) {
            setError('');
            setWarning('');
            setLoadingMessage(false);
        }
    }, [open]);

    const sortedItems = items.slice().sort((a, b) => b.popularity - a.popularity); // Sort items by popularity
    const filteredItems = sortedItems.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleAddOrderItem = (item) => {
        if (!orderItems.some(orderItem => orderItem.item === item.id)) {
            const quantityOrdered = order && order.status === 'Accepted' ? 0 : '';
            setOrderItems([...orderItems, {
                item: item.id,
                ref: item.ref,
                name: item.name,
                quantityOrdered: quantityOrdered, // set to 0 if order is accepted
                stockLevel: item.stock_level,
                quantityPacked: order && order.status === 'Accepted' ? '' : '' // or set the initial value based on requirements
            }]);
        }
    };
    

    const handleOrderItemChange = (index, event) => {
        const values = [...orderItems];
        values[index][event.target.name] = event.target.value;
        setOrderItems(values);
    };

    const handleRemoveOrderItem = (index) => {
        const values = [...orderItems];
        values.splice(index, 1);
        setOrderItems(values);
    };

    const validateForm = () => {
        if (!user) {
            setError('User information is missing.');
            return false;
        }
        if (!selectedProject) {
            setError('Project is required.');
            return false;
        }
        if (!deliveryDate) {
            setError('Delivery date is required.');
            return false;
        }
    
        // Check for accepted orders
        if (order?.status === 'Accepted') {
            if (orderItems.length === 0 || orderItems.some(item => item.quantityPacked === '' || item.quantityPacked <= 0)) {
                setError('At least one valid item with a packed quantity greater than 0 is required.');
                return false;
            }
        } else {
            // Regular validation for non-accepted orders
            if (orderItems.length === 0 || orderItems.some(item => item.quantityOrdered === '' || item.quantityOrdered <= 0)) {
                setError('At least one valid item with an ordered quantity greater than 0 is required.');
                return false;
            }
        }
    
        if (orderItems.some(item => item.quantityOrdered > item.stockLevel)) {
            setWarning('Not enough stock quantity for one or more items.');
            return false;
        }
    
        setWarning('');
        return true;
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        setLoadingMessage('Submitting order...');

        const orderData = {
            project: selectedProject,
            delivery_date: deliveryDate,
            delivery_type: deliveryType, // Include delivery type
            order_items: orderItems.map(item => ({
                item: item.item,
                quantity: parseInt(item.quantityOrdered),
                quantity_packed: item.quantityPacked ? parseInt(item.quantityPacked) : parseInt(item.quantityOrdered) // Ensure quantity_packed is included
            })),
            user: user,
        };

        console.log('Submitting Order Data:', orderData);

        try {
            let response;
            if (order) {
                // Editing an existing order
                response = await dispatch(updateStockOrder({ orderId: order.id, stockOrder: orderData })).unwrap();
            } else {
                // Creating a new order
                response = await dispatch(createStockOrder({ stockOrder: orderData })).unwrap();
            }
            console.log('Order Submission Response:', response);
            setLoadingMessage('Order submitted successfully');

            setTimeout(() => {

                setSearchText('');
                setDeliveryDate('');
                setSelectedProject('');
                setDeliveryType('Delivery'); // Reset delivery type
                setOrderItems([]);
                handleClose();
                setLoadingMessage('');
            }, 2000);
        } catch (err) {
            console.error('Order submission error:', err);
            setError('Order submission failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleAccept = async () => {
        if (!order) return;

        // Validate the form before updating and accepting
        if (!validateForm()) {
            return;
        }

        setLoading(true);

        const orderData = {
            project: selectedProject,
            delivery_date: deliveryDate,
            delivery_type: deliveryType, // Include delivery type
            order_items: orderItems.map(item => ({
                item: item.item,
                quantity: parseInt(item.quantityOrdered),
                quantity_packed: item.quantityPacked !== null && item.quantityPacked !== '' ? parseInt(item.quantityPacked) : 0 // Include quantityPacked
            })),
            status: 'Accepted',
        };

        try {
            const response = await dispatch(updateStockOrder({ orderId: order.id, stockOrder: orderData })).unwrap();
            console.log('Order Acceptance Response:', response);
            setSearchText('');
            handleClose();
        } catch (err) {
            console.error('Order acceptance error:', err);
            setError('Order acceptance failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFulfill = async () => {
        if (!order) return;

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        const orderData = {
            ...order,
            status: 'Fulfilled',
            order_items: orderItems.map(item => ({
                item: item.item,
                quantity: parseInt(item.quantityOrdered),
                quantity_packed: item.quantityPacked ? parseInt(item.quantityPacked) : 0 // Include quantity_packed
            })),
        };

        try {
            const response = await dispatch(updateStockOrder({ orderId: order.id, stockOrder: orderData })).unwrap();
            console.log('Order Fulfillment Response:', response);
            handleClose();
        } catch (err) {
            console.error('Order fulfillment error:', err);
            setError('Order fulfillment failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleReject = async () => {
        if (!order) return;

        const rejectionNote = prompt('Please enter a note for rejection'); // Prompt for rejection note

        setLoading(true);

        const orderData = {
            ...order,
            status: 'Rejected',
            notes: rejectionNote, // Add rejection note to notes field
        };

        try {
            const response = await dispatch(updateStockOrder({ orderId: order.id, stockOrder: orderData })).unwrap();
            console.log('Order Rejection Response:', response);
            handleClose();
        } catch (err) {
            console.error('Order rejection error:', err);
            setError('Order rejection failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const canEdit = isApproved || (!isApproved && (!order || order.status === 'Pending'));
    const canFulfill = isApproved && order?.status === 'Accepted';

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        height: '90vh',
                        width: '90vw',
                        padding: '20px',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        overflowY: 'auto',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <Close />
                    </IconButton>
                    {loadingMessage ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            {loadingMessage === 'Submitting order...' ? (
                                <CircularProgress />
                            ) : (
                                <CheckCircle color="success" sx={{ fontSize: 60 }} />
                            )}
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {loadingMessage}
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Grid container spacing={2} sx={{ mb: 0 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" component="h4" gutterBottom>
                                        {order ? 'View Order' : 'Create Order'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    {order && (
                                        <Typography variant="h6" component="h6" gutterBottom>
                                            Status: {order.status}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {order && (
                                        <DownloadPdf
                                            order={order}
                                            project={selectedProject}
                                            deliveryDate={deliveryDate}
                                            orderItems={orderItems}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '93%' }}>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="project-select-label">Select Project</InputLabel>
                                    <Select
                                        labelId="project-select-label"
                                        id="project-select"
                                        name="project"
                                        value={selectedProject}
                                        onChange={(e) => setSelectedProject(e.target.value)}
                                        size='small'
                                        disabled={!isApproved} // Disable if user cannot edit
                                    >
                                        {projects.map((project) => (
                                            <MenuItem key={project.id} value={project.id}>
                                                {project.project_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="delivery-type-label">Delivery Type</InputLabel>
                                    <Select
                                        labelId="delivery-type-label"
                                        id="delivery-type-select"
                                        value={deliveryType}
                                        onChange={(e) => setDeliveryType(e.target.value)}
                                        size="small"
                                        disabled={!isApproved} // Disable if user cannot edit
                                    >
                                        <MenuItem value="Delivery">Delivery</MenuItem>
                                        <MenuItem value="Collection">Collection</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="delivery_date"
                                    name="delivery_date"
                                    label={deliveryType === 'Collection' ? 'Collection Date' : 'Delivery Date'}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={deliveryDate}
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                    size='small'
                                    disabled={!isApproved} // Disable if user cannot edit
                                    sx={{ mb: 2 }}
                                    inputProps={{
                                        min: today,
                                    }}
                                />
                                <Grid container spacing={2} sx={{ flex: 1, overflowY: 'hidden' }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 0 }}>
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Search Items"
                                                inputProps={{ 'aria-label': 'search items' }}
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                disabled={!isApproved || order?.status === 'Fulfilled'} // Disable if user cannot edit or order is fulfilled
                                            />
                                        </Paper>
                                        <Box sx={{ overflowY: 'auto', flex: 1, maxHeight: '50vh' }}>
                                            <List>
                                                {items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())).map(item => (
                                                    <ListItem button key={item.id} onClick={() => handleAddOrderItem(item)} disabled={!isApproved || order?.status === 'Fulfilled'}>
                                                        <ListItemText primary={item.name} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sx={{ overflowY: 'auto', maxHeight: '30vh' }}>
                                        {orderItems.map((orderItem, index) => (
                                            <Grid container key={index} spacing={2} alignItems="center" sx={{ mb: 1 }}>
                                                <Grid item xs={1.5}>
                                                    <TextField
                                                        fullWidth
                                                        name="ref"
                                                        label="Ref"
                                                        value={orderItem.ref}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        size='small'
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        fullWidth
                                                        name="name"
                                                        label="Description"
                                                        value={orderItem.name}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        size='small'
                                                    />
                                                </Grid>
                                                <Grid item xs={order && order.status !== 'Pending' ? 1.7 : 1.7}>
                                                    <TextField
                                                        fullWidth
                                                        name="quantityOrdered"
                                                        label={orderItem.stockLevel === 0 ? 'Out of Stock' : (order && order.status ? 'Quantity Ordered' : 'Quantity')}
                                                        value={orderItem.quantityOrdered}
                                                        onChange={(event) => handleOrderItemChange(index, event)}
                                                        size='small'
                                                        disabled={orderItem.stockLevel === 0 || (order && order.status !== 'Pending')}
                                                    />
                                                    {orderItem.stockLevel === 0 && (
                                                        <Typography variant="caption" color="error">
                                                            Not enough stock
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                {order && order.status !== 'Pending' && (
                                                    <Grid item xs={1.7}>
                                                        <TextField
                                                            fullWidth
                                                            name="quantityPacked"
                                                            label="Quantity Packed"
                                                            value={orderItem.quantityPacked}
                                                            onChange={(event) => handleOrderItemChange(index, event)}
                                                            size='small'
                                                            disabled={!isApproved && !isOperative}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={1.7}>
                                                    <TextField
                                                        fullWidth
                                                        name="stockLevel"
                                                        label="Stock Level"
                                                        value={orderItem.stockLevel}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        size='small'
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleRemoveOrderItem(index)}
                                                        disabled={!isApproved} // Disable if user cannot edit
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                    <Grid item xs={6}>

                                    </Grid>
                                    <Grid>
                                        {(isApproved || isOperative) && (
                                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: 20, right: 20 }}>
                                                <Grid sx={{ mr: 2 }}>
                                                    {warning && <Alert severity="warning">{warning}</Alert>}
                                                    {error && <Alert severity="error">{error}</Alert>}
                                                </Grid>
                                                <Button type="submit" variant="contained" color="primary" disabled={orderItems.some(item => item.quantityOrdered > item.stockLevel)}>
                                                    {order ? 'Update Order' : 'Submit'}
                                                </Button>
                                                {order && order.status === 'Pending' && isApproved && (
                                                    <>
                                                        <Button variant="contained" color="primary" onClick={handleAccept} sx={{ ml: 2 }}>
                                                            Accept Order
                                                        </Button>
                                                        <Button variant="contained" color="secondary" onClick={handleReject} sx={{ ml: 2 }}>
                                                            Reject Order
                                                        </Button>
                                                    </>
                                                )}
                                                {order && order.status === 'Accepted' && isApproved && (
                                                    <>
                                                        <Button variant="contained" color="secondary" onClick={handleReject} sx={{ ml: 2 }}>
                                                            Reject Order
                                                        </Button>
                                                        <Button variant="contained" color="primary" onClick={handleFulfill} sx={{ ml: 2 }} disabled={orderItems.some(item => item.quantityOrdered > item.stockLevel)}>
                                                            Fulfill Order
                                                        </Button>
                                                    </>
                                                )}
                                                {order && order.status === 'Rejected' && isApproved && (
                                                    <>
                                                        <Button variant="contained" color="primary" onClick={handleAccept} sx={{ ml: 2 }}>
                                                            Accept Order
                                                        </Button>
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )}
                </Box>
            </Fade>
        </Modal>
    );
};

export default OrderForm;
