// src/components/FormItemEditor/FormItemEditor.js
import React from 'react';
import TextInputCustomizer from './customizers/TextInputCustomizer';
import DropdownCustomizer from './customizers/DropdownCustomizer';
import CheckboxCustomizer from './customizers/CheckboxCustomizer';
import TextAreaCustomizer from './customizers/TextAreaCustomizer';
import DateInputCustomizer from './customizers/DateInputCustomizer'; // Import the DateInputCustomizer
import TableItemCustomizer from './customizers/TableItemCustomizer'; // Import the TableItemCustomizer
import HeadingCustomizer from './customizers/HeadingCustomizer';
import LinkedItemCustomizer from './customizers/LinkedItemCustomizer';

const FormItemEditor = ({ item, updateItem, close, formItems }) => {
  const renderCustomizer = () => {
    switch (item.item_type) {
      case 'textInput':
        return <TextInputCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'dropdown':
        return <DropdownCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'checkbox':
        return <CheckboxCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'textArea':
        return <TextAreaCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'dateInput': // Add case for DateInputCustomizer
        return <DateInputCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'table': // Add case for TableItemCustomizer
        return <TableItemCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'heading':
        return <HeadingCustomizer item={item} updateItem={updateItem} close={close} />;
      case 'linkedItem':
        return <LinkedItemCustomizer item={item} formItems={formItems} updateItem={updateItem} close={close} />;
      default:
        return null;
    }
  };

  return <>{renderCustomizer()}</>;
};

export default FormItemEditor;
